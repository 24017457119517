import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";

import Notifications from "../../utils/notifications";
import BASE_URL from "../../utils/api";
import Layout from "../layouts/index";
import "rsuite/dist/styles/rsuite-default.css";
import { getPermissions } from "../../utils/permissions.util";
import { getToken } from "../../utils/session.util";
import { searchMedicines } from "../../services/medicines.service";

// Configurations
toast.configure({ autoClose: 8000, draggable: false });

const InactiveMedicines = () => {
  const [medicine_list, setMedicine_list] = useState([]);
  const [loading, setloading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [alength, setalength] = useState();
  const [datastatus, setDatastatus] = useState({
    confirmbx: false,
    rowid: "",
    type: "",
    name: "",
  });

  const fetchData = async (offset) => {
    setloading(true);
    await fetch(BASE_URL + "/medicines", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "medicines",
        offset: offset,
        method: "GET",
        status_id: "inactive",
      }),
    })
      .then((res) => res.json())
      .then(
        (resp) => {
          let medlist = resp.data;
          setMedicine_list(medlist);
          setalength(resp.total);
          setloading(false);
        },
        (error) => {}
      );
  };

  // offset
  useEffect(() => fetchData(offset), [offset]);

  // Medicine Change Status function
  function handleMedicineActivation() {
    if (datastatus.confirmbx === true) {
      setDatastatus({ confirmbx: false, rowid: "", type: "", name: "" });
      setloading(true);
      fetch(BASE_URL + "/admin-by", {
        method: "POST",
        headers: {
          Origin: "*",
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          medicine_id: `${datastatus.rowid}`,
          resource: "admin_by",
          method: "POST",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (Object.prototype.hasOwnProperty.call(result, "error")) {
            toast.error(`${result["error"]["message"]}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            fetchData(offset);
            toast.success(`${Notifications.activesuccess}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } else {
      cancelActivation();
    }
  }

  function handleFormulaActivation() {
    if (datastatus.confirmbx === true) {
      setDatastatus({ confirmbx: false, rowid: "", type: "", name: "" });
      setloading(true);

      fetch(BASE_URL + "/admin-by", {
        method: "POST",
        headers: {
          Origin: "*",
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          medicine_formula_id: `${datastatus.rowid}`,
          resource: "admin_by",
          method: "POST",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (Object.prototype.hasOwnProperty.call(result, "error")) {
            toast.error(`${result["error"]["message"]}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            fetchData(offset);
            toast.success(`Medicine formula active successfully`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } else {
      cancelActivation();
    }
  }

  const cancelActivation = () => {
    setDatastatus({ confirmbx: false, rowid: "", type: "", name: "" });
  };

  const search = async (value) => {
    if (value.length > 2) {
      setloading(true);
      const data = await searchMedicines(value, "inactive");
      setMedicine_list(data.data);
      setalength(data.total);
      setloading(false);
    }
  };

  return (
    <Layout>
      <div className="card shadow mb-4">
        <input
          autoComplete="off"
          type="text"
          onChange={(e) => search(e.target.value)}
          placeholder="Search..."
          className="form-control-user form-control"
        />
        <div className="card-header">
          <h5 className="m-0 font-weight-bold text-primary">
            Inactive Medicines
          </h5>
        </div>
        <div className="card-body p-3">
          {medicine_list.map((data, index) => {
            return (
              <div
                key={index}
                className="accordion"
                id={`md_accordion${index}`}
              >
                <div className="card mb-2 border-bottom">
                  <div className="card-header p-1 " id={`md_head${index}`}>
                    <a
                      href="#"
                      className="collapsed d-block p-1 w-75"
                      data-toggle="collapse"
                      data-target={`#mdbx${index}`}
                      aria-expanded="true"
                      aria-controls={`mdbx${index}`}
                    >
                      {data.name}{" "}
                      <span className="badge badge-secondary">
                        {data.medicine_formulas.length}
                      </span>
                    </a>
                    <span
                      data-toggle="tooltip"
                      title="Active medicine with all formulas"
                      className={`cp mr-2 text-success`}
                      onClick={() =>
                        setDatastatus({
                          confirmbx: true,
                          rowid: data.id,
                          type: "medicine",
                          name: data.name,
                        })
                      }
                    >
                      <i className="fas fa-redo"></i>
                    </span>
                  </div>
                  <div
                    id={`mdbx${index}`}
                    className="collapse"
                    aria-labelledby={`md_head${index}`}
                    data-parent={`#md_accordion${index}`}
                  >
                    <div className="card-body">
                      <div className="table-responsive">
                        <table
                          loading={loading}
                          className="table table-sm mb-0 table-bordered text-center"
                        >
                          <thead>
                            <tr>
                              <th className={`align-middle`}>Dose Name</th>
                              <th className={`align-middle`}>Salt Name</th>
                              <th className={`align-middle`}>Quantity</th>
                              <th className={`align-middle`}>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.medicine_formulas.map((k, i) => (
                              <tr>
                                <td className={`align-middle`}>{k.dose_id}</td>
                                <td className={`align-middle`}>
                                  <div className="rolelist d-inline-block text-center align-middle">
                                    {(() => {
                                      if (3 < getPermissions("medicines")) {
                                        return data.salts.map((key, i) => {
                                          return (
                                            <span
                                              className={`badge badge-primary a${i}`}
                                              key={i}
                                            >
                                              {key.dose_id}
                                              {key.quantity}
                                            </span>
                                          );
                                        });
                                      }
                                    })()}
                                  </div>
                                </td>
                                <td className={`align-middle`}>{k.quantity}</td>
                                <td className={`align-middle`}>
                                  <a
                                    data-toggle="tooltip"
                                    title="Active formula"
                                    href
                                    className={`cp text-success`}
                                    onClick={() =>
                                      setDatastatus({
                                        confirmbx: true,
                                        rowid: k.id,
                                        type: "medcine_formula",
                                        name: k.name,
                                      })
                                    }
                                  >
                                    <i className="fas fa-sync"></i>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <SweetAlert
        show={datastatus.confirmbx}
        showCancel
        confirmBtnText="Activate"
        confirmBtnBsStyle="success"
        title="Are you sure!"
        onConfirm={() =>
          datastatus.type == "medicine"
            ? handleMedicineActivation()
            : handleFormulaActivation()
        }
        onCancel={cancelActivation}
        focusCancelBtn
        closeAnim={{ name: "hideSweetAlert", duration: 500 }}
      >
        You want to activate {datastatus.name}?
      </SweetAlert>
    </Layout>
  );
};

export default InactiveMedicines;
