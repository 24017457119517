import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";

import { SearchableSelectFull } from "../../common/forms/form-fields";
import { getOne, deleteOne } from "../../../services/sales.service";
import { getPermissions } from "../../../utils/permissions.util";

export const SalesForm = ({
  setAction,
  handleSubmit,
  handleChange,
  medicinesList,
  setSearch,
  salesParams,
  setSalesParams,
  defaultSalesParams,
  totalPrice,
  totalItems,
  setTotalItems,
  setTotalPrice,
  setDraftSaleId,
  draftSaleId,
  setReturnSaleId,
  returnSaleId,
  currency,
  createMedicine,
}) => {
  const history = useHistory();
  const location = useLocation();

  const handleSearch = (e) => setSearch(e);

  let modified_medicine_id_list = medicinesList?.map((key) => {
    const container = {};
    container.id = salesParams.length - 1;
    container.label = `${key.name}`;
    container.fieldName = "medicine_id";
    container.key = key.id;
    container.value = `${key.name}${key.medicine_shelf}${key.brand_id}${key.entity_type_id}`;

    return container;
  });

  const select = [
    {
      id: "medicine_id",
      label: "Select Medicine",
      search: handleSearch,
      options: modified_medicine_id_list,
    },
    {
      id: "unit_id",
      label: "Unit ID",
      type: "string",
      placeHolder: "Enter Unit",
      value: salesParams,
    },
    {
      id: "quantity",
      label: "Quantity",
      type: "number",
      placeHolder: "Enter Quantity",
      value: salesParams,
    },
    {
      id: "price",
      label: "Price",
      type: "number",
      placeHolder: "Enter Price",
      value: salesParams,
    },
  ];

  let initialvals = {
    sales: [
      { medicine_id: "", quantity: "", price: "", unit_id: "", stock_id: "" },
    ],
  };

  const validationTablets = Yup.object({
    medicine_id: Yup.string().required("Medicine is required").nullable(),
    quantity: Yup.string().required("Medicine quantity is required").nullable(),
    price: Yup.string().required("Price is required").nullable(),
  });

  // This use effect is used for getting query params and performing return order and draft sale
  // Only runs when component is initially rendered
  useEffect(async () => {
    // Getting draft
    const draftSaleId = new URLSearchParams(location.search).get("draft");
    if (draftSaleId) {
      const data = await getOne(draftSaleId, "draft");
      if (data.length > 0) {
        const saleParams = data[0].sale_medicines.map((x) => ({
          unit_id: x.unit_id,
          resource: "medicine_sales",
          quantity: parseInt(x.quantity),
          medicine_id: x.medicine_id,
          price: x.price,
          stock_id: x.stock_inventory_id,
          quantityError: "",
          hasError: false,
        }));
        setDraftSaleId(draftSaleId);
        setReturnSaleId(null);
        initialvals = saleParams;
        setSalesParams([...saleParams, { ...defaultSalesParams }]);
        setTotalPrice(data[0].total_sale_amount);
        setTotalItems(data[0].sale_medicines.length);
        for (let i = 0; i < saleParams.length - 1; i++) {
          document.getElementById("add-button").click();
        }
        document.getElementById("add-button").hidden = true;
      }
      return;
    }

    // Getting a completed sale used for return order
    const returnSaleId = new URLSearchParams(location.search).get("return");
    if (returnSaleId) {
      const data = await getOne(returnSaleId);
      console.log("Return Sale", data);
      setReturnSaleId(returnSaleId);
      setDraftSaleId(null);
      return;
    }
  }, []);

  const deleteDraft = async () => await deleteOne(draftSaleId);

  return (
    <Formik
      initialValues={initialvals}
      validationSchema={validationTablets}
      onSubmit={(values, { resetForm }) => {
        resetForm();
      }}
      render={({ values, handleBlur, resetForm }) => {
        return (
          <Form className="user" onSubmit={handleSubmit}>
            {1 < getPermissions("sales") ? (
              <div id="accordion1">
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed card-link d-block w-100 text-dark font-weight-600"
                      aria-expanded="true"
                      data-toggle="collapse"
                      href="#collapseTwo3"
                    >
                      <h5 className="m-0 font-weight-bold text-primary">
                        {(() => {
                          if (draftSaleId)
                            return <>Continue Draft Sale ({draftSaleId})</>;
                          else if (returnSaleId)
                            return <>Return Sale ({returnSaleId})</>;
                          else return <>New Sale</>;
                        })()}
                      </h5>
                    </a>
                  </div>
                  <div
                    id="collapseTwo3"
                    className="collapse show"
                    data-parent="#accordion1"
                  >
                    <div className="card-body px-2 pt-2 pb-0">
                      <div id="accordion">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                              <FieldArray
                                name="sales"
                                render={(arrayHelpers) => {
                                  const sales = values.sales;
                                  return (
                                    <div>
                                      {sales && sales.length > 0
                                        ? sales.map((sales, i) => (
                                            <div
                                              key={i}
                                              className="row formula_sec"
                                            >
                                              {select.map((key, index) => {
                                                if (
                                                  key.id === "medicine_id" &&
                                                  !draftSaleId
                                                ) {
                                                  return (
                                                    <>
                                                      <SearchableSelectFull
                                                        label={key["label"]}
                                                        handleSearch={
                                                          key["search"]
                                                        }
                                                        handleChange={
                                                          handleChange
                                                        }
                                                        handleCreate={
                                                          createMedicine
                                                        }
                                                        className="form-control-user form-control"
                                                        id={`sales.${i}.${key.id}`}
                                                        name={`sales.${i}.${key.id}`}
                                                        options={key["options"]}
                                                        rowIndex={i}
                                                      />
                                                    </>
                                                  );
                                                } else if (
                                                  key.id === "medicine_id" &&
                                                  draftSaleId
                                                ) {
                                                  return (
                                                    <>
                                                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12 has-float-label">
                                                        <p>
                                                          Medicine Name:&nbsp;
                                                          {(() => {
                                                            const medicine =
                                                              medicinesList.find(
                                                                (x) =>
                                                                  salesParams[i]
                                                                    .medicine_id ===
                                                                  x.id
                                                              );
                                                            return medicine?.name
                                                              ? medicine.name
                                                              : null;
                                                          })()}
                                                        </p>
                                                      </div>
                                                    </>
                                                  );
                                                } else if (
                                                  key.id === "unit_id" &&
                                                  !draftSaleId
                                                ) {
                                                  return (
                                                    <>
                                                      <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                                        <select
                                                          disabled={
                                                            !key?.value[i]
                                                              .medicine_id
                                                          }
                                                          id={`sales.${i}.${key.id}`}
                                                          name={`sales.${i}.${key.id}`}
                                                          placeholder={`Enter ${key.id}`}
                                                          value={values.type_id}
                                                          onChange={(e) => {
                                                            e.fieldName =
                                                              key.id;
                                                            handleChange(e, i);
                                                          }}
                                                          className={
                                                            "form-control-user form-control"
                                                          }
                                                        >
                                                          <option
                                                            value=""
                                                            label="Select Type"
                                                          />
                                                          <option
                                                            value="item"
                                                            label="Units"
                                                          />
                                                          <option
                                                            value="pack"
                                                            label="Packs"
                                                          />
                                                        </select>
                                                      </div>
                                                    </>
                                                  );
                                                } else if (
                                                  key.id === "unit_id" &&
                                                  draftSaleId
                                                ) {
                                                  return (
                                                    <>
                                                      <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                                        <p>Unit ID: Item</p>
                                                      </div>
                                                    </>
                                                  );
                                                } else if (key.id === "price") {
                                                  return (
                                                    <>
                                                      <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                                        <input
                                                          readOnly={true}
                                                          type={key["type"]}
                                                          disabled={
                                                            !key?.value[i]
                                                              .medicine_id
                                                          }
                                                          placeholder={`Enter ${key.id}`}
                                                          className="form-control-user form-control"
                                                          id={`sales.${i}.${key.id}`}
                                                          name={`sales.${i}.${key.id}`}
                                                          value={
                                                            key?.value[i][
                                                              key.id
                                                            ] || ""
                                                          }
                                                          onChange={(e) => {
                                                            e.fieldName =
                                                              key.id;
                                                            handleChange(e, i);
                                                          }}
                                                        />
                                                        <label
                                                          htmlFor={`${key.id}`}
                                                        >
                                                          {" "}
                                                          {key.id} <sup>*</sup>{" "}
                                                        </label>
                                                        {true && (
                                                          <div
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {" "}
                                                            {
                                                              key?.value[i][
                                                                `${key.id}Error`
                                                              ]
                                                            }{" "}
                                                          </div>
                                                        )}
                                                        <ErrorMessage
                                                          name={`sales.${i}.${key.id}`}
                                                          component="div"
                                                          className="invalidbx"
                                                        />
                                                      </div>
                                                    </>
                                                  );
                                                } else {
                                                  return (
                                                    <>
                                                      <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                                        <input
                                                          type={key["type"]}
                                                          disabled={
                                                            !key?.value[i]
                                                              .medicine_id
                                                          }
                                                          placeholder={`Enter ${key.id}`}
                                                          className="form-control-user form-control"
                                                          id={`sales.${i}.${key.id}`}
                                                          name={`sales.${i}.${key.id}`}
                                                          value={
                                                            key?.value[i][
                                                              key.id
                                                            ] || ""
                                                          }
                                                          onChange={(e) => {
                                                            e.fieldName =
                                                              key.id;
                                                            handleChange(e, i);
                                                          }}
                                                        />
                                                        <label
                                                          htmlFor={`${key.id}`}
                                                        >
                                                          {" "}
                                                          {key.id} <sup>*</sup>{" "}
                                                        </label>
                                                        {true && (
                                                          <div
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {" "}
                                                            {
                                                              key?.value[i][
                                                                `${key.id}Error`
                                                              ]
                                                            }{" "}
                                                          </div>
                                                        )}
                                                        <ErrorMessage
                                                          name={`sales.${i}.${key.id}`}
                                                          component="div"
                                                          className="invalidbx"
                                                        />
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              })}
                                              {(() => {
                                                if (i > 0) {
                                                  return (
                                                    <span
                                                      className="close_btn"
                                                      onClick={() => {
                                                        const copySalesParams =
                                                          [...salesParams];
                                                        copySalesParams.splice(
                                                          i,
                                                          1
                                                        );
                                                        const updatedSalesParams =
                                                          copySalesParams;
                                                        setSalesParams(
                                                          updatedSalesParams
                                                        );
                                                        arrayHelpers.remove(i);
                                                      }}
                                                    >
                                                      <i
                                                        onClick={() => {
                                                          arrayHelpers.remove(
                                                            i
                                                          );
                                                        }}
                                                        className="fas fa-times-circle text-danger"
                                                      ></i>
                                                    </span>
                                                  );
                                                } else {
                                                  return <div></div>;
                                                }
                                              })()}
                                            </div>
                                          ))
                                        : null}
                                      <div className="row">
                                        <div className="mb-3 col-lg-3 col-md-3 col-sm-3 col-3 px-1">
                                          <i className="fas fa-angle-right"></i>{" "}
                                          <span>
                                            Total Items: <b>{totalItems}</b>
                                          </span>
                                          <br />
                                          <i className="fas fa-angle-right"></i>{" "}
                                          <span>
                                            Total Sale: <b>{totalPrice}</b>
                                          </span>
                                        </div>
                                        <div className="mb-3 col-lg-9 col-md-9 col-sm-9 col-9 justify-content-end text-right">
                                          <button
                                            className="btn btn-secondary bxd btn-sm"
                                            id="add-button"
                                            type="button"
                                            onClick={() => {
                                              setSalesParams([
                                                ...salesParams,
                                                { ...defaultSalesParams },
                                              ]);
                                              arrayHelpers.push({
                                                medicine_id: "",
                                                quantity: "",
                                                price: "",
                                                unit_id: "",
                                              });
                                            }}
                                          >
                                            <i className="fas fa-plus"></i> Add
                                          </button>
                                        </div>
                                      </div>
                                      <div>
                                        <i>
                                          All prices represented here are in{" "}
                                          {currency}.
                                        </i>
                                      </div>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            <div className="row">
                              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12 text-right">
                                {(() => {
                                  if (draftSaleId) {
                                    return (
                                      <>
                                        <button
                                          type="submit"
                                          onClick={() =>
                                            setAction("finalise-draft")
                                          }
                                          className="btn btn-success btn-sm"
                                        >
                                          {" "}
                                          Save
                                        </button>
                                        <button
                                          type="submit"
                                          onClick={() => deleteDraft()}
                                          className="btn btn-danger btn-sm mx-2"
                                        >
                                          {" "}
                                          Delete Draft
                                        </button>
                                        <button
                                          type="submit"
                                          onClick={() => {
                                            history.push(`/sales`);
                                            history.go(`/sales`);
                                          }}
                                          className="btn btn-secondary btn-sm"
                                        >
                                          {" "}
                                          Cancel
                                        </button>
                                      </>
                                    );
                                  } else if (returnSaleId) {
                                    return (
                                      <>
                                        <button
                                          type="button"
                                          onClick={() => setAction("")}
                                          className="btn btn-success btn-sm mr-2"
                                        >
                                          {" "}
                                          Return Order
                                        </button>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            history.push(`/sales`);
                                            history.go(`/sales`);
                                          }}
                                          className="btn btn-secondary btn-sm"
                                        >
                                          {" "}
                                          Cancel
                                        </button>
                                      </>
                                    );
                                  } else {
                                    return (
                                      <>
                                        <button
                                          type="submit"
                                          onClick={() => setAction("save")}
                                          className="btn btn-success btn-sm mr-2"
                                        >
                                          {" "}
                                          Save
                                        </button>
                                        <button
                                          type="submit"
                                          onClick={() =>
                                            setAction("save-draft")
                                          }
                                          className="btn btn-primary btn-sm mr-2"
                                        >
                                          {" "}
                                          Save Draft
                                        </button>
                                        <button
                                          type="submit"
                                          onClick={() => setAction("reset")}
                                          className="btn btn-secondary btn-sm"
                                        >
                                          {" "}
                                          Reset
                                        </button>
                                      </>
                                    );
                                  }
                                })()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <span></span>
            )}
          </Form>
        );
      }}
    />
  );
};
