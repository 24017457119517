export const getToken = () => {
  const token = localStorage.getItem("token");
  return token ? token.split(":")[0] : null;
};

export const getUserName = () => {
  const username = localStorage.getItem("username");
  return username ? username.split(":")[0] : null;
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};
