import React from "react";
import jquery from "jquery";
import { ToastContainer } from "react-toastify";
import NavScrollTop from "./components/common/nav-scroll-top/nav-scroll-top";
import Routes from "./routes/routes";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/vendor/fontawesome-free/css/all.min.css";
import "./assets/css/sb-admin-2.css";
import "./assets/css/bootstrap-float-label.min.css";
import {
  Dashboard,
  ForgotPassword,
  Login,
  NotFound,
  Register,
  ResetPassword,
} from "./components";

const App = () => {
  window.$ = window.jQuery = jquery;

  return (
    <React.Fragment>
      <ToastContainer />
      <Router>
        <NavScrollTop>
          <Routes />
          <Switch>
            <Route exact path={["/", "/login"]} component={Login} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/register"}`}
              component={Register}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/forgot-password"}`}
              component={ForgotPassword}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/reset:id"}`}
              component={ResetPassword}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/404"}`}
              component={NotFound}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/dashboard"}`}
              component={Dashboard}
            />
          </Switch>
        </NavScrollTop>
      </Router>
    </React.Fragment>
  );
};

export default App;
