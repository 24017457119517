import { useState, useEffect } from "react";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import "rsuite/dist/styles/rsuite-default.css";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Pagination from "material-ui-flat-pagination";
import SweetAlert from "react-bootstrap-sweetalert";

import Layout from "../layouts/index";
import {
  getAllDiscounts,
  updateOneDiscount,
} from "../../services/discounts.service";
import { date } from "../../utils/date.util";
import { getPermissions } from "../../utils/permissions.util";
import { searchDiscounts } from "../../services/discounts.service";

// Configurations
const theme = createTheme();

const InactiveDiscounts = () => {
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [length, setLength] = useState();
  const [discountList, setDiscountList] = useState([]);
  const [dataStatus, setDataStatus] = useState({ confirmBox: false, id: "" });

  // Hooks
  useEffect(() => getAllData(offset), [offset]);

  // APIs
  const getAllData = async (offset) => {
    setLoading(true);
    const data = await getAllDiscounts(offset, "inactive");
    setDiscountList(data.data);
    setLength(data.total);
    setLoading(false);
  };

  const handleActivation = async () => {
    if (dataStatus.confirmBox === true) {
      setDataStatus({ confirmBox: false });
      setLoading(true);
      const data = await updateOneDiscount(dataStatus.id, {
        status_id: "active",
      });
      setLoading(false);
      if (Object.prototype.hasOwnProperty.call(data, "error")) {
        toast.error(`${data["error"]["message"]}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success(`${Notifications.successMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDataStatus({ confirmBox: false, id: "" });
        fetchData(offset);
      }
    }
  };

  const cancelActivation = () => setDataStatus({ confirmBox: false, id: "" });

  const handleClick = (offset) => {
    setOffset(offset);
    if (searchValue !== "") {
      fetchData(offset);
    } else {
      search(searchValue);
    }
  };

  const search = async (value) => {
    if (value.length > 2) {
      setLoading(true);
      setSearchValue(value);
      setOffset(0);
      const data = await searchDiscounts(value, offset, "inactive");
      setDiscountList(data.data);
      setLength(data.total);
      setLoading(false);
    } else {
      setOffset(0);
      setSearchValue("");
      fetchData();
    }
  };

  return (
    <>
      <Layout>
        <div className="card shadow mb-4">
          <div className="card-header">
            <h5 className="m-0 font-weight-bold text-primary">
              Inactive Discounts List
            </h5>
          </div>
          <div className="card-body p-1">
            <input
              autoComplete="off"
              type="text"
              onChange={(e) => search(e.target.value)}
              placeholder="Search..."
              className="form-control-user form-control"
            />
            <Table
              data={discountList}
              minHeight={300}
              autoHeight={true}
              loading={loading}
              headerHeight={37}
              wordWrap
            >
              <Column width={50} verticalAlign="middle">
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey="id" />
              </Column>
              <Column flexGrow={2} verticalAlign="middle">
                <HeaderCell>Medicine</HeaderCell>
                <Cell>
                  {(data) => {
                    return (
                      <>
                        {" "}
                        {data.medicine.name} - {data.medicine.entity_type_id}{" "}
                      </>
                    );
                  }}
                </Cell>
              </Column>
              <Column flexGrow={2} verticalAlign="middle">
                <HeaderCell>Discount Type (Title)</HeaderCell>
                <Cell dataKey="type" />
              </Column>
              <Column flexGrow={2} verticalAlign="middle">
                <HeaderCell>Discount Value (%)</HeaderCell>
                <Cell dataKey="value" />
              </Column>
              <Column flexGrow={2} verticalAlign="middle">
                <HeaderCell>Date Added</HeaderCell>
                <Cell>{(data) => date(data["inserted_at"], "medium")}</Cell>
              </Column>
              <Column fixed="right" flexGrow={1} verticalAlign="middle">
                <HeaderCell>Actions</HeaderCell>
                <Cell>
                  {(data) => {
                    return (
                      <>
                        <span>
                          {3 < getPermissions("sales") ? (
                            <a
                              href
                              className="cp"
                              data-toggle="tooltip"
                              title="Activate"
                              onClick={() =>
                                setDataStatus({ confirmBox: true, id: data.id })
                              }
                            >
                              <i className="fas fa-play text-success"></i>{" "}
                            </a>
                          ) : (
                            <span></span>
                          )}
                        </span>
                      </>
                    );
                  }}
                </Cell>
              </Column>
            </Table>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <Pagination
                limit={10}
                offset={offset}
                total={length}
                onClick={(e, offset) => handleClick(offset)}
              />
            </MuiThemeProvider>
          </div>
        </div>
        <SweetAlert
          show={dataStatus.confirmBox}
          showCancel
          confirmBtnText="Activate"
          confirmBtnBsStyle="success"
          title="Are you sure!"
          onConfirm={handleActivation}
          onCancel={cancelActivation}
          focusCancelBtn
          closeAnim={{ name: "hideSweetAlert", duration: 500 }}
        >
          You want to activate discount ID: {dataStatus.id}?
        </SweetAlert>
      </Layout>
    </>
  );
};

export default InactiveDiscounts;
