import { useState, useEffect } from "react";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import "rsuite/dist/styles/rsuite-default.css";
import { Icon } from "rsuite";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Pagination from "material-ui-flat-pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import { Form, Formik } from "formik";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";

import Layout from "../layouts/index";
import {
  addOneDiscount,
  getAllDiscounts,
  getOneDiscount,
  updateOneDiscount,
} from "../../services/discounts.service";
import { date } from "../../utils/date.util";
import { getPermissions } from "../../utils/permissions.util";
import { getAllMedicines } from "../../services/medicines.service";
import { searchDiscounts } from "../../services/discounts.service";

// Configurations
const theme = createTheme();

const ActiveDiscounts = () => {
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [length, setLength] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [discountList, setDiscountList] = useState([]);
  const [dataStatus, setDataStatus] = useState({ confirmBox: false, id: "" });
  const [medicineList, setMedicineList] = useState([]);

  // Hooks
  useEffect(async () => {
    const medicines = await getAllMedicines();
    const data =
      medicines &&
      medicines.data.map((x) => ({
        label: `${x.name} - ${x.entity_type_id}`,
        value: x.id,
      }));
    setMedicineList(data);
    getAllData();
  }, [offset]);

  // Get all active discounts list
  const getAllData = async () => {
    setLoading(true);
    const data = await getAllDiscounts(offset);
    setDiscountList(data.data);
    setLength(data.total);
    setLoading(false);
  };

  // Get a single discount
  const getDiscount = async (id) => {
    setLoading(true);
    const data = await getOneDiscount(id);
    console.log(data);
    setLoading(false);
  };

  const handleDelete = async () => {
    if (dataStatus.confirmBox === true) {
      setDataStatus({ confirmBox: false });
      setLoading(true);
      const data = await updateOneDiscount(dataStatus.id, {
        status_id: "inactive",
      });
      setLoading(false);
      if (Object.prototype.hasOwnProperty.call(data, "error")) {
        toast.error(`${data["error"]["message"]}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success(`${Notifications.deletedsuccess}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDataStatus({ confirmBox: false, id: "" });
        fetchData(offset);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = await addOneDiscount({
      medicine_id: event.target.medicine_id.value,
      value: event.target.value.value,
      type: event.target.type.value,
    });
    getAllData();
    setLoading(false);
  };

  const validationTablets = Yup.object({
    medicine_id: Yup.string().required("Medicine is required").nullable(),
    type: Yup.string().required("Medicine quantity is required").nullable(),
    value: Yup.string().required("Price is required").nullable(),
  });

  const cancelActivation = () => setDataStatus({ confirmBox: false, id: "" });

  const handleClick = (offset) => {
    setOffset(offset);
    if (searchValue !== "") {
      fetchData(offset);
    } else {
      search(searchValue);
    }
  };

  const search = async (value) => {
    if (value.length > 2) {
      setLoading(true);
      setSearchValue(value);
      setOffset(0);
      const data = await searchDiscounts(value, offset);
      setDiscountList(data.data);
      setLength(data.total);
      setLoading(false);
    } else {
      setOffset(0);
      setSearchValue("");
      fetchData();
    }
  };

  return (
    <>
      <Layout>
        <Formik
          validationSchema={validationTablets}
          onSubmit={(values, { resetForm }) => {
            resetForm();
          }}
          render={({ values }) => {
            return (
              <Form className="user" onSubmit={handleSubmit}>
                <div id="accordion1">
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link d-block w-100 text-dark font-weight-600"
                        aria-expanded="true"
                        data-toggle="collapse"
                        href="#collapseTwo3"
                      >
                        <h5 className="m-0 font-weight-bold text-primary">
                          Add New Discount
                        </h5>
                      </a>
                    </div>

                    <div
                      id="collapseTwo3"
                      className="collapse show"
                      data-parent="#accordion1"
                    >
                      <div className="card-body px-2 pt-2 pb-0">
                        <div id="accordion">
                          <div className="row">
                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12 has-float-label">
                              <label>Select Medicine</label>
                              <CreatableSelect
                                id="medicine_id"
                                options={medicineList}
                                menuPortalTarget={document.body}
                                menuPosition={"fixed"}
                                name="medicine_id"
                                required
                              />
                            </div>
                            <div className="form-group col-lg-3 col-md-3 col-sm-12 col-12 has-float-label">
                              <input
                                type="text"
                                placeholder="Enter Discount Type"
                                className="form-control-user form-control"
                                name="type"
                                required
                              />
                              <label>Discount Type</label>
                            </div>
                            <div className="form-group col-lg-3 col-md-3 col-sm-12 col-12 has-float-label">
                              <input
                                type="number"
                                placeholder="Enter Discount Type"
                                className="form-control-user form-control"
                                name="value"
                                min={0}
                                max={100}
                                step={0.01}
                                required
                              />
                              <label>Discount Value (%)</label>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="row">
                                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12 text-right">
                                  <button
                                    type="submit"
                                    className="btn btn-success btn-sm mx-2"
                                  >
                                    Save
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-secondary btn-sm"
                                  >
                                    {" "}
                                    Reset{" "}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        />
        <div className="card shadow mb-4 mt-3">
          <div className="card-header">
            <h5 className="m-0 font-weight-bold text-primary">
              Active Discounts List
            </h5>
          </div>
          <div className="card-body p-1">
            <input
              autoComplete="off"
              type="text"
              onChange={(e) => search(e.target.value)}
              placeholder="Search..."
              className="form-control-user form-control"
            />
            <Table
              data={discountList}
              minHeight={300}
              autoHeight={true}
              loading={loading}
              headerHeight={37}
              wordWrap
            >
              <Column width={50} verticalAlign="middle">
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey="id" />
              </Column>
              <Column flexGrow={2} verticalAlign="middle">
                <HeaderCell>Medicine</HeaderCell>
                <Cell>
                  {(data) => {
                    return (
                      <>
                        {" "}
                        {data.medicine.name} - {data.medicine.entity_type_id}{" "}
                      </>
                    );
                  }}
                </Cell>
              </Column>
              <Column flexGrow={2} verticalAlign="middle">
                <HeaderCell>Discount Type (Title)</HeaderCell>
                <Cell dataKey="type" />
              </Column>
              <Column flexGrow={2} verticalAlign="middle">
                <HeaderCell>Discount Value (%)</HeaderCell>
                <Cell dataKey="value" />
              </Column>
              <Column flexGrow={2} verticalAlign="middle">
                <HeaderCell>Date Added</HeaderCell>
                <Cell>{(data) => date(data["inserted_at"], "medium")}</Cell>
              </Column>
              <Column fixed="right" flexGrow={1} verticalAlign="middle">
                <HeaderCell>Actions</HeaderCell>
                <Cell>
                  {(data) => {
                    return (
                      <>
                        {/* {(() => {
                          if (2 < getPermissions("sales")) {
                            return (
                              <a
                                title="Edit"
                                data-toggle="tooltip"
                                className="cp"
                                onClick={() => getDiscount(data.id)}
                              >
                                {" "}
                                <Icon icon="edit2" />
                              </a>
                            );
                          }
                        })()} */}
                        <span>
                          {3 < getPermissions("sales") ? (
                            <a
                              href
                              data-toggle="tooltip"
                              className="cp"
                              title="Inactivate"
                              onClick={() =>
                                setDataStatus({ confirmBox: true, id: data.id })
                              }
                            >
                              <Icon icon="trash" />{" "}
                            </a>
                          ) : (
                            <span></span>
                          )}
                        </span>
                      </>
                    );
                  }}
                </Cell>
              </Column>
            </Table>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <Pagination
                limit={10}
                offset={offset}
                total={length}
                onClick={(e, offset) => handleClick(offset)}
              />
            </MuiThemeProvider>

            <SweetAlert
              show={dataStatus.confirmBox}
              showCancel
              confirmBtnText="Inactivate"
              confirmBtnBsStyle="error"
              title="Are you sure!"
              onConfirm={handleDelete}
              onCancel={cancelActivation}
              focusCancelBtn
              closeAnim={{ name: "hideSweetAlert", duration: 500 }}
            >
              You want to inactivate discount ID:{dataStatus.id}?
            </SweetAlert>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ActiveDiscounts;
