import { Link, useLocation } from "react-router-dom";
import SideBarItems from "./sidebar";

const Sidebar = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <ul
      className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${
        props.togglecls ? "toggled" : "no"
      }`}
      id="accordionSidebar"
    >
      <a
        className="sidebar-brand d-flex align-items-center justify-content-center"
        href="/"
      >
        <div className="sidebar-brand-icon">
          <i className="fas fa-heartbeat" />
        </div>
        <div className="sidebar-brand-text mx-3">Medical App</div>
      </a>
      <hr className="sidebar-divider my-0" />
      <li
        className={`nav-item ${
          splitLocation[1] === "dashboard" ? "active" : ""
        }`}
      >
        <Link className="nav-link" to={process.env.PUBLIC_URL + "/dashboard"}>
          <i className="fas fa-fw fa-tachometer-alt" />
          <span>Dashboard</span>
        </Link>
      </li>
      <hr className="sidebar-divider my-0" />
      <SideBarItems />
    </ul>
  );
};

export default Sidebar;
