import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "rsuite/dist/styles/rsuite-default.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import SweetAlert from "react-bootstrap-sweetalert";

import { post, put, remove } from "../../utils/api";
import Layout from "../layouts/index";
import { StocksForm, StocksTable } from "./components";
import { currentDate } from "../../utils/date.util";
import { getCurrency } from "../../services/settings.service";

// Configurations
const theme = createTheme();
toast.configure({ autoClose: 8000, draggable: false });

const defaultStockParams = {
  medicine_id: "",
  medicine_formula_id: "",
  total_stock_purchased: 0,
  purchased_from: "",
  purchase_date: currentDate(),
  price_per_pack: 0,
  expiry_date: "",
  delivered_by: "",
  stock_shelf: "",
  can_change_price: false,
  selling_price_per_pack: 0,
  resource: "stocks",
};

const defaultState = { data: [], total: 0 };

const Stocks = (props) => {
  const isActive = props["location"]["pathname"] === "/active-stocks";
  const [medicinesList, setMedicinesList] = useState(defaultState);
  const [stocksList, setStocksList] = useState(defaultState);
  const [stock, setStock] = useState(defaultStockParams);
  const [medicineFormulaList, setMedicineFormulaList] = useState([]);
  const [stocksParams, setStocksParams] = useState(defaultStockParams);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [reFetch, setReFetch] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [loading, setLoading] = useState();
  const [action, setAction] = useState("");
  const [dataStatus, setDataStatus] = useState({
    confirmbx: false,
    rowid: "",
    name: "",
  });
  const [currency, setCurrency] = useState("");

  useEffect(async () => {
    const currency = await getCurrency();
    setCurrency(currency);
  }, []);

  const fetchStock = async (id) =>
    post(
      "/stock-inventory",
      {
        resource: "stocks",
        method: "GET",
        id: id,
      },
      setStock,
      false
    ).then(() => setIsMounted(true));

  useEffect(() => {
    post(
      "/medicines",
      {
        resource: "medicines",
        status_id: "active",
        method: "GET",
        search: search,
        offset: offset,
      },
      setMedicinesList,
      false
    ).then();
  }, [search]);

  useEffect(() => {
    post(
      "/stock-inventories",
      {
        resource: "stocks",
        method: "GET",
        status_id: isActive ? "active" : "inactive",
        offset: offset,
      },
      setStocksList,
      false
    ).then();
  }, [isActive, offset, reFetch]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isMounted
      ? setStocksParams({ ...makeStocksParams(stock), resource: "stocks" })
      : null;
  }, [isMounted, stock]);

  const handleChange = (e) => {
    switch (e.fieldName || e.target.id) {
      case "can_change_price":
        setStocksParams({
          ...stocksParams,
          can_change_price: e.target.checked,
        });
        break;
      case "medicine_formula_id":
        console.log(medicineFormulaList);
        medicineFormulaList.filter((formula) =>
          formula.id == e.key
            ? setStocksParams({
                ...makeStocksParams(formula["stocks"][0], stocksParams),
                medicine_formula_id: e.key,
              })
            : null
        );
        break;
      case "medicine_id":
        setMedicineFormulaList([]);

        setStocksParams({ ...stocksParams, medicine_formula_id: NaN });

        medicinesList["data"].filter((medicine) =>
          medicine.id == e.key
            ? setMedicineFormulaList(medicine["medicine_formulas"])
            : null
        );
        break;
      default:
        setStocksParams({ ...stocksParams, [e.target.id]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    switch (action) {
      case "add":
        post(
          "/entity-stock-history",
          { ...stocksParams, method: "POST" },
          false
        ).then();
        setStocksParams(defaultStockParams);
        break;
      case "update":
        put(
          "/stock-inventory",
          { ...stocksParams, method: "PUT", id: stock["id"] },
          false
        ).then();
        setStocksParams(defaultStockParams);
        break;
      default:
        setStocksParams(defaultStockParams);
    }
    reFetch ? setReFetch(false) : setReFetch(true);
    e.preventDefault();
  };

  const handleDelete = () => {
    remove(
      "/stock-inventory",
      { id: dataStatus.rowid, method: "DELETE", resource: "stocks" },
      false
    ).then();
    setDataStatus({ confirmbx: false, rowid: "", name: "" });
    setReFetch(true);
  };

  const cancelActivation = () =>
    setDataStatus({ confirmbx: false, rowid: "", name: "" });

  return (
    <Layout>
      <div className="accordionformblk mb-3">
        <StocksForm
          action={action}
          setAction={setAction}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          medicinesList={medicinesList["data"]}
          medicineFormulaList={medicineFormulaList}
          stocksParams={stocksParams}
          setSearch={setSearch}
          isActive={isActive}
          currency={currency}
        />
      </div>
      <div className="card shadow mb-4">
        <div className="card-header">
          <h5 className="m-0 font-weight-bold text-primary">Stocks</h5>
        </div>
        <div className="card-body p-1">
          <StocksTable
            stocksList={stocksList["data"]}
            loading={loading}
            handleDelete={handleDelete}
            fetchStock={fetchStock}
            setAction={setAction}
            setDataStatus={setDataStatus}
          />
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Pagination
              limit={10}
              offset={offset}
              total={stocksList["total"]}
              onClick={(e, offset) => setOffset(offset)}
            />
          </MuiThemeProvider>
        </div>
      </div>
      <SweetAlert
        show={dataStatus.confirmbx}
        showCancel
        confirmBtnText="Inactivate"
        confirmBtnBsStyle="error"
        title="Are you sure!"
        onConfirm={handleDelete}
        onCancel={cancelActivation}
        focusCancelBtn
        closeAnim={{ name: "hideSweetAlert", duration: 500 }}
      >
        You want to inactivate {dataStatus.name}?
      </SweetAlert>
    </Layout>
  );
};

function makeStocksParams(stock, scatterStock = {}) {
  const purchase_date =
    scatterStock === {} ? currentDate() : stock["purchase_date"];
  return {
    ...scatterStock,
    total_stock_purchased: stock["total_stock_purchased"],
    purchased_from: stock["purchased_from"],
    purchase_date: purchase_date,
    price_per_pack: stock["entity_pricing"]["price_per_pack"],
    expiry_date: stock["expiry_date"],
    delivered_by: stock["delivered_by"],
    stock_shelf: stock["stock_shelf"],
    can_change_price: stock["can_change_price"],
    selling_price_per_pack: stock["entity_pricing"]["selling_price_per_pack"],
  };
}

export default Stocks;
