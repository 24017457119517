import moment from "moment";

// DateTime formatter for whole application
export const date = (date, type = "medium") => {
  switch (type) {
    case "short":
      return moment(date).format("DD/MM/YY LT");
    case "medium":
      return moment(date).format("lll");
    case "full":
      return moment(date).format("LLLL");
  }
};

// Return current datetime
export const currentDate = () => {
  return date(new Date());
};
