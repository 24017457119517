import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import "rsuite/dist/styles/rsuite-default.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import $ from "jquery";

import { getPermissions } from "../../../utils/permissions.util";
import Layout from "../../layouts/index";
import { date } from "../../../utils/date.util";
import { getAll, getOne } from "../../../services/sales.service";
import { getCurrency } from "../../../services/settings.service";

// Configurations
const theme = createTheme();

const SaleDrafts = () => {
  const history = useHistory();
  const [saleList, setList] = useState([]);
  const [loading, setloading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [alength, setalength] = useState();
  const [sale, setSale] = useState(null);
  const [currency, setCurrency] = useState("");

  // Hooks
  useEffect(() => fetchData(offset), [offset]);

  useEffect(async () => {
    const currency = await getCurrency();
    setCurrency(currency);
  }, []);

  const showSalePopup = async (id) => {
    const data = await getOne(id, "draft");
    setSale(data[0]);
    $("#sale-model").modal("show");
  };

  const fetchData = async (offset) => {
    setloading(true);
    const data = await getAll(offset, "draft");
    setList(data.data);
    setalength(data.total);
    setloading(false);
  };

  const handleClick = (offset) => {
    setOffset(offset);
    fetchData(offset);
  };

  return (
    <Layout>
      <div className="card shadow mb-4">
        <div className="card-header">
          <h5 className="m-0 font-weight-bold text-primary">Sale Drafts</h5>
        </div>
        <div className="card-body p-1">
          <Table
            wordWrap
            data={saleList}
            minHeight={300}
            autoHeight={true}
            loading={loading}
            headerHeight={37}
          >
            <Column flexGrow={1} verticalAlign="middle">
              <HeaderCell>Sale ID</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell>Total Amount</HeaderCell>
              <Cell>
                {(data) => {
                  return (
                    <>
                      {data.total_sale_amount} {currency}
                    </>
                  );
                }}
              </Cell>
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell>Medicines</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <div className="rolelist">
                      {(() => {
                        return rowData.sale_medicines.map((sale, i) => {
                          return (
                            <span
                              className={`badge badge-primary a${i}`}
                              key={i}
                            >
                              {sale.medicine.name}
                            </span>
                          );
                        });
                      })()}
                    </div>
                  );
                }}
              </Cell>
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell>Draft Added By</HeaderCell>
              <Cell>{(data) => data["user"]["name"]}</Cell>
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell>Date Added</HeaderCell>
              <Cell>{(data) => date(data["inserted_at"], "medium")}</Cell>
            </Column>
            <Column fixed="right" flexGrow={1} verticalAlign="middle">
              <HeaderCell>Actions</HeaderCell>
              <Cell>
                {(data) => {
                  return (
                    <>
                      <span>
                        {3 < getPermissions("medicine_sales") ? (
                          <a
                            href
                            data-toggle="tooltip"
                            className="cp text-dark"
                            title="View"
                            onClick={() => showSalePopup(data.id)}
                          >
                            <i className="fas fa-eye"></i>
                          </a>
                        ) : (
                          <span></span>
                        )}
                      </span>
                      <span>
                        {3 < getPermissions("medicine_sales") ? (
                          <a
                            onClick={() =>
                              history.push(`/sales?draft=${data.id}`)
                            }
                            className="cp"
                            data-toggle="tooltip"
                            title="Continue Sale"
                          >
                            <i className="fas fa-play text-success"></i>
                          </a>
                        ) : (
                          <span></span>
                        )}
                      </span>
                    </>
                  );
                }}
              </Cell>
            </Column>
          </Table>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Pagination
              limit={10}
              offset={offset}
              total={alength}
              onClick={(e, offset) => handleClick(offset)}
            />
          </MuiThemeProvider>
        </div>
      </div>
      <div className="modal modal-md fade" id="sale-model">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card shadow mb-0">
                  <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Sale Detail
                    </h6>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="card-body p-3 prw text-center">
                    {(() => {
                      if (sale) {
                        return (
                          <>
                            <div className="p-0">
                              Invoice Number: {sale.id} <br />
                              Date: {date(sale.inserted_at)} <br />
                              Generated By: {sale.user.name} <br /> <br />
                              <table className="table table-sale-receipt table-sm text-center">
                                <thead>
                                  <tr>
                                    <th>Item</th>
                                    <th>Quantity</th>
                                    <th>Type</th>
                                    <th>Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sale.sale_medicines.map((key, i) => (
                                    <tr key={i}>
                                      <td>{key.medicine.name}</td>
                                      <td>{key.quantity}</td>
                                      <td>{key.unit_id}</td>
                                      <td>{key.price}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <hr />
                              <div width={100} className="text-right">
                                Total Items: {sale.sale_medicines.length} <br />
                                Total Price: {sale.total_sale_amount}
                              </div>
                              <hr />
                            </div>
                          </>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SaleDrafts;
