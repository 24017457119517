import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import "rsuite/dist/styles/rsuite-default.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import SweetAlert from "react-bootstrap-sweetalert";

import Notifications from "../../utils/notifications";
import { getPermissions } from "../../utils/permissions.util.js";
import BASE_URL from "../../utils/api";
import Layout from "../layouts/index";
import { date } from "../../utils/date.util";
import { getToken } from "../../utils/session.util";
import { getAll } from "../../services/users.service";

// Configurations
const theme = createTheme();
toast.configure({ autoClose: 8000, draggable: false });

const InactiveUsers = () => {
  const [userslist, setUserslist] = useState([]);
  const [loading, setloading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [alength, setalength] = useState();
  const [datastatus, setDatastatus] = useState({
    confirmBox: false,
    id: "",
    name: "",
  });

  // Hooks
  useEffect(() => fetchData(offset), [offset]);

  const fetchData = async (offset) => {
    setloading(true);
    const data = await getAll(offset, "inactive");
    setloading(false);
    setUserslist(data.data);
    setalength(data.total);
  };

  function handleActivation() {
    if (datastatus.confirmBox === true) {
      setDatastatus({ confirmBox: false, id: "" });
      setloading(true);
      fetch(BASE_URL + "/user", {
        method: "DELETE",
        headers: {
          Origin: "*",
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          user_id: `${datastatus.id}`,
          resource: "employees",
          method: "DELETE",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (Object.prototype.hasOwnProperty.call(result, "error")) {
            toast.error(`${result["error"]["message"]}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            fetchData(offset);
            setDatastatus({ id: "" });
            toast.success(`${Notifications.deletedsuccess}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    }
  }

  const handleClick = (offset) => {
    setOffset(offset);
    fetchData(offset);
  };

  const activate = () => setDatastatus({ confirmBox: false, id: "", name: "" });

  return (
    <Layout>
      <div className="card shadow mb-4">
        <div className="card-header">
          <h5 className="m-0 font-weight-bold text-primary">
            Inactive Users List
          </h5>
        </div>
        <div className="card-body p-1">
          {(() => {
            return (
              <>
                <Table
                  data={userslist}
                  minHeight={300}
                  autoHeight={true}
                  loading={loading}
                  headerHeight={37}
                  wordWrap
                >
                  <Column minWidth={80} verticalAlign="middle">
                    <HeaderCell>ID</HeaderCell>
                    <Cell dataKey="id" />
                  </Column>
                  <Column flexGrow={2} verticalAlign="middle">
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name" />
                  </Column>
                  <Column minWidth={200} flexGrow={2} verticalAlign="middle">
                    <HeaderCell>Email/Phone</HeaderCell>
                    <Cell>
                      {(rowData) => {
                        return (
                          <span>{rowData.email || rowData.phone_number}</span>
                        );
                      }}
                    </Cell>
                  </Column>
                  <Column flexGrow={2} verticalAlign="middle">
                    <HeaderCell>Role</HeaderCell>
                    <Cell>
                      {(rowData) => {
                        return (
                          <div className="rolelist">
                            {(() => {
                              if (0 < getPermissions("users")) {
                                return rowData.roles.map((key, i) => {
                                  return (
                                    <span
                                      className={`badge badge-primary a${i}`}
                                      key={i}
                                    >
                                      {key.id}
                                    </span>
                                  );
                                });
                              }
                            })()}
                          </div>
                        );
                      }}
                    </Cell>
                  </Column>
                  <Column verticalAlign="middle">
                    <HeaderCell>Gender</HeaderCell>
                    <Cell dataKey="gender" />
                  </Column>
                  <Column minWidth={200} flexGrow={2} verticalAlign="middle">
                    <HeaderCell>Date Added</HeaderCell>
                    <Cell>{(data) => date(data["inserted_at"], "medium")}</Cell>
                  </Column>
                  <Column width={120} fixed="right" verticalAlign="middle">
                    <HeaderCell>Actions</HeaderCell>
                    <Cell>
                      {(rowData) => {
                        return (
                          <>
                            <span>
                              {3 < getPermissions("users") ? (
                                <a
                                  href
                                  className="cp"
                                  title="Activate"
                                  data-toggle="tooltip"
                                  onClick={() =>
                                    setDatastatus({
                                      confirmBox: true,
                                      id: rowData.id,
                                      name: rowData.name,
                                    })
                                  }
                                >
                                  <i className="fas fa-play text-success"></i>{" "}
                                </a>
                              ) : (
                                <span></span>
                              )}
                            </span>
                          </>
                        );
                      }}
                    </Cell>
                  </Column>
                </Table>
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <Pagination
                    limit={10}
                    offset={offset}
                    total={alength}
                    onClick={(e, offset) => handleClick(offset)}
                  />
                </MuiThemeProvider>
              </>
            );
          })()}
        </div>
      </div>
      <SweetAlert
        show={datastatus.confirmBox}
        showCancel
        confirmBtnText="Activate"
        confirmBtnBsStyle="success"
        title="Are you sure!"
        onConfirm={handleActivation}
        onCancel={activate}
        focusCancelBtn
        closeAnim={{ name: "hideSweetAlert", duration: 500 }}
      >
        You want to activate {datastatus.name}?
      </SweetAlert>
    </Layout>
  );
};

export default InactiveUsers;
