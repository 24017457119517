import CreatableSelect from "react-select/creatable";

const customStyles = {
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  option: (provided, state) => ({
    ...provided,
    opacity: 1,
    borderBottom: "1px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
};

export const SearchableSelectFull = ({
  rowIndex,
  id,
  label,
  handleSearch,
  handleChange,
  handleCreate,
  options,
}) => {
  return (
    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12 has-float-label">
      <label>{label}</label>
      <CreatableSelect
        id={id}
        options={options}
        menuPortalTarget={document.body}
        menuPosition={"fixed"}
        styles={customStyles}
        onChange={(e) => handleChange(e, rowIndex)}
        onCreateOption={handleCreate}
        onInputChange={handleSearch}
      />
    </div>
  );
};

export const SearchableSelect = ({
  rowIndex,
  id,
  label,
  handleSearch,
  handleChange,
  options,
}) => {
  return (
    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
      <label>{label}</label>
      <CreatableSelect
        id={id}
        options={options}
        menuPortalTarget={document.body}
        menuPosition={"fixed"}
        styles={customStyles}
        onChange={(e) => handleChange(e, rowIndex)}
        onInputChange={handleSearch}
      />
    </div>
  );
};

export const TextField = ({
  id,
  label,
  fieldType,
  placeHolder,
  value,
  handleChange,
  style,
}) => {
  const isCheckbox =
    fieldType === "checkbox" ? { defaultChecked: value } : { value: value };
  return (
    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
      <input
        id={id}
        name={label}
        {...isCheckbox}
        type={fieldType}
        placeholder={placeHolder}
        onChange={handleChange}
        className={`form-control-user form-control`}
        style={style}
      />

      <label htmlFor={id}>
        {label}
        <sup>*</sup>
      </label>
    </div>
  );
};

export const SelectField = ({ id, name, label, handleChange, options }) => {
  return (
    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
      <select
        id={id}
        name={name}
        onChange={handleChange}
        className={"form-control-user form-control"}
      >
        <option label={label} />
        {options}
      </select>
      <label htmlFor={id}>
        {name}
        <sup>*</sup>
      </label>
    </div>
  );
};

export const SelectOptions = ({
  dataList,
  keyForOption,
  keysForOptionValue,
}) => {
  return dataList?.map((item) => (
    <option key={item[keyForOption]} value={item[keyForOption]}>
      {buildValue(item, keysForOptionValue)}
    </option>
  ));
};

export const buildOptions = (dataList, id, keysForLabelValue, field) =>
  dataList?.map((item) => ({
    key: item[id],
    value: item[id],
    fieldName: field,
    label: buildValue(item, keysForLabelValue),
  }));

const buildValue = (item, keysForValue) =>
  keysForValue?.map((key) => item[key]).join(" ");
