import { toast } from "react-toastify";

import Notifications from "./notifications";
import { getToken } from "../utils/session.util";
import * as env from "../config/config";

const BASE_URL = env.config.BASE_URL;

let requestHeaders = (body, method) => {
  return {
    method: method,
    headers: {
      Origin: "*",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(body),
  };
};

const successToast = () => {
  toast.success(`${Notifications.successMsg}`, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const errorToast = () => {
  toast.error(`${Notifications.errorMsg}`, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const post = async (requestFor, body, setData, isShowToast = false) => {
  await fetch(BASE_URL + `${requestFor}`, requestHeaders(body, "POST"))
    .then((res) => res.json())
    .then((response) => {
      if (setData !== false) {
        setData(response);
      }
      // eslint-disable-next-line no-unused-expressions
      Object.prototype.hasOwnProperty.call(response, "error")
        ? errorToast()
        : isShowToast
        ? successToast()
        : null;
    });
};

export const put = async (requestFor, body, setData, isShowToast = true) => {
  await fetch(BASE_URL + `${requestFor}`, requestHeaders(body, "PUT"))
    .then((res) => res.json())
    .then((response) => {
      let list = response.data;
      if (setData !== false) {
        setData(list);
      }
      // eslint-disable-next-line no-unused-expressions
      Object.prototype.hasOwnProperty.call(response, "error")
        ? errorToast()
        : isShowToast
        ? successToast()
        : null;
    });
};

export const remove = async (requestFor, body, setData, isShowToast = true) => {
  await fetch(BASE_URL + `${requestFor}`, requestHeaders(body, "DELETE"))
    .then((res) => res.json())
    .then((response) => {
      let list = response.data;
      if (setData !== false) {
        setData(list);
      }
      // eslint-disable-next-line no-unused-expressions
      Object.prototype.hasOwnProperty.call(response, "error")
        ? errorToast()
        : isShowToast
        ? successToast()
        : null;
    });
};

export default BASE_URL;
