import { post, postNoAuth } from "../utils/http.util";

const resource = "users";

// Get all users
export const getAll = (offset, status_id = "active") => {
  return post("/users", { resource, method: "GET", offset, status_id }, "POST");
};

// Add a new user
export const addOne = (
  name,
  login,
  password,
  gender,
  roles,
  status_id = "active"
) => {
  return postNoAuth(
    "/register",
    {
      resource,
      method: "POST",
      name,
      login,
      password,
      gender,
      roles,
      status_id,
    },
    "POST"
  );
};
