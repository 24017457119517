import { post } from "../utils/http.util";

const resource = "entity_types";

// Get all types
export const getAll = (offset, status_id = "active") => {
  return post(
    "/entity-types",
    { resource, method: "GET", offset, status_id },
    "POST"
  );
};

// Get one type
export const getOne = (id, status_id = "active") => {
  return post(
    "/entity-type",
    { id, resource, method: "GET", status_id },
    "POST"
  );
};

// Add a new type
export const addOne = (name) => {
  return post("/entity-type", { name, resource, method: "POST" }, "POST");
};

// Update a single type
export const updateOne = (id, name, status_id = "active") => {
  return post(
    "/entity-type",
    { id, resource, name, method: "UPDATE", status_id },
    "PUT"
  );
};

// Delete one type
export const deleteOne = (id) => {
  return post(
    "/entity-type",
    { id, resource, method: "DELETE", status_id: "inactive" },
    "DELETE"
  );
};

export const searchTypes = (search, offset, status_id = "active") => {
  return post(
    "/entity-types",
    { offset, search, resource, method: "POST", status_id },
    "POST"
  );
};
