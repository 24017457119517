import { useEffect, useState } from "react";

import Layout from "../layouts/index";
import { getPermissions } from "../../utils/permissions.util";
import { getUserName } from "../../utils/session.util";
import Admindashboard from "./admin";

const Dashboard = () => {
  const [username, setUserName] = useState("");
  useEffect(() => {
    setUserName(getUserName());
  }, []);

  return (
    <Layout>
      {(() => {
        if (3 < getPermissions("roles")) {
          return <Admindashboard />;
        } else {
          return (
            <div>
              <h1 className="h3">Welcome {username}</h1>
            </div>
          );
        }
      })()}
    </Layout>
  );
};

export default Dashboard;
