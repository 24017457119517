import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import "rsuite/dist/styles/rsuite-default.css";
import { Icon } from "rsuite";
import Spinner from "react-bootstrap/Spinner";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import $ from "jquery";
import SweetAlert from "react-bootstrap-sweetalert";

import Notifications from "../../utils/notifications";
import BASE_URL from "../../utils/api";
import { getPermissions } from "../../utils/permissions.util";
import Layout from "../layouts/index";
import { date } from "../../utils/date.util";
import { getToken } from "../../utils/session.util";

// Configurations
const theme = createTheme();
toast.configure({ autoClose: 8000, draggable: false });

const ActiveRoles = () => {
  const [roles, setroles] = useState([]);
  const [rolepermlist, setRolepermlist] = useState([]);
  const [loading, setloading] = useState(false);
  const [prloading, setprloading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [alength, setalength] = useState();
  const [datastatus, setDatastatus] = useState({
    confirmBox: false,
    id: "",
    name: "",
  });

  // Hooks
  useEffect(() => fetchData(offset), [offset]);

  const fetchData = async (offset) => {
    setloading(true);
    await fetch(BASE_URL + "/roles", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "roles",
        method: "GET",
        offset: offset,
        status_id: "active",
      }),
    })
      .then((res) => res.json())
      .then(
        (resp) => {
          setroles(resp.data);
          setalength(resp.total);
          setloading(false);
        },
        (error) => {}
      );
  };

  const handleClick = (offset) => {
    setOffset(offset);
    fetchData(offset);
  };

  // roleslistpopup
  const Rolebyidfetch = async (ids, rolenam) => {
    $("#roleslistpopup").modal("show");
    setprloading(true);
    await fetch(BASE_URL + "/role", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "roles",
        method: "GET",
        id: `${ids}`,
        role: `${rolenam}`,
      }),
    })
      .then((res) => res.json())
      .then(
        (resp) => {
          setprloading(false);
          setRolepermlist(resp);
        },
        (error) => {}
      );
  };

  function handleDelete() {
    if (datastatus.confirmBox === true) {
      setDatastatus({ confirmBox: false, id: "" });
      setloading(true);
      fetch(BASE_URL + "/role", {
        method: "DELETE",
        headers: {
          Origin: "*",
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          id: `${datastatus.id}`,
          resource: "roles",
          name: `${datastatus.name}`,
          method: "DELETE",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (Object.prototype.hasOwnProperty.call(result, "error")) {
            toast.error(`${result["error"]["message"]}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            fetchData(offset);
            setDatastatus({ id: "", name: "" });
            toast.success(`${Notifications.deletedsuccess}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    }
  }

  const cancelActivation = () =>
    setDatastatus({ confirmBox: false, id: "", name: "" });

  // ======== Permissions Data =========
  let addbtns;
  if (1 < getPermissions("roles")) {
    addbtns = (
      <Link
        to={process.env.PUBLIC_URL + "/add-role"}
        className="btn-sm btn btn-primary btn-icon-split"
      >
        <span className="icon text-white-50">
          <i className="fas fa-plus"></i>
        </span>
        <span className="text">Add</span>
      </Link>
    );
  } else {
    addbtns = <span></span>;
  }

  return (
    <Layout>
      <div className="card shadow mb-4">
        <div className="card-header">
          <h5 className="m-0 font-weight-bold text-primary">
            Roles and Permissions List
          </h5>
          {addbtns}
        </div>
        <div className="card-body p-1">
          <Table
            data={roles}
            minHeight={300}
            loading={loading}
            autoHeight={true}
          >
            <Column width={80} verticalAlign="middle">
              <HeaderCell>ID</HeaderCell>
              <Cell>
                {(rowData, rowIndex) => {
                  return <span>{rowIndex + 1}</span>;
                }}
              </Cell>
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell>Role Name</HeaderCell>
              <Cell dataKey="name" />
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell>Date Added</HeaderCell>
              <Cell>{(data) => date(data["inserted_at"], "medium")}</Cell>
            </Column>
            <Column width={120} fixed="right" verticalAlign="middle">
              <HeaderCell>Actions</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <>
                      <a
                        onClick={() => Rolebyidfetch(rowData.id, rowData.name)}
                        className="cp mr-3 text-dark"
                      >
                        <i className="fas fa-eye"></i>
                      </a>
                      {(() => {
                        if (2 < getPermissions("roles")) {
                          return (
                            <Link
                              to={{
                                pathname: "/edit-role",
                                state: { id: rowData.id, role: rowData.name },
                              }}
                            >
                              {" "}
                              <Icon icon="edit2" />
                            </Link>
                          );
                        }
                      })()}
                      <span className="ml-3">
                        {3 < getPermissions("roles") ? (
                          <a
                            href
                            onClick={() =>
                              setDatastatus({
                                confirmBox: true,
                                id: rowData.id,
                                name: rowData.name,
                              })
                            }
                          >
                            <Icon icon="trash" />{" "}
                          </a>
                        ) : (
                          <span></span>
                        )}
                      </span>
                    </>
                  );
                }}
              </Cell>
            </Column>
          </Table>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Pagination
              limit={10}
              offset={offset}
              total={alength}
              onClick={(e, offset) => handleClick(offset)}
            />
          </MuiThemeProvider>
        </div>
      </div>

      {/* ==========  View Roles and Permissions  ======== */}
      <div className="modal fade" id="roleslistpopup">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card shadow mb-0">
                  <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Roles and Permission Details
                    </h6>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="card-body p-0 prw">
                    <div className="p-0">
                      {prloading ? (
                        <div className="prloader">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      ) : (
                        <table className="table table-striped table-sm">
                          <thead>
                            <tr>
                              <th>Resource</th>
                              <th>Create</th>
                              <th>Read</th>
                              <th>Update</th>
                              <th>Delete</th>
                              <th>No</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rolepermlist.map((key, i) => (
                              <tr key={i}>
                                <td className="text-capitalize">
                                  {key.resource.replace(/_/g, " ")}
                                </td>
                                {(() => {
                                  if (key.permission === 1) {
                                    return (
                                      <>
                                        <td>
                                          <i className="fas fa-check text-success"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-times"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-times"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-times"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-times"></i>
                                        </td>
                                      </>
                                    );
                                  } else if (key.permission === 2) {
                                    return (
                                      <>
                                        <td>
                                          <i className="fas fa-check text-success"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-check text-success"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-times text-danger"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-times text-danger"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-times text-danger"></i>
                                        </td>
                                      </>
                                    );
                                  } else if (key.permission === 3) {
                                    return (
                                      <>
                                        <td>
                                          <i className="fas fa-check text-success"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-check text-success"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-check text-success"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-times text-danger"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-times text-danger"></i>
                                        </td>
                                      </>
                                    );
                                  } else if (key.permission === 4) {
                                    return (
                                      <>
                                        <td>
                                          <i className="fas fa-check text-success"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-check text-success"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-check text-success"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-check text-success"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-times text-danger"></i>
                                        </td>
                                      </>
                                    );
                                  } else {
                                    return (
                                      <>
                                        <td>
                                          <i className="fas fa-times text-danger"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-times text-danger"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-times text-danger"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-times text-danger"></i>
                                        </td>
                                        <td>
                                          <i className="fas fa-check text-success"></i>
                                        </td>
                                      </>
                                    );
                                  }
                                })()}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SweetAlert
        show={datastatus.confirmBox}
        showCancel
        confirmBtnText="Inactivate"
        confirmBtnBsStyle="error"
        title="Are you sure!"
        onConfirm={handleDelete}
        onCancel={cancelActivation}
        focusCancelBtn
        closeAnim={{ name: "hideSweetAlert", duration: 500 }}
      >
        You want to inactivate {datastatus.name}?
      </SweetAlert>
    </Layout>
  );
};

export default ActiveRoles;
