export function getRole(key) {
  let cookie = localStorage.getItem(key);
  if (cookie) {
    let arr = cookie.split(":");
    arr = arr.reverse();
    let resti = arr[0].replace("0z54x3", "");
    let role = resti.replace("y9kv638", "");
    return role;
  }
}

export function setPermissionsList(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getPermissionsList(key) {
  return JSON.parse(localStorage.getItem(key) || "[]");
}

export function getPermissions(n) {
  let perm = getPermissionsList("permissions");
  let result;
  perm.filter((rule) => {
    if (rule.resource === `${n}`) {
      result = Math.max(rule.permission);
    }
  });
  return result;
}

export function getrolebsx(list) {
  return list.filter((x) => x.id === "employee").value;
}
