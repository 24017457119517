import React from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";

import { getPermissions } from "../../../utils/permissions.util";

const SideBarItems = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  $('[data-toggle="tooltip"]').tooltip({ boundary: "window" });

  return (
    <React.Fragment>
      {(() => {
        if (0 < getPermissions("brands")) {
          return (
            <li
              className={`nav-item ${
                splitLocation[1] === "active-brands" ? "active" : ""
              } ${splitLocation[1] === "inactive-brands" ? "active" : ""}`}
            >
              <a
                className={`nav-link ${
                  splitLocation[1] === "active-brands" ? "" : "collapsed"
                } ${splitLocation[1] === "inactive-brands" ? "" : "collapsed"}`}
                href="#"
                data-toggle="collapse"
                data-target="#collapseBrands"
                aria-expanded="true"
                aria-controls="collapseBrands"
              >
                <i className="fas fa-tags" />
                <span>Brands</span>
              </a>
              <div
                id="collapseBrands"
                className={`collapse ${
                  splitLocation[1] === "active-brands" ? "show" : ""
                } ${splitLocation[1] === "inactive-brands" ? "show" : ""}`}
                aria-labelledby="headingBrands"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "active-brands" ? "active" : ""
                    } `}
                    to="/active-brands"
                  >
                    <span> Active Brands List</span>
                  </Link>
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "inactive-brands" ? "active" : ""
                    }`}
                    to="/inactive-brands"
                  >
                    <span> Inactive Brands List</span>
                  </Link>
                </div>
              </div>
            </li>
          );
        } else {
          return <li />;
        }
      })()}
      {(() => {
        if (0 < getPermissions("doses")) {
          return (
            <li
              className={`nav-item ${
                splitLocation[1] === "active-doses" ? "active" : ""
              } ${splitLocation[1] === "inactive-doses" ? "active" : ""}`}
            >
              <a
                className={`nav-link ${
                  splitLocation[1] === "active-doses" ? "" : "collapsed"
                } ${splitLocation[1] === "inactive-doses" ? "" : "collapsed"}`}
                href="#"
                data-toggle="collapse"
                data-target="#collapseDoses"
                aria-expanded="true"
                aria-controls="collapseDoses"
              >
                <i className="fas fa-mortar-pestle" />
                <span>Doses</span>
              </a>
              <div
                id="collapseDoses"
                className={`collapse ${
                  splitLocation[1] === "active-doses" ? "show" : ""
                } ${splitLocation[1] === "inactive-doses" ? "show" : ""}`}
                aria-labelledby="headingDoses"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "active-doses" ? "active" : ""
                    }`}
                    to="/active-doses"
                  >
                    <span> Active Doses List</span>
                  </Link>
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "inactive-doses" ? "active" : ""
                    }`}
                    to="/inactive-doses"
                  >
                    <span> Inactive Doses List</span>
                  </Link>
                </div>
              </div>
            </li>
          );
        } else {
          return <li />;
        }
      })()}
      {(() => {
        if (0 < getPermissions("units")) {
          return (
            <li
              className={`nav-item ${
                splitLocation[1] === "active-units" ? "active" : ""
              } ${splitLocation[1] === "inactive-units" ? "active" : ""}`}
            >
              <a
                className={`nav-link ${
                  splitLocation[1] === "active-units" ? "" : "collapsed"
                } ${splitLocation[1] === "inactive-units" ? "" : "collapsed"}`}
                href="#"
                data-toggle="collapse"
                data-target="#collapseUnits"
                aria-expanded="true"
                aria-controls="collapseUnits"
              >
                <i className="fas fa-weight" />
                <span>Units</span>
              </a>
              <div
                id="collapseUnits"
                className={`collapse ${
                  splitLocation[1] === "active-units" ? "show" : ""
                } ${splitLocation[1] === "inactive-units" ? "show" : ""}`}
                aria-labelledby="headingUnits"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "active-units" ? "active" : ""
                    }`}
                    to="/active-units"
                  >
                    <span> Active Units List</span>
                  </Link>
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "inactive-units" ? "active" : ""
                    }`}
                    to="/inactive-units"
                  >
                    <span> Inactive Units List</span>
                  </Link>
                </div>
              </div>
            </li>
          );
        } else {
          return <li />;
        }
      })()}
      {(() => {
        if (0 < getPermissions("entity_types")) {
          return (
            <li
              className={`nav-item ${
                splitLocation[1] === "active-types" ? "active" : ""
              } ${splitLocation[1] === "inactive-types" ? "active" : ""}`}
            >
              <a
                className={`nav-link ${
                  splitLocation[1] === "active-types" ? "" : "collapsed"
                } ${splitLocation[1] === "inactive-types" ? "" : "collapsed"}`}
                href="#"
                data-toggle="collapse"
                data-target="#collapseEtypes"
                aria-expanded="true"
                aria-controls="collapseEtypes"
              >
                <i className="fas fa-cubes" />
                <span>Types</span>
              </a>
              <div
                id="collapseEtypes"
                className={`collapse ${
                  splitLocation[1] === "active-types" ? "show" : ""
                } ${splitLocation[1] === "inactive-types" ? "show" : ""}`}
                aria-labelledby="headingEtypes"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "active-types" ? "active" : ""
                    }`}
                    to="/active-types"
                  >
                    <span> Active Types List</span>
                  </Link>
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "inactive-types" ? "active" : ""
                    }`}
                    to="/inactive-types"
                  >
                    <span> Inactive Types List</span>
                  </Link>
                </div>
              </div>
            </li>
          );
        } else {
          return <li />;
        }
      })()}
      {(() => {
        if (0 < getPermissions("salts")) {
          return (
            <li
              className={`nav-item ${
                splitLocation[1] === "active-salts" ? "active" : ""
              } ${splitLocation[1] === "inactive-salts" ? "active" : ""}`}
            >
              <a
                className={`nav-link ${
                  splitLocation[1] === "active-salts" ? "" : "collapsed"
                } ${splitLocation[1] === "inactive-salts" ? "" : "collapsed"}`}
                href="#"
                data-toggle="collapse"
                data-target="#collapseEtype"
                aria-expanded="true"
                aria-controls="collapseEtype"
              >
                <i className="fas fa-flask" />
                <span>Salts</span>
              </a>
              <div
                id="collapseEtype"
                className={`collapse ${
                  splitLocation[1] === "active-salts" ? "show" : ""
                } ${splitLocation[1] === "inactive-salts" ? "show" : ""}`}
                aria-labelledby="headingEtype"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "active-salts" ? "active" : ""
                    }`}
                    to="/active-salts"
                  >
                    <span> Active Salts List</span>
                  </Link>
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "inactive-salts" ? "active" : ""
                    }`}
                    to="/inactive-salts"
                  >
                    <span> Inactive Salts List</span>
                  </Link>
                </div>
              </div>
            </li>
          );
        } else {
          return <li />;
        }
      })()}
      {(() => {
        if (0 < getPermissions("medicines")) {
          return (
            <li
              className={`nav-item ${
                splitLocation[1] === "active-medicines" ? "active" : ""
              } ${splitLocation[1] === "inactive-medicines" ? "active" : ""}`}
            >
              <a
                className={`nav-link ${
                  splitLocation[1] === "active-medicines" ? "" : "collapsed"
                } ${
                  splitLocation[1] === "inactive-medicines" ? "" : "collapsed"
                }`}
                href="#"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                <i className="fas fa-fw fa-capsules" />
                <span>Medicines</span>
              </a>
              <div
                id="collapseThree"
                className={`collapse ${
                  splitLocation[1] === "active-medicines" ? "show" : ""
                } ${splitLocation[1] === "inactive-medicines" ? "show" : ""}`}
                aria-labelledby="headingThree"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "active-medicines" ? "active" : ""
                    }`}
                    to="/active-medicines"
                  >
                    <span> Active Medicines</span>
                  </Link>
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "inactive-medicines" ? "active" : ""
                    }`}
                    to="/inactive-medicines"
                  >
                    <span> Inactive Medicines</span>
                  </Link>
                </div>
              </div>
            </li>
          );
        } else {
          return <li />;
        }
      })()}
      {(() => {
        if (0 < getPermissions("stocks")) {
          return (
            <li
              className={`nav-item ${
                splitLocation[1] === "active-stocks" ? "active" : ""
              } ${splitLocation[1] === "inactive-stocks" ? "active" : ""}`}
            >
              <a
                className={`nav-link ${
                  splitLocation[1] === "active-stocks" ? "" : "collapsed"
                } ${splitLocation[1] === "inactive-stocks" ? "" : "collapsed"}`}
                href="#"
                data-toggle="collapse"
                data-target="#collapseThreee"
                aria-expanded="true"
                aria-controls="collapseThreee"
              >
                <i className="fas fa-fw fa-store" />
                <span>Stocks</span>
              </a>
              <div
                id="collapseThreee"
                className={`collapse ${
                  splitLocation[1] === "active-stocks" ? "show" : ""
                } ${splitLocation[1] === "inactive-stocks" ? "show" : ""}`}
                aria-labelledby="headingThree"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "active-stocks" ? "active" : ""
                    }`}
                    to="/active-stocks"
                  >
                    <span> Active Stocks</span>
                  </Link>
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "inactive-stocks" ? "active" : ""
                    }`}
                    to="/inactive-stocks"
                  >
                    <span> Inactive Stocks</span>
                  </Link>
                </div>
              </div>
            </li>
          );
        } else {
          return <li />;
        }
      })()}
      {(() => {
        if (0 < getPermissions("medicine_sales")) {
          return (
            <li
              className={`nav-item ${
                splitLocation[1] === "sales" ? "active" : ""
              } ${splitLocation[1] === "sale-logs" ? "active" : ""} ${
                splitLocation[1] === "sale-drafts" ? "active" : ""
              }`}
            >
              <a
                className={`nav-link ${
                  splitLocation[1] === "sales" ? "" : "collapsed"
                } ${splitLocation[1] === "sale-logs" ? "" : "collapsed"} ${
                  splitLocation[1] === "sale-drafts" ? "" : "collapsed"
                }`}
                href="#"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="true"
                aria-controls="collapseFour"
              >
                <i className="fas fa-fw fa-layer-group" />
                <span>Sales</span>
              </a>
              <div
                id="collapseFour"
                className={`collapse ${
                  splitLocation[1] === "sales" ? "show" : ""
                } ${splitLocation[1] === "sale-logs" ? "show" : ""} ${
                  splitLocation[1] === "sale-drafts" ? "show" : ""
                }`}
                aria-labelledby="headingFour"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "sales" ? "active" : ""
                    }`}
                    to="/sales"
                  >
                    <span>Sales</span>
                  </Link>
                  {/* <Link className={`collapse-item ${splitLocation[1] === "sale-logs" ? "active" : ""}`} to="/sale-logs">
                  <span>Sale Logs</span></Link> */}
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "sale-drafts" ? "active" : ""
                    }`}
                    to="/sale-drafts"
                  >
                    <span>Sale Drafts</span>
                  </Link>
                </div>
              </div>
            </li>
          );
        } else {
          return <li />;
        }
      })()}
      {(() => {
        if (0 < getPermissions("sales")) {
          return (
            <li
              className={`nav-item ${
                splitLocation[1] === "active-discounts" ? "active" : ""
              } ${splitLocation[1] === "inactive-discounts" ? "active" : ""}`}
            >
              <a
                className={`nav-link ${
                  splitLocation[1] === "active-discounts" ? "" : "collapsed"
                } ${
                  splitLocation[1] === "inactive-discounts" ? "" : "collapsed"
                }`}
                href="#"
                data-toggle="collapse"
                data-target="#collapseDiscounts"
                aria-expanded="true"
                aria-controls="collapseDiscounts"
              >
                <i className="fas fa-fw fa-users" />
                <span>Discounts</span>
              </a>
              <div
                id="collapseDiscounts"
                className={`collapse ${
                  splitLocation[1] === "active-discounts" ? "show" : ""
                } ${splitLocation[1] === "inactive-discounts" ? "show" : ""}`}
                aria-labelledby="headingDiscounts"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "active-discounts" ? "active" : ""
                    } `}
                    to="/active-discounts"
                  >
                    <span> Active Discounts</span>
                  </Link>
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "inactive-discounts" ? "active" : ""
                    }`}
                    to="/inactive-discounts"
                  >
                    <span> Inactive Discounts</span>
                  </Link>
                </div>
              </div>
            </li>
          );
        } else {
          return <li />;
        }
      })()}
      {(() => {
        if (0 < getPermissions("roles")) {
          return (
            <li
              className={`nav-item ${
                splitLocation[1] === "active-roles" ? "active" : ""
              } ${splitLocation[1] === "inactive-roles" ? "active" : ""}`}
            >
              <hr className="sidebar-divider my-0" />
              <a
                className={`nav-link ${
                  splitLocation[1] === "active-roles" ? "" : "collapsed"
                } ${splitLocation[1] === "inactive-roles" ? "" : "collapsed"}`}
                href="#"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <i className="fas fa-fw fa-cog" />
                <span>Roles & Permissions</span>
              </a>
              <div
                id="collapseTwo"
                className={`collapse ${
                  splitLocation[1] === "active-roles" ? "show" : ""
                } ${splitLocation[1] === "inactive-roles" ? "show" : ""}`}
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "active-roles" ? "active" : ""
                    }`}
                    to="/active-roles"
                  >
                    <span>Active Roles List</span>
                  </Link>
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "inactive-roles" ? "active" : ""
                    }`}
                    to="/inactive-roles"
                  >
                    <span>Inactive Roles List</span>
                  </Link>
                </div>
              </div>
            </li>
          );
        } else {
          return <li />;
        }
      })()}
      {(() => {
        if (0 < getPermissions("users")) {
          return (
            <li
              className={`nav-item ${
                splitLocation[1] === "active-users" ? "active" : ""
              } ${splitLocation[1] === "inactive-users" ? "active" : ""}`}
            >
              <a
                className={`nav-link ${
                  splitLocation[1] === "active-users" ? "" : "collapsed"
                } ${splitLocation[1] === "inactive-users" ? "" : "collapsed"}`}
                href="#"
                data-toggle="collapse"
                data-target="#collapseUsers"
                aria-expanded="true"
                aria-controls="collapseUsers"
              >
                <i className="fas fa-fw fa-users" />
                <span>Users</span>
              </a>
              <div
                id="collapseUsers"
                className={`collapse ${
                  splitLocation[1] === "active-users" ? "show" : ""
                } ${splitLocation[1] === "inactive-users" ? "show" : ""}`}
                aria-labelledby="headingUsers"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "active-users" ? "active" : ""
                    } `}
                    to="/active-users"
                  >
                    <span> Active Users List</span>
                  </Link>
                  <Link
                    className={`collapse-item ${
                      splitLocation[1] === "inactive-users" ? "active" : ""
                    }`}
                    to="/inactive-users"
                  >
                    <span> Inactive Users List</span>
                  </Link>
                </div>
              </div>
            </li>
          );
        } else {
          return <li />;
        }
      })()}
      {(() => {
        if (0 < getPermissions("system_settings")) {
          return (
            <>
              <hr className="sidebar-divider my-0" />
              <li
                className={`nav-item ${
                  splitLocation[1] === "system-settings" ? "active" : ""
                }`}
              >
                <Link
                  className="nav-link"
                  to={process.env.PUBLIC_URL + "/system-settings"}
                >
                  <i className="fas fa-fw fa-tachometer-alt" />
                  <span>System Settings</span>
                </Link>
              </li>
            </>
          );
        } else {
          return <li />;
        }
      })()}
    </React.Fragment>
  );
};

export default SideBarItems;
