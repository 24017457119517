import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import { setPermissionsList } from "../../../utils/permissions.util";
import { getToken } from "../../../utils/session.util";
import { setLocalStorage } from "../../../utils/session.util";
import { NavLink } from "react-router-dom";
import Notifications from "../../../utils/notifications";
import { login } from "../../../services/auth.service";

toast.configure({ autoClose: 8000, draggable: false });

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setloading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (getToken()) {
      history.push("/dashboard");
    } else {
      history.push("/login");
    }
  }, []);

  const fldChange = () => {
    setloading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setloading(true);
    const data = await login(email, password);
    setloading(false);
    if (data.token === null || data.token === undefined) {
      setloading(false);
      toast.error(`${Notifications.invalidcred}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      let perm = data.permissions;
      let reslt;
      perm.filter((key) => {
        if (key.resource === "users") {
          reslt = key.role;
        }
      });
      setloading(false);
      setLocalStorage("token", data.token + ":0z54x3" + reslt + "y9kv638");
      history.push("/sales");
      window.location.href = "/sales";
      setPermissionsList("permissions", data.permissions);
      setLocalStorage("username", data.name);
    }
  };

  return (
    <Container>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xl={10} lg={12} md={9}>
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <Row>
                <Col
                  lg={6}
                  className="d-none d-lg-block bg-login-image"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/loginimg.jpg`,
                  }}
                ></Col>
                <Col lg={6}>
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                    </div>
                    <Form
                      noValidate
                      validated={false}
                      className="user"
                      onSubmit={handleSubmit}
                    >
                      <Form.Group
                        className="mb-3"
                        controlId="validationCustom01"
                      >
                        <Form.Control
                          className="form-control-user"
                          name="text"
                          onChange={(e) => setEmail(e.target.value)}
                          onFocus={fldChange}
                          type="text"
                          placeholder="Enter email"
                          required
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="validationCustom02"
                      >
                        <Form.Control
                          className="form-control-user"
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                          onFocus={fldChange}
                          type="password"
                          placeholder="Password"
                          required
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={loading}
                        className="btn btn-primary btn-block"
                      >
                        Submit
                      </Button>
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="primary"
                          className="mt-3"
                        />
                      ) : (
                        <span></span>
                      )}
                    </Form>
                    <hr />
                    <div className="text-center">
                      <NavLink
                        className="small"
                        to={process.env.PUBLIC_URL + "/forgot-password"}
                      >
                        Forgot Password?
                      </NavLink>
                    </div>
                    <div className="text-center">
                      <NavLink
                        className="small"
                        to={process.env.PUBLIC_URL + "/register"}
                      >
                        Create an Account!
                      </NavLink>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
