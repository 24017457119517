import { useState, useEffect, Fragment } from "react";
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import { Icon } from "rsuite";
import $ from "jquery";
import { useHistory, Link, useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import BASE_URL from "../../utils/api";
import Notifications from "../../utils/notifications";
import Layout from "../layouts/index";
import { getPermissions } from "../../utils/permissions.util";
import { getToken } from "../../utils/session.util";

// Configurations
toast.configure({ autoClose: 8000, draggable: false });

const EditMedicine = () => {
  const entity_typeid = "0";
  const [entity_type_list, setEntity_type_list] = useState([]);
  const [brand_id_list, setBrand_id_list] = useState([]);
  const [unit_id_list, setUnit_id_list] = useState([]);
  const [loading, setloading] = useState(false);
  const [prloading, setprloading] = useState(false);
  const [salt_id_list, setSalt_id_list] = useState([]);
  const [dose_id_list, setDose_id_list] = useState([]);

  // Edit Fields
  const [medicine_id, setMedicine_id] = useState("");
  const [medicine_name, setMedicine_name] = useState("");
  const [entity_type_id, setEntity_type_id] = useState("");
  const [brand_id, setBrand_id] = useState("");
  const [inputFields, setInputFields] = useState([]);
  const [inputField, setInputField] = useState([
    { salt_id: "", dose_id: "", quantity: "" },
  ]);
  const [unit_id, setUnit_id] = useState("");
  const [units_id, setUnits_id] = useState("");
  const [strips_per_packe, setStrips_per_packe] = useState("");
  const [price_per_packe, setPrice_per_packe] = useState("");
  const [units_per_stripe, setUnits_per_stripe] = useState("");
  const [md_id, setMd_id] = useState("");

  //   Edit Salts Area Fields
  const [price_per_pack, setPrice_per_pack] = useState("");
  const [selling_price_per_pack, setSelling_price_per_pack] = useState("");
  const [units_per_strip, setUnits_per_strip] = useState("");
  const [price_per_strip, setPrice_per_strip] = useState("");
  const [price_per_unit, setPrice_per_unit] = useState("");
  const [strips_per_packs, setStrips_per_packs] = useState("");
  const [medicine_ids, setMedicine_ids] = useState("");
  const [med_formula_id, setMed_formula_id] = useState("");
  const [salted_list, setSalted_list] = useState([]);
  const [sloading, setsloading] = useState(false);

  const history = useHistory();
  const { state } = useLocation();

  useEffect(() => {
    fetchSaltsList();
    fetchDoseList();
    $(document).on("show.bs.modal", function (event) {
      if (!event.relatedTarget) {
        $(".modal").not(event.target).modal("hide");
      }
      if ($(event.relatedTarget).parents(".modal").length > 0) {
        $(event.relatedTarget).parents(".modal").modal("hide");
      }
    });
    $(document).on("shown.bs.modal", function (event) {
      if ($("body").hasClass("modal-open") === false) {
        $("body").addClass("modal-open");
      }
    });
    fetchDataUnits();
    fetchEntityTypeList();
    fetchBrandsList();
    fetchUnitsList();
    fetchMedicinesList();
  }, []);

  const fetchMedicinesList = async () => {
    setprloading(true);
    await fetch(BASE_URL + "/medicine", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        id: state,
        resource: "medicines",
        method: "GET",
      }),
    })
      .then((res) => res.json())
      .then(
        (response) => {
          setprloading(false);
          setMedicine_id(response.id);
          setMedicine_name(response.name);
          setEntity_type_id(response.entity_type_id);
          setBrand_id(response.brand_id);
          let med_formulas = response.medicine_formulas;
          let rst = med_formulas.filter((key) => {
            if (key.status_id === "active") {
              return key;
            }
          });
          setSalted_list(rst);
        },
        (error) => {}
      );
  };

  // Fetch all medicines
  const fetchData = async () => {
    await fetch(BASE_URL + "/medicines", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "medicines",
        offset: 0,
        method: "GET",
      }),
    })
      .then((res) => res.json())
      .then(
        (resp) => {},
        (error) => {}
      );
  };

  // Entity Type List
  const fetchEntityTypeList = async () => {
    await fetch(BASE_URL + "/entity-types", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "entity_types",
        method: "GET",
      }),
    })
      .then((res) => res.json())
      .then(
        (response) => {
          setEntity_type_list(response.data);
        },
        (error) => {}
      );
  };

  // Brands List
  const fetchBrandsList = async () => {
    await fetch(BASE_URL + "/brands", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "brands",
        method: "GET",
      }),
    })
      .then((res) => res.json())
      .then(
        (response) => {
          setBrand_id_list(response.data);
        },
        (error) => {}
      );
  };

  // Units List
  const fetchUnitsList = async () => {
    await fetch(BASE_URL + "/units", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "units",
        method: "GET",
      }),
    })
      .then((res) => res.json())
      .then(
        (response) => {
          setUnit_id_list(response.data);
        },
        (error) => {}
      );
  };

  // Salts List
  const fetchSaltsList = async () => {
    await fetch(BASE_URL + "/salts", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "salts",
        method: "GET",
      }),
    })
      .then((res) => res.json())
      .then(
        (response) => {
          setSalt_id_list(response.data);
        },
        (error) => {}
      );
  };

  // Dose List
  const fetchDoseList = async () => {
    await fetch(BASE_URL + "/doses", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "doses",
        method: "GET",
      }),
    })
      .then((res) => res.json())
      .then(
        (response) => {
          setDose_id_list(response.data);
        },
        (error) => {}
      );
  };

  const fldChange = () => {
    // setloading(false);
  };

  // Medicines Update Function
  const handleUpdate = async (e) => {
    e.preventDefault();
    setloading(true);
    await fetch(BASE_URL + `/medicine`, {
      method: "PUT",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        id: state,
        name: medicine_name,
        entity_type_id: entity_type_id,
        brand_id: brand_id,
        resource: "medicines",
        method: "UPDATE",
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (Object.prototype.hasOwnProperty.call(result, "error")) {
          fetchData();
          toast.error(`${result["error"]["message"]}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setloading(false);
          history.push("/active-medicines");
          toast.success(`${Notifications.addedsuccess}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  // Edit Formulas Functions start
  const fetchDataUnits = async () => {
    await fetch(BASE_URL + "/units", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "units",
        method: "GET",
      }),
    })
      .then((res) => res.json())
      .then(
        (response) => {
          setUnit_id_list(response.data);
        },
        (error) => {}
      );
  };

  function handleDeleteFormula(id) {
    fetch(BASE_URL + "/medicine", {
      method: "DELETE",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        medicine_formula_id: `${id}`,
        resource: "medicines",
        method: "DELETE",
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (Object.prototype.hasOwnProperty.call(result, "error")) {
          fetchData();
          toast.error(`${result["error"]["message"]}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          fetchData();
          $("#listsaltmodal").modal("hide");
          toast.success(`${Notifications.deletedsuccess}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  }

  // Clone Form Scripts
  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ salt_id: "", dose_id: "", quantity: "", id: 0 });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "salt_id") {
      values[index].salt_id = event.target.value;
    } else if (event.target.name === "dose_id") {
      values[index].dose_id = event.target.value;
    } else {
      values[index].quantity = event.target.value;
    }
    setInputFields(values);
  };

  // Salt Delete
  const handleRemoveFun = async (id) => {
    await fetch(BASE_URL + "/medicine-salt", {
      method: "DELETE",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "medicine_salts",
        method: "DELETE",
        id: `${id}`,
      }),
    })
      .then((res) => res.json())
      .then(
        (response) => {
          fetchData();
        },
        (error) => {}
      );
  };

  // Update Medicine Formulas function
  const updateFormulasfun = async (e) => {
    e.preventDefault();
    setloading(true);
    await fetch(BASE_URL + `/medicine`, {
      method: "PUT",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        medicine_formula_id: `${medicine_ids}`,
        units_per_strip: units_per_strip,
        entity_type_id: entity_type_id,
        unit_id: unit_id,
        strips_per_pack: strips_per_packs,
        salts: inputFields,
        price_per_pack: price_per_pack,
        selling_price_per_pack: selling_price_per_pack,
        resource: "medicines",
        method: "UPDATE",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setloading(false);
          fetchData();
          if (Object.prototype.hasOwnProperty.call(result, "error")) {
            toast.error(`${result["error"]["message"]}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            setloading(false);
            $("#editsaltsmodal").modal("hide");
            toast.success(`${Notifications.addedsuccess}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        },
        (error) => {
          setloading(false);
        }
      );
  };

  // Get Dose and salts data in popup
  const handleEditSalts = (id, med_id) => {
    fetchData();
    const values = [...inputFields];
    salted_list.filter((key) => {
      if (key.id === id) {
        key.salts.map((k, l) => {
          values.push({
            salt_id: k.salt_id,
            dose_id: k.dose_id,
            quantity: k.quantity,
            id: k.id,
          });
        });
        setInputFields(values);
        setPrice_per_pack(key.entity_pricing.price_per_pack);
        setPrice_per_strip(key.entity_pricing.price_per_strip);
        setPrice_per_unit(key.entity_pricing.price_per_unit);
        setSelling_price_per_pack(key.entity_pricing.selling_price_per_pack);
        setUnits_per_strip(key.entity_pricing.units_per_strip);
        setStrips_per_packs(key.entity_pricing.strips_per_pack);
        setUnit_id(key.entity_pricing.unit_id);
        setMedicine_ids(key.id);
        setMed_formula_id(key.id);
        setMd_id(key.medicine_id);
      }
    });
  };

  const onchangeunits = (e) => {
    setUnit_id(e.target.value);
  };

  // Close Modals
  const editsaltsmodal = (md_f_id, brand, name) => {
    $("#listsaltmodal").modal("show");
    $("#editsaltsmodal").modal("hide");
    setInputFields([]);
    setPrice_per_pack("");
    setPrice_per_strip("");
    setPrice_per_unit("");
    setUnits_per_strip("");
    setStrips_per_packs("");
    setUnit_id("");
    setMedicine_ids("");
  };

  const updatemodal = () => {
    $("#editsaltsmodal").modal("show");
    $("#updatemodal").modal("hide");
  };
  const addSaltsfun = () => {
    $("#addformulamodal").modal("show");
    $("#updatesmodal").modal("hide");
  };

  const Openformulamodal = () => {
    $("#listsaltmodal").modal("show");
    $("#addformulamodal").modal("hide");
  };

  // Add Salts from Medicine Formulas
  const handleAddField = () => {
    const values = [...inputField];
    values.push({ salt_id: "", dose_id: "", quantity: "" });
    setInputField(values);
  };

  const handleRemoveField = (index) => {
    const values = [...inputField];
    values.splice(index, 1);
    setInputField(values);
  };

  const handleInputChang = (index, event) => {
    const values = [...inputField];
    if (event.target.name === "salt_id") {
      values[index].salt_id = event.target.value;
    } else if (event.target.name === "dose_id") {
      values[index].dose_id = event.target.value;
    } else {
      values[index].quantity = event.target.value;
    }
    setInputField(values);
  };

  // Add Formula new popup function
  const addnewformulafun = async (e) => {
    e.preventDefault();
    setsloading(true);
    await fetch(BASE_URL + "/medicine", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        name: medicine_name,
        units_per_strip: units_per_stripe,
        strips_per_pack: strips_per_packe,
        price_per_pack: price_per_packe,
        selling_price_per_pack: selling_price_per_pack,
        unit_id: units_id,
        entity_type_id: entity_type_id,
        brand_id: brand_id,
        salts: inputField,
        resource: "medicines",
        method: "POST",
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (Object.prototype.hasOwnProperty.call(result, "error")) {
          toast.error(`${result["error"]["message"]}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setsloading(false);
          $("#addformulamodal").modal("hide");
          toast.success(`${Notifications.addedsuccess}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  return (
    <Layout>
      <Row>
        <Col lg={6} md={8} sm={12}>
          <Link
            to={process.env.PUBLIC_URL + "/active-medicines"}
            className="btn btn-secondary btn-icon-split mb-3"
          >
            <span className="icon text-white-50">
              <i className="fas fa-arrow-left"></i>
            </span>
            <span className="text">Back</span>
          </Link>
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Edit Medicine
              </h6>
              <a
                href="#listsaltmodal"
                data-toggle="modal"
                className="btn btn-primary btn-sm btn-icon-split"
              >
                <span className="text">Edit Formula</span>
              </a>
            </div>
            <div className="card-body">
              <div className="p-4">
                {prloading ? (
                  <>
                    <div className="prloader">
                      <Spinner
                        animation="border"
                        variant="primary"
                        className="mt-3"
                      />
                    </div>
                  </>
                ) : (
                  <form className="user" onSubmit={handleUpdate}>
                    <div className="row">
                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12">
                        <label htmlFor="name">Name</label>
                        <input
                          name="name"
                          type="text"
                          onFocus={fldChange}
                          onChange={(e) => setMedicine_name(e.target.value)}
                          value={medicine_name}
                          className={"form-control-user form-control"}
                        />
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12">
                        <label htmlFor="stocktype">Medicine Type</label>
                        <select
                          onFocus={fldChange}
                          onChange={(e) => setEntity_type_id(e.target.value)}
                          value={entity_type_id}
                          name="entity_type_id"
                          className={"form-control-user form-control"}
                        >
                          <option defaultValue={entity_typeid}>
                            Select Medicine Type
                          </option>
                          {entity_type_list.map((stock, i) => (
                            <option key={i} value={stock.id}>
                              {stock.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12">
                        <label htmlFor="stocktype">Brands</label>
                        <select
                          onFocus={fldChange}
                          onChange={(e) => setBrand_id(e.target.value)}
                          value={brand_id}
                          name="brand_id"
                          className={"form-control-user form-control"}
                        >
                          <option defaultValue={entity_typeid}>
                            Select Brand
                          </option>
                          {brand_id_list.map((stock, i) => (
                            <option key={i} value={stock.id}>
                              {stock.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group mt-2 mb-0 col-lg-4 col-md-6 col-sm-12 col-12">
                        <button
                          type="submit"
                          disabled={loading}
                          className="btn-user btn-block btn btn-primary mb-2"
                        >
                          Submit
                        </button>
                        {loading ? (
                          <Spinner
                            animation="border"
                            variant="primary"
                            className="mt-3"
                          />
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/*  Edit Formulas with Salts  */}
      <div className="modal fade" id="listsaltmodal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card shadow mb-0">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Medicine Formulas
                    </h6>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="text-right px-2">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#addformulamodal"
                        className="btn-user btn-sm btn btn-secondary"
                      >
                        + Formula
                      </button>
                    </div>
                    <div className="p-2">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="border-bottom-0 border-top-0  text-dark">
                                  Medicine name
                                </th>
                                <th className="border-bottom-0 border-top-0  text-dark">
                                  Brand name
                                </th>

                                <th className="border-bottom-0 border-top-0  text-dark">
                                  Salts
                                </th>
                                <th className="border-bottom-0 border-top-0  text-dark">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {salted_list.map((key, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      {
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {key.medicine.name.replace(/_/g, " ")}
                                        </span>
                                      }
                                    </td>
                                    <td>
                                      {
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {key.medicine.brand_id.replace(
                                            /_/g,
                                            " "
                                          )}
                                        </span>
                                      }
                                    </td>
                                    <td>
                                      {
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {key.salts.length}
                                        </span>
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {(() => {
                                        if (2 < getPermissions("medicines")) {
                                          return (
                                            <a
                                              alt={key.id}
                                              href="#editsaltsmodal"
                                              data-toggle="modal"
                                              onClick={() =>
                                                handleEditSalts(
                                                  key.id,
                                                  key.medicine_id
                                                )
                                              }
                                            >
                                              {" "}
                                              <Icon icon="edit2" />
                                            </a>
                                          );
                                        }
                                      })()}
                                      <span className="ml-3">
                                        {3 < getPermissions("medicines") ? (
                                          <a
                                            href
                                            className="text-danger cp"
                                            onClick={() =>
                                              handleDeleteFormula(key.id)
                                            }
                                          >
                                            <Icon icon="trash" />{" "}
                                          </a>
                                        ) : (
                                          <span></span>
                                        )}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12 mb-0 mt-3">
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn-user btn-sm btn btn-primary "
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="editsaltsmodal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <Row>
              <Col lg={12} md={12} sm={12}>
                <div className="card shadow">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Edit Medicine Formula
                    </h6>
                    <button
                      type="button"
                      className={`close`}
                      onClick={(e) => editsaltsmodal(med_formula_id)}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="p-3">
                      <form className="user" onSubmit={updateFormulasfun}>
                        <div className="row">
                          <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                            <label htmlFor="name">Name</label>
                            <span
                              className="form-control-user"
                              style={{ textTransform: "capitalize" }}
                            >
                              {" "}
                              {medicine_name.replace(/_/g, " ")}
                            </span>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                            <label htmlFor="stocktype">Medicine Type</label>
                            <span
                              className="form-control-user"
                              style={{ textTransform: "capitalize" }}
                            >
                              {" "}
                              {entity_type_id.replace(/_/g, " ")}
                            </span>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                            <label htmlFor="stocktype">Brands</label>
                            <span
                              className="form-control-user"
                              style={{ textTransform: "capitalize" }}
                            >
                              {" "}
                              {brand_id.replace(/_/g, " ")}
                            </span>
                          </div>
                          {(() => {
                            if (
                              entity_type_id === "syrup" ||
                              entity_type_id === "syrups"
                            ) {
                              return "";
                            } else {
                              return (
                                <>
                                  <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                                    <label htmlFor="units_per_strip">
                                      Price per unit
                                    </label>
                                    <span className="form-control-user">
                                      {price_per_unit}
                                    </span>
                                  </div>
                                  <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                                    <label htmlFor="units_per_strip">
                                      Price per strip
                                    </label>
                                    <span className="form-control-user">
                                      {price_per_strip}
                                    </span>
                                  </div>
                                  <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                                    <label htmlFor="units_per_strip">
                                      Units per strip
                                    </label>
                                    <input
                                      name="units_per_strip"
                                      value={units_per_strip}
                                      onFocus={fldChange}
                                      onChange={(e) =>
                                        setUnits_per_strip(e.target.value)
                                      }
                                      type="number"
                                      className={
                                        "form-control-user form-control"
                                      }
                                    />
                                  </div>
                                  <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                                    <label htmlFor="strips_per_packs">
                                      Strip per pack
                                    </label>
                                    <input
                                      value={strips_per_packs}
                                      name="strips_per_packs"
                                      onFocus={fldChange}
                                      onChange={(e) =>
                                        setStrips_per_packs(e.target.value)
                                      }
                                      type="number"
                                      min="0"
                                      className={
                                        "form-control-user form-control"
                                      }
                                    />
                                  </div>
                                </>
                              );
                            }
                          })()}
                          <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                            <label htmlFor="price_per_pack">
                              Buying Price (Rs)
                            </label>
                            <input
                              name="price_per_pack"
                              onFocus={fldChange}
                              value={price_per_pack}
                              onChange={(e) =>
                                setPrice_per_pack(e.target.value)
                              }
                              type="number"
                              className={"form-control-user form-control"}
                            />
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                            <label htmlFor="selling_price_per_pack">
                              Selling Price (Rs)
                            </label>
                            <input
                              name="selling_price_per_pack"
                              value={selling_price_per_pack}
                              onChange={(e) =>
                                setSelling_price_per_pack(e.target.value)
                              }
                              type="number"
                              className={"form-control-user form-control"}
                            />
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                            <label htmlFor="stocktype">Unit Type</label>
                            <select
                              onChange={onchangeunits}
                              name="unit_id"
                              onFocus={fldChange}
                              value={unit_id}
                              className={"form-control-user form-control"}
                            >
                              <option defaultValue={entity_typeid}>
                                Select Unit Type
                              </option>
                              {unit_id_list.map((stock, i) => (
                                <option key={i} value={stock.id}>
                                  {stock.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group col-lg-2 col-md-3 col-sm-6 col-6">
                            <label className="text-white">B</label>
                            <button
                              data-target="#updatemodal"
                              className="btn-user btn-block btn btn-secondary"
                              data-toggle="modal"
                              type="button"
                            >
                              + Salt
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mt-2 mb-0 col-lg-4 col-md-6 col-sm-12 col-12">
                            <button
                              type="submit"
                              disabled={loading}
                              className="btn-user btn-block btn btn-primary mb-2"
                            >
                              Update
                            </button>
                            {loading ? (
                              <Spinner
                                animation="border"
                                variant="primary"
                                className="mt-3"
                              />
                            ) : (
                              <span></span>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="modal fade" id="updatemodal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <form className="user">
              <div className="row ">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="card shadow mb-0">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Edit Salts
                      </h6>
                      <button
                        type="button"
                        className="close"
                        onClick={updatemodal}
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="card-body">
                      <div className="p-4">
                        {inputFields.map((inputField, index) => (
                          <Fragment key={`${inputField}~${index}`}>
                            <div className="form-row">
                              <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                                <label htmlFor="saltid">Salt name</label>
                                <select
                                  name="salt_id"
                                  id="salt_id"
                                  value={inputField.salt_id}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                  className={"form-control-user form-control"}
                                >
                                  <option defaultValue={entity_typeid}>
                                    Select Salt
                                  </option>
                                  {salt_id_list.map((stock, i) => (
                                    <option key={i} value={stock.id}>
                                      {" "}
                                      {stock.name}{" "}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                                <label htmlFor="dose_id"> Dose name </label>
                                <select
                                  name="dose_id"
                                  id="dose_id"
                                  value={inputField.dose_id}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                  className={"form-control-user form-control"}
                                >
                                  <option defaultValue={entity_typeid}>
                                    Select Dose
                                  </option>
                                  {dose_id_list.map((stock, i) => (
                                    <option key={i} value={stock.id}>
                                      {stock.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                                <label htmlFor="quantity">Quantity</label>
                                <input
                                  type="text"
                                  className="form-control-user form-control"
                                  id="quantity"
                                  name="quantity"
                                  value={inputField.quantity}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                />
                              </div>
                              {(() => {
                                if (
                                  inputField.id == null ||
                                  inputField.id == ""
                                ) {
                                  return (
                                    <button
                                      className="btn btn-link text-danger p-0 bxd a"
                                      type="button"
                                      onClick={() => handleRemoveFields(index)}
                                    >
                                      <i className="rs-icon rs-icon-trash"></i>
                                    </button>
                                  );
                                } else {
                                  return (
                                    <button
                                      className="btn btn-link text-danger p-0 bxd b"
                                      type="button"
                                      onClick={() =>
                                        handleRemoveFun(inputField.id)
                                      }
                                    >
                                      <i className="rs-icon rs-icon-trash"></i>
                                    </button>
                                  );
                                }
                              })()}
                            </div>
                          </Fragment>
                        ))}
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end text-right">
                            <button
                              className="btn btn-link p-0 bxd"
                              type="button"
                              onClick={() => handleAddFields()}
                            >
                              <i className="fas fa-plus-circle"></i>
                            </button>
                          </div>
                          <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12">
                            <button
                              type="button"
                              onClick={updatemodal}
                              className="btn-user btn-block btn btn-primary "
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/*Add Medicine Formula Popup*/}
      <div className="modal fade" id="addformulamodal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card shadow">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Add Formula
                    </h6>
                    <button
                      type="button"
                      className="close"
                      onClick={Openformulamodal}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="p-4">
                      <form className="user" onSubmit={addnewformulafun}>
                        <div className="row">
                          <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                            <label htmlFor="name">Name</label>
                            <span
                              className="form-control-user"
                              style={{ textTransform: "capitalize" }}
                            >
                              {medicine_name.replace(/_/g, " ")}
                            </span>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                            <label htmlFor="stocktype">Medicine Type</label>

                            <span
                              className="form-control-user"
                              style={{ textTransform: "capitalize" }}
                            >
                              {entity_type_id}
                            </span>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                            <label htmlFor="stocktype">Brand</label>
                            <span
                              className="form-control-user"
                              style={{ textTransform: "capitalize" }}
                            >
                              {brand_id.replace(/_/g, " ")}
                            </span>
                          </div>
                          {(() => {
                            if (
                              entity_type_id === "syrup" ||
                              entity_type_id === "syrups"
                            ) {
                              return "";
                            } else {
                              return (
                                <>
                                  <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                                    <label htmlFor="units_per_strip">
                                      Units per strip
                                    </label>
                                    <input
                                      onFocus={fldChange}
                                      name="units_per_stripe"
                                      onChange={(e) =>
                                        setUnits_per_stripe(e.target.value)
                                      }
                                      type="text"
                                      className={
                                        "form-control-user form-control"
                                      }
                                    />
                                  </div>
                                  <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                                    <label htmlFor="strips_per_pack">
                                      Strip per pack
                                    </label>
                                    <input
                                      onFocus={fldChange}
                                      name="strips_per_pack"
                                      onChange={(e) =>
                                        setStrips_per_packe(e.target.value)
                                      }
                                      type="text"
                                      className={
                                        "form-control-user form-control"
                                      }
                                    />
                                  </div>
                                </>
                              );
                            }
                          })()}
                          <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                            <label htmlFor="price_per_pack">
                              Buying Price (Rs)
                            </label>
                            <input
                              name="price_per_packe"
                              onChange={(e) =>
                                setPrice_per_packe(e.target.value)
                              }
                              type="text"
                              className={"form-control-user form-control"}
                            />
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                            <label htmlFor="selling_price_per_pack">
                              Selling Price (Rs)
                            </label>
                            <input
                              name="selling_price_per_pack"
                              onChange={(e) =>
                                setSelling_price_per_pack(e.target.value)
                              }
                              type="text"
                              className={"form-control-user form-control"}
                            />
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                            <label htmlFor="stocktype">Unit Type</label>
                            <select
                              onFocus={fldChange}
                              onChange={(e) => setUnits_id(e.target.value)}
                              name="units_id"
                              className={"form-control-user form-control"}
                            >
                              <option defaultValue={entity_typeid}>
                                Select Unit Type
                              </option>
                              {unit_id_list.map((stock, i) => (
                                <option key={i} value={stock.id}>
                                  {stock.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group col-lg-2 col-md-3 col-sm-6 col-6">
                            <label className="text-white">Brands</label>
                            <a
                              href="#updatesmodal"
                              className="btn-user btn-block btn btn-secondary"
                              data-toggle="modal"
                              type="button"
                            >
                              + Salt
                            </a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mt-3 mb-0 col-lg-4 col-md-6 col-sm-12 col-12">
                            <button
                              type="submit"
                              disabled={loading}
                              className="btn-user btn-block btn btn-primary mb-2"
                            >
                              Submit
                            </button>
                            {loading ? (
                              <Spinner
                                animation="border"
                                variant="primary"
                                className="mt-3"
                              />
                            ) : (
                              <span></span>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Salts Add Modal */}
      <div className="modal fade" id="updatesmodal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card shadow mb-0">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Add Salts
                    </h6>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="p-4">
                      {inputField.map((inputField, index) => (
                        <Fragment key={`${inputField}~${index}`}>
                          <form className="user">
                            <div className="form-row">
                              <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                                <label htmlFor="saltid">Salt name</label>
                                <select
                                  onFocus={fldChange}
                                  name="salt_id"
                                  id="salt_id"
                                  value={inputField.salt_id}
                                  onChange={(event) =>
                                    handleInputChang(index, event)
                                  }
                                  className={"form-control-user form-control"}
                                >
                                  <option defaultValue={entity_typeid}>
                                    Select Salt
                                  </option>
                                  {salt_id_list.map((stock, i) => (
                                    <option key={i} value={stock.id}>
                                      {stock.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                                <label htmlFor="dose_id">Dose name</label>
                                <select
                                  onFocus={fldChange}
                                  name="dose_id"
                                  id="dose_id"
                                  value={inputField.dose_id}
                                  onChange={(event) =>
                                    handleInputChang(index, event)
                                  }
                                  className={"form-control-user form-control"}
                                >
                                  <option defaultValue={entity_typeid}>
                                    Select Dose
                                  </option>
                                  {dose_id_list.map((stock, i) => (
                                    <option key={i} value={stock.id}>
                                      {stock.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12">
                                <label htmlFor="quantity">Quantity</label>
                                <input
                                  type="text"
                                  onFocus={fldChange}
                                  className="form-control-user form-control"
                                  id="quantity"
                                  name="quantity"
                                  value={inputField.quantity}
                                  onChange={(event) =>
                                    handleInputChang(index, event)
                                  }
                                />
                              </div>
                              <button
                                className="btn btn-link text-danger p-0 bxd"
                                type="button"
                                onClick={() => handleRemoveField(index)}
                              >
                                <i className="rs-icon rs-icon-trash"></i>
                              </button>
                            </div>
                          </form>
                        </Fragment>
                      ))}

                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end text-right">
                          <button
                            className="btn btn-link p-0 bxd"
                            type="button"
                            onClick={() => handleAddField()}
                          >
                            <i className="fas fa-plus-circle"></i>
                          </button>
                        </div>
                        <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12">
                          <button
                            type="button"
                            className="btn-user btn btn-primary"
                            onClick={addSaltsfun}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditMedicine;
