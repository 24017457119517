import { post } from "../utils/http.util";

const resource = "discounts";

// Get all discounts
export const getAllDiscounts = (offset, status_id = "active") => {
  return post(
    "/discounts",
    { resource, method: "GET", offset, status_id },
    "POST"
  );
};

// Get one discount
export const getOneDiscount = (id) => {
  return post("/discount", { id, resource, method: "GET" }, "POST");
};

// Add a new discount
export const addOneDiscount = (data, status_id = "active") => {
  return post(
    "/discount",
    { ...data, resource, method: "POST", status_id },
    "POST"
  );
};

// Update a single discount
export const updateOneDiscount = (id, data) => {
  return post("/discount", { ...data, id, resource, method: "UPDATE" }, "PUT");
};

// Delete one discount
export const deleteOneDiscount = (id) => {
  return post("/discount", { id, resource, method: "DELETE" }, "DELETE");
};

export const searchDiscounts = (search, offset, status_id = "active") => {
  return post(
    "/discounts",
    { offset, search, resource, method: "POST", status_id },
    "POST"
  );
};
