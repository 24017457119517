const Notifications = {
  successMsg: "Success!",
  errorMsg: "Error!",
  errorStock: "Error! Stock Shortage",
  notlogin: "Unable to login, please check.",
  loginsuccess: "Login successful.",
  regsuccess: "Registeration successful.",
  regerror: "Registration failed",
  addedsuccess: "Data added successfully.",
  updatedsuccess: "Data updated successfully.",
  notupdatedsuccess: "Updation failed.",
  notaddfailed: "No data added, please check.",
  notadfailed: "Please fill all fields.",
  notaligible: "You are Customer.",
  deletedsuccess: "Deleted successfully.",
  notdeletedsuccess: "Deletion failed.",
  invalidcred: "Invalid credentials.",
  resetpass: "Reset password successful.",
  notpasswordsame: "Password do not match.",
  useraddedsuccess: "User added successfully.",
  usernotaddedsuccess: "User added successfully.",
  userdeletedsuccess: "User deleted successfully.",
  userupdatedsuccess: "User updated successfully.",
  employeeaddedsuccess: "Employee added successfully.",
  employeedeletedsuccess: "Employee deleted successfully.",
  employeeupdatedsuccess: "Employee updated successfully.",
  activesuccess: "Medicine activation successful.",
  failedrecheck: "Failed, please check!",
};
export default Notifications;
