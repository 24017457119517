import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import "rsuite/dist/styles/rsuite-default.css";
import { Icon } from "rsuite";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import SweetAlert from "react-bootstrap-sweetalert";

import Multiselect from "multiselect-react-dropdown";
import Notifications from "../../utils/notifications";
import { getPermissions } from "../../utils/permissions.util.js";
import BASE_URL from "../../utils/api";
import Layout from "../layouts/index";
import { date } from "../../utils/date.util";
import { getToken } from "../../utils/session.util";
import { addOne, getAll } from "../../services/users.service";

// Configurations
const theme = createTheme();
toast.configure({ autoClose: 8000, draggable: false });

const ActiveUsers = () => {
  const multiselectRef = useRef(null);
  const [userslist, setUserslist] = useState([]);
  const [loading, setloading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [alength, setalength] = useState();
  const [roles, setroles] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  // User Register
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const [rolelist, setRolelist] = useState([]);
  const [ids, setIds] = useState("");
  const [userid, setUserid] = useState("");
  const [datastatus, setDatastatus] = useState({
    confirmBox: false,
    id: "",
    name: "",
  });

  // Hooks
  useEffect(() => {
    fetchUsers(offset);
    fetchRoles();
  }, [offset]);

  const fetchRoles = async () => {
    await fetch(BASE_URL + "/roles", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "roles",
        method: "GET",
        status_id: "active",
      }),
    })
      .then((res) => res.json())
      .then(
        (resp) => {
          var array = resp.data.filter(function (item) {
            return item.id !== "owner";
          });
          let options = [];
          for (const [key, label] of Object.entries(array)) {
            options.push({ id: label.id, name: label.name });
          }
          setroles(options);
        },
        (error) => {}
      );
  };

  const fetchUsers = async (offset) => {
    setloading(true);
    const data = await getAll(offset);
    setloading(false);
    setUserslist(data.data);
    setalength(data.total);
  };

  function handleDelete() {
    if (datastatus.confirmBox === true) {
      setDatastatus({ confirmBox: false, id: "", name: "" });
      setloading(true);
      fetch(BASE_URL + "/user", {
        method: "DELETE",
        headers: {
          Origin: "*",
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          user_id: `${datastatus.id}`,
          resource: "employees",
          method: "DELETE",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (Object.prototype.hasOwnProperty.call(result, "error")) {
            toast.error(`${result["error"]["message"]}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            fetchUsers(offset);
            setDatastatus({ id: "", name: "" });
            toast.success(`${Notifications.deletedsuccess}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    }
  }

  const handleClick = (offset) => {
    setOffset(offset);
    fetchUsers(offset);
  };

  const onSelectOption = (selectedList, selectedItem) => {
    setSelectedOptions(selectedList);
    selectedList.forEach(function (obj) {
      delete obj.id;
    });
    let option = [];
    for (var i = 0; i < selectedList.length; i++) {
      option.push(selectedList[i].name.toLowerCase());
    }
    setRolelist(option);
  };

  const onRemoveOption = (selectedList, selectedItem) => {
    setSelectedOptions(selectedList);
    selectedList.forEach(function (obj) {
      delete obj.id;
    });
    let option = [];
    for (var i = 0; i < selectedList.length; i++) {
      option.push(selectedList[i].name.toLowerCase());
    }
    setRolelist(option);
  };

  // Reset Dropdown Multiselect function
  const resetValues = () => multiselectRef.current.resetSelectedValues();

  const resetForm = () => {
    multiselectRef.current.resetSelectedValues();
    setUserid("");
    setName("");
    setEmail("");
    setGender("");
  };

  // Register User Function
  const RegisterUserFunction = async (e) => {
    setloading(true);
    const data = await addOne(name, email, password, gender, rolelist);
    setloading(false);
    toast.success(`${Notifications.regsuccess}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    fetchUsers(offset);
    setIds("");
  };

  const getUserById = async (id) => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    await fetch(BASE_URL + "/user", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "users",
        method: "GET",
        id: id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);

          setUserid(result.id);
          setName(result.name);
          setEmail(result.email);
          setGender(result.gender);

          let option = [];
          let options = [];
          result.roles.map((key, i) => {
            option.push({ id: key.id, name: key.name });
            options.push(key.name);
          });

          setSelectedOptions(option);
          setRolelist(options);
        },
        (error) => {}
      );
  };

  // User Update Function
  const UserUpdateFun = async (e) => {
    setloading(true);
    await fetch(BASE_URL + `/user`, {
      method: "PUT",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        id: userid,
        resource: "users",
        method: "UPDATE",
        name: name,
        login: email,
        password: password,
        gender: gender,
        roles: rolelist,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setloading(false);
        if (Object.prototype.hasOwnProperty.call(result, "error")) {
          toast.error(`${result["error"]["message"]}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setloading(false);
          toast.success(`${Notifications.updatedsuccess}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const UpdateRegisterUserFunc = (e) => {
    e.preventDefault();
    if (ids === "update") {
      UserUpdateFun();
    } else {
      UserUpdateFun();
      e.target.reset();
      resetValues();
    }
  };

  const RegissterUserFunc = (e) => {
    e.preventDefault();
    if (ids === "yes") {
      RegisterUserFunction();
    } else {
      RegisterUserFunction();
      e.target.reset();
      resetValues();
    }
  };

  const cancelActivation = () => {
    setDatastatus({ confirmBox: false, id: "", name: "" });
  };

  return (
    <Layout>
      {1 < getPermissions("users") ? (
        <div className="accordionformblk mb-3">
          <form
            className="user"
            autoComplete="off"
            onSubmit={
              userid !== "" ? UpdateRegisterUserFunc : RegissterUserFunc
            }
          >
            <div id="accordion1">
              <div className="card">
                <div className="card-header">
                  <a
                    className="collapsed card-link d-block w-100 text-dark font-weight-600"
                    aria-expanded="true"
                    data-toggle="collapse"
                    href="#collapseTwo3"
                  >
                    <h5 className="m-0 font-weight-bold text-primary">
                      {userid !== "" ? "Edit" : "Add"} User
                    </h5>
                  </a>
                </div>
                <div
                  id="collapseTwo3"
                  className="collapse show"
                  data-parent="#accordion1"
                >
                  <div className="card-body px-2 pt-2 pb-0">
                    <div id="accordion">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="row">
                            <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                              <input
                                name="name"
                                value={name}
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                id="name"
                                placeholder="Enter full name"
                                required="true"
                                className={"form-control-user form-control"}
                              />

                              <label htmlFor="name">Name</label>
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                              <input
                                name="login"
                                value={email}
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setEmail(e.target.value)}
                                id="login"
                                placeholder="Enter email or phone"
                                required="true"
                                className={
                                  "form-control-user form-control auto-complete-off"
                                }
                              />
                              <label htmlFor="name">Email/Phone</label>
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                              <input
                                name="password"
                                value={password}
                                autoComplete="off"
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                id="password"
                                placeholder="Enter password"
                                required="true"
                                className={"form-control-user form-control"}
                              />
                              <label htmlFor="name">Password</label>
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                              <select
                                name="gender"
                                value={gender}
                                id="gender"
                                className="form-control-user form-control"
                                onChange={(e) => setGender(e.target.value)}
                              >
                                <option defaultValue="0">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                              <label htmlFor="name">Gender</label>
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                              <Multiselect
                                options={roles}
                                selectedValues={selectedOptions}
                                showCheckbox={true}
                                placeholder="Select Roles"
                                onSelect={onSelectOption}
                                onRemove={onRemoveOption}
                                ref={multiselectRef}
                                showArrow="true"
                                displayValue="name"
                              />
                              <label htmlFor="name">Role</label>
                            </div>
                          </div>
                          <div className="form-group mt-0">
                            {(() => {
                              if (userid !== "") {
                                return (
                                  <>
                                    <button
                                      type="submit"
                                      onClick={() => setIds("update")}
                                      className="btn btn-success btn-sm"
                                    >
                                      {" "}
                                      Update
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-sm mx-2"
                                    >
                                      {" "}
                                      Update & Reset
                                    </button>
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <button
                                      type="submit"
                                      onClick={() => setIds("yes")}
                                      className="btn btn-success btn-sm"
                                    >
                                      {" "}
                                      Save
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-sm mx-2"
                                    >
                                      {" "}
                                      Save & Reset
                                    </button>
                                  </>
                                );
                              }
                            })()}
                            <button
                              type="button"
                              onClick={resetForm}
                              className="btn btn-secondary btn-sm"
                            >
                              {" "}
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <sapn></sapn>
      )}
      <div className="card shadow mb-4">
        <div className="card-header">
          <h5 className="m-0 font-weight-bold text-primary">Users List</h5>
        </div>
        <div className="card-body p-1">
          {(() => {
            return (
              <>
                <Table
                  wordWrap
                  data={userslist}
                  minHeight={300}
                  autoHeight={true}
                  loading={loading}
                  headerHeight={37}
                >
                  <Column minWidth={80} verticalAlign="middle">
                    <HeaderCell>ID</HeaderCell>
                    <Cell dataKey="id" />
                  </Column>
                  <Column flexGrow={2} verticalAlign="middle">
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name" />
                  </Column>
                  <Column minWidth={200} flexGrow={2} verticalAlign="middle">
                    <HeaderCell>Email/Phone</HeaderCell>
                    <Cell>
                      {(rowData) => {
                        return (
                          <span>{rowData.email || rowData.phone_number}</span>
                        );
                      }}
                    </Cell>
                  </Column>
                  <Column flexGrow={2} verticalAlign="middle">
                    <HeaderCell>Role</HeaderCell>
                    <Cell>
                      {(rowData) => {
                        return (
                          <div className="rolelist">
                            {(() => {
                              if (0 < getPermissions("users")) {
                                return rowData.roles.map((key, i) => {
                                  return (
                                    <span
                                      className={`badge badge-primary a${i}`}
                                      key={i}
                                    >
                                      {key.id}
                                    </span>
                                  );
                                });
                              }
                            })()}
                          </div>
                        );
                      }}
                    </Cell>
                  </Column>
                  <Column verticalAlign="middle">
                    <HeaderCell>Gender</HeaderCell>
                    <Cell dataKey="gender" />
                  </Column>
                  <Column minWidth={200} flexGrow={2} verticalAlign="middle">
                    <HeaderCell>Date Added</HeaderCell>
                    <Cell>{(data) => date(data["inserted_at"], "medium")}</Cell>
                  </Column>
                  <Column width={120} fixed="right" verticalAlign="middle">
                    <HeaderCell>Actions</HeaderCell>
                    <Cell>
                      {(rowData) => {
                        return (
                          <>
                            {(() => {
                              if (2 < getPermissions("users")) {
                                return (
                                  <a
                                    href
                                    onClick={() => getUserById(rowData.id)}
                                    className="cp mr-3 text-primary"
                                    data-toggle="tooltip"
                                    title="Edit"
                                  >
                                    <i className="fas fa-pencil-alt"></i>
                                  </a>
                                );
                              }
                            })()}
                            <span>
                              {3 < getPermissions("users") ? (
                                <a
                                  href
                                  className="cp"
                                  title="Inactivate"
                                  data-toggle="tooltip"
                                  onClick={() =>
                                    setDatastatus({
                                      confirmBox: true,
                                      id: rowData.id,
                                      name: rowData.name,
                                    })
                                  }
                                >
                                  <Icon icon="trash" />{" "}
                                </a>
                              ) : (
                                <span></span>
                              )}
                            </span>
                          </>
                        );
                      }}
                    </Cell>
                  </Column>
                </Table>
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <Pagination
                    limit={10}
                    offset={offset}
                    total={alength}
                    onClick={(e, offset) => handleClick(offset)}
                  />
                </MuiThemeProvider>
              </>
            );
          })()}
        </div>
      </div>

      <SweetAlert
        show={datastatus.confirmBox}
        showCancel
        confirmBtnText="Inactivate"
        confirmBtnBsStyle="error"
        title="Are you sure!"
        onConfirm={handleDelete}
        onCancel={cancelActivation}
        focusCancelBtn
        closeAnim={{ name: "hideSweetAlert", duration: 500 }}
      >
        You want to inactivate {datastatus.name}?
      </SweetAlert>
    </Layout>
  );
};

export default ActiveUsers;
