import { post } from "../utils/http.util";

const resource = "sales";

// Get all sales
export const getAll = (offset, status = "completed") => {
  return post(
    "/medicine-sales",
    { offset, resource, method: "GET", status },
    "POST"
  );
};

// Get one sale
export const getOne = (id, status_id = "completed") => {
  return post(
    "/medicine-sales",
    { id, resource, method: "GET", status_id },
    "POST"
  );
};

// Add a medicine sale
export const addOne = (sale, user_id, status_id = "completed") => {
  return post(
    "/medicine-sale",
    { method: "POST", resource, sale, user_id, status_id },
    "POST"
  );
};

// Get completed sale
export const deleteOne = async (id) => {
  return post("/medicine-sale", { resource, method: "DELETE", id }, "DELETE");
};
