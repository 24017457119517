import { post } from "../utils/http.util";

const resource = "doses";

// Get all doses
export const getAll = (offset, status_id = "active") => {
  return post("/doses", { resource, method: "GET", offset, status_id }, "POST");
};

// Get one dose
export const getOne = (id, status_id = "active") => {
  return post("/dose", { id, resource, method: "GET", status_id }, "POST");
};

// Add a new dose
export const addOne = (dose_id, name) => {
  return post("/dose", { dose_id, name, resource, method: "POST" }, "POST");
};

// Update a single dose
export const updateOne = (id, name, short_name, status_id = "active") => {
  return post(
    "/dose",
    { id, resource, short_name, name, method: "UPDATE", status_id },
    "PUT"
  );
};

// Delete one dose
export const deleteOne = (id) => {
  return post(
    "/dose",
    { id, resource, method: "DELETE", status_id: "inactive" },
    "DELETE"
  );
};

export const searchDoses = (search, offset, status_id = "active") => {
  return post(
    "/doses",
    { offset, search, resource, method: "POST", status_id },
    "POST"
  );
};
