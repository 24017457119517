export const MedicineDetails = (
  prloading,
  Spinner,
  inputFieldss,
  mdname,
  mddiseases,
  mdside_effects,
  mdshelf,
  entity_type_ids,
  brand_id,
  inputFormulass
) => {
  return (
    <div className="modal fade" id="getmedicineslistpopup">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="card shadow mb-0">
                <div className="card-header">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Medicine Details
                  </h6>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="card-body p-0 prw">
                  <div className="px-3 py-3">
                    <div className="medlists">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          {prloading ? (
                            <div className="prloader">
                              <Spinner animation="border" variant="primary" />
                            </div>
                          ) : (
                            <div className="accordionformblk mb-0">
                              <div className="user">
                                <div className="card">
                                  <div className="card-header">
                                    <a
                                      className="card-link d-block w-100 text-dark font-weight-600"
                                      href
                                    >
                                      {" "}
                                      Medicine Information
                                    </a>
                                  </div>
                                  <div id="collapseTwo3">
                                    <div className="card-body px-2 pt-2 pb-0 ha overflow-hidden">
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 pb-2">
                                          <div className="row">
                                            <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                              <input
                                                value={mdname}
                                                type="text"
                                                readOnly
                                                className="form-control-user form-control"
                                              />
                                              <label htmlFor="medicine_name">
                                                Medicine Name
                                              </label>
                                            </div>
                                            <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                              <input
                                                value={mddiseases}
                                                type="text"
                                                readOnly
                                                className="form-control-user form-control"
                                              />
                                              <label htmlFor="diseases">
                                                Medicine Diseases
                                              </label>
                                            </div>
                                            <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                              <input
                                                value={mdside_effects}
                                                type="text"
                                                readOnly
                                                className="form-control-user form-control"
                                              />
                                              <label htmlFor="side_effects">
                                                Medicine Side Effects
                                              </label>
                                            </div>
                                            <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                              <input
                                                value={mdshelf}
                                                type="text"
                                                readOnly
                                                className="form-control-user form-control"
                                              />
                                              <label htmlFor="medicine_shelf">
                                                Medicine Shelf
                                              </label>
                                            </div>
                                            <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                              <input
                                                readOnly
                                                type="text"
                                                className="form-control-user form-control"
                                                value={entity_type_ids}
                                              />
                                              <label htmlFor="type_id">
                                                Medicine Type
                                              </label>
                                            </div>
                                            <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                              <input
                                                readOnly
                                                type="text"
                                                className="form-control-user form-control"
                                                value={brand_id}
                                              />
                                              <label htmlFor="brand_name">
                                                Brands
                                              </label>
                                            </div>
                                          </div>
                                          <div className="card">
                                            <div className="card-header">
                                              <a
                                                className="card-link d-block w-100 text-dark"
                                                href
                                              >
                                                Doses Information
                                              </a>
                                            </div>
                                            <div id="collapseTwo1">
                                              <div
                                                className="card-body  p-0 ha"
                                                id="child1"
                                              >
                                                <div className="px-3 pt-1 pb-3">
                                                  <div>
                                                    {inputFormulass.map(
                                                      (k, i) => (
                                                        <div
                                                          className="row formula_sec"
                                                          key={i}
                                                        >
                                                          {(() => {
                                                            if (
                                                              entity_type_ids ===
                                                                "syrup" ||
                                                              entity_type_ids ===
                                                                "syrups"
                                                            ) {
                                                              return "";
                                                            } else {
                                                              return (
                                                                <>
                                                                  <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                    <input
                                                                      readOnly
                                                                      type="text"
                                                                      className="form-control-user form-control"
                                                                      value={
                                                                        k.strips_per_pack
                                                                      }
                                                                    />
                                                                    <label htmlFor="price_per_pack">
                                                                      Strips Per
                                                                      Pack{" "}
                                                                    </label>
                                                                  </div>
                                                                  <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                    <input
                                                                      readOnly
                                                                      type="text"
                                                                      className="form-control-user form-control"
                                                                      value={
                                                                        k.units_per_strip
                                                                      }
                                                                    />
                                                                    <label htmlFor="price_per_pack">
                                                                      Units Per
                                                                      Strip
                                                                    </label>
                                                                  </div>
                                                                </>
                                                              );
                                                            }
                                                          })()}
                                                          <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                            <input
                                                              readOnly
                                                              type="text"
                                                              className="form-control-user form-control"
                                                              value={k.dose_id}
                                                            />
                                                            <label htmlFor="price_per_pack">
                                                              Dose Name
                                                            </label>
                                                          </div>
                                                          <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                            <input
                                                              readOnly
                                                              type="text"
                                                              className="form-control-user form-control"
                                                              value={k.quantity}
                                                            />
                                                            <label htmlFor="price_per_pack">
                                                              Dose Quantity
                                                            </label>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card mt-2">
                                            <div className="card-header">
                                              <a
                                                className=" card-link d-block w-100 text-dark font-weight-600"
                                                href
                                              >
                                                Salts Information
                                              </a>
                                            </div>
                                            <div className="card-body p-0 ha">
                                              <div className="px-3 pt-1 pb-3">
                                                <div>
                                                  {inputFieldss.map((k, i) => (
                                                    <div
                                                      className="row formula_sec"
                                                      key={i}
                                                    >
                                                      <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                                        {(() => {
                                                          let ent =
                                                            k.salt_id.replace(
                                                              /_/g,
                                                              " "
                                                            );
                                                          return (
                                                            <input
                                                              readOnly
                                                              type="text"
                                                              className="form-control-user form-control text-capitalize"
                                                              value={ent}
                                                            />
                                                          );
                                                        })()}
                                                        <label htmlFor="saltid">
                                                          Salt name
                                                        </label>
                                                      </div>
                                                      <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                                        <input
                                                          readOnly
                                                          type="text"
                                                          className="form-control-user form-control"
                                                          value={k.dose_id}
                                                        />
                                                        <label htmlFor="dose_id">
                                                          Dose name
                                                        </label>
                                                      </div>
                                                      <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                                        <input
                                                          readOnly
                                                          type="text"
                                                          className="form-control-user form-control"
                                                          value={k.quantity}
                                                        />
                                                        <label htmlFor="quantity">
                                                          Quantity
                                                        </label>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
