import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import "rsuite/dist/styles/rsuite-default.css";
import $ from "jquery";

import Layout from "../layouts/index";
import { SalesForm, SalesTable } from "./components";
import { post } from "../../utils/api";
import { getToken } from "../../utils/session.util";
import { getStock } from "../../services/stock.service";
import { getMedicine } from "../../services/medicines.service";
import Notifications from "../../utils/notifications";
import { getOne, addOne } from "../../services/sales.service";
import { date } from "../../utils/date.util";
import { getAllSettings, getCurrency } from "../../services/settings.service";

// Configurations
const theme = createTheme();
toast.configure({ autoClose: 8000, draggable: false });

const defaultSalesParams = {
  unit_id: "",
  medicine_id: "",
  resource: "medicine_sales",
  quantity: 0,
  price: "",
  stock_id: "",
  hasError: false,
  unit_idError: "",
  quantityError: "",
  priceError: "",
};

const Sales = (props) => {
  const history = useHistory();
  const isActive = props["location"]["pathname"] === "/sales";
  const [session, setSession] = useState({ id: "" });
  const [salesParams, setSalesParams] = useState([{ ...defaultSalesParams }]);
  const [salesList, setSalesList] = useState({ data: [], total: 0 });
  const [stocksList, setStocksList] = useState({ data: [], total: 0 });
  const [unitsList, setUnitsList] = useState({ data: [], total: 0 });
  const [medicinesList, setMedicinesList] = useState({ data: [], total: 0 });
  const [medicineFormulaList, setMedicineFormulaList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [action, setAction] = useState("");
  const [reFetch, setReFetch] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [printableSale, setSale] = useState(null);
  const [draftSaleId, setDraftSaleId] = useState(null);
  const [returnSaleId, setReturnSaleId] = useState(null);
  const [slipParams, setSlipParams] = useState(null);
  const [currency, setCurrency] = useState("");

  const showSalePopup = async (id) => {
    const settings = await getAllSettings();
    const data = await getOne(id);
    const sale = data[0];

    // Values
    const currency = settings.currency || null;
    const discount = sale.total_sale_amount * (settings.discount / 100);
    const tax =
      settings.tax_value === "0"
        ? 0
        : (sale.total_sale_amount - discount) *
          (parseFloat(settings.tax_value) / 100);

    sale.sub_total = sale.total_sale_amount + ` ${currency}`;
    sale.discount = discount.toFixed(2) + ` ${currency}`;
    sale.tax = tax.toFixed(2) + ` ${currency}`;
    sale.total =
      (sale.total_sale_amount - discount + tax).toFixed(2) + ` ${currency}`;

    setSlipParams(settings);
    setSale(sale);
    $("#sale-model").modal("show");
  };

  const printSale = () => {
    console.log("Print functionality");
  };

  const createMedicine = (value) => {
    history.push(`/active-medicines?create=true&name=${value}`);
    history.go(`/sales?return?create=true&name=${value}`);
  };

  useEffect(() => {
    post(
      "/medicines",
      {
        resource: "medicines",
        status_id: "active",
        method: "GET",
        search: search,
        offset: offset,
      },
      (data) => {
        let medicines = { data: [], total: data.total };
        data.data.forEach((medicine) => {
          const salts =
            medicine.salts.length > 0
              ? medicine.salts.map((x) => x.salt.name)
              : null;
          const mSalts = salts ? ` - ${salts.join(", ")}` : "";
          medicine.medicine_formulas.forEach((x) => {
            medicines.data.push({
              ...medicine,
              dose: `${x.quantity} ${x.dose_id.toUpperCase()}`,
              name: `${medicine.name} - ${
                medicine.entity_type_id.charAt(0).toUpperCase() +
                medicine.entity_type_id.slice(1)
              } ${x.quantity} ${x.dose_id.toUpperCase()}${mSalts}`,
              medicine_formulas: [x],
            });
          });
        });
        setMedicinesList(medicines);
      },
      false
    ).then();
  }, [search]);

  useEffect(() => {
    post(
      "/user",
      {
        token: getToken(),
        resource: "users",
        method: "GET",
      },
      setSession,
      false
    ).then();
  }, []);

  useEffect(() => {
    post(
      "/units",
      { resource: "units", method: "GET" },
      setUnitsList,
      false
    ).then();
  }, []);

  useEffect(async () => {
    const currency = await getCurrency();
    setCurrency(currency);
  }, []);

  useEffect(() => {
    post(
      "/medicine-sales",
      {
        resource: "sales",
        method: "GET",
        status: isActive ? "completed" : "cancelled",
        offset: offset,
      },
      setSalesList,
      false
    ).then();
  }, [isActive, offset, reFetch]);

  const calculate_intial_price = (element, quantity) => {
    let medicine_quantity = element?.quantity;
    if (quantity > 0) {
      medicine_quantity = quantity;
    }
    if (element?.unit_id == "item") {
      return element?.selling_price_per_unit * medicine_quantity;
    } else if (element?.unit_id == "strip") {
      return element?.selling_price_per_strip * medicine_quantity;
    } else if (element?.unit_id) {
      return element?.selling_price_per_pack * medicine_quantity;
    } else {
      return 0;
    }
  };

  const fetch_additional_params = (formulas, quantity = 0) => {
    var element = {};
    formulas.forEach((formula) => {
      element.quantity = formula.quantity;
      element.medicine_id = formula.id;
      formula["entity_pricings"].forEach((entity) => {
        element.selling_price_per_strip = entity.selling_price_per_strip;
        element.selling_price_per_unit = entity.selling_price_per_unit;
        element.selling_price_per_pack = entity.selling_price_per_pack;
        element.stock_inventory_id = entity.stock_inventory_id;
        element.unit_id = entity.unit_id;
      });
    });
    let price = calculate_intial_price(element, quantity);
    element.price = price;
    return element;
  };

  const handleChange = (e, rowIndex = 0) => {
    let updatedSalesParams = [...salesParams];
    switch (e?.fieldName || e?.target?.id) {
      case "medicine_formula_id":
        post(
          "/stock-inventories",
          {
            resource: "stocks",
            method: "GET",
            status: "active",
            [e.target.id]: e.target.value,
          },
          setStocksList,
          false
        ).then();
        break;
      case "medicine_id":
        setMedicineFormulaList([]);
        medicinesList["data"].filter((medicine) =>
          medicine.id == e.key
            ? setMedicineFormulaList(medicine["medicine_formulas"]["active"])
            : null
        );
        const medicines = medicinesList["data"].filter((medicine) =>
          medicine.id == e.key ? medicine["medicine_formulas"] : null
        );
        const element = fetch_additional_params(
          medicines[0]["medicine_formulas"]
        );
        const newObj = {
          unit_id: element.unit_id,
          resource: "medicine_sales",
          quantity: element.quantity,
          medicine_id: element.medicine_id,
          price: element.price,
          stock_id: element.stock_inventory_id,
        };
        updatedSalesParams[rowIndex] = newObj;
        setSalesParams(updatedSalesParams);
        break;
      case "unit_id":
        updatedSalesParams[rowIndex]["unit_id"] = e.target.value;
        updatedSalesParams[rowIndex].unit_idError = "";
        updatedSalesParams[rowIndex].hasError = false;
        setSalesParams(updatedSalesParams);
        calculateTotalSale();
        break;
      case "price":
        updatedSalesParams[rowIndex]["price"] = e.target.value;
        updatedSalesParams[rowIndex].priceError = "";
        updatedSalesParams[rowIndex].hasError = false;
        setSalesParams(updatedSalesParams);
        calculateTotalSale();
        break;
      case "quantity":
        updatedSalesParams[rowIndex]["quantity"] = e.target.value;
        const medicinesSales = medicinesList["data"].filter((medicine) =>
          medicine.id == updatedSalesParams[rowIndex]["medicine_id"]
            ? medicine["medicine_formulas"]
            : null
        );
        const elementSales = fetch_additional_params(
          medicinesSales[0]["medicine_formulas"],
          updatedSalesParams[rowIndex]["quantity"]
        );
        updatedSalesParams[rowIndex]["price"] = elementSales?.price;
        updatedSalesParams[rowIndex].quantityError = "";
        updatedSalesParams[rowIndex].hasError = false;
        setSalesParams(updatedSalesParams);
        calculateTotalSale();
        break;
      default:
        setSalesParams({ ...salesParams, [e.target.id]: e.target.value });
        calculateTotalSale();
    }
  };

  const calculateTotalSale = () => {
    setTotalItems(salesParams.length);
    setTotalPrice(salesParams.reduce((sum, sale) => sum + sale.price, 0));
  };

  const validate = (salesParams) => {
    // Check if any medicine is selected
    if (!salesParams[0].medicine_id) {
      toast.error(`${Notifications.notaddfailed}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return { result: null, hasError: true };
    } else {
      const result = salesParams.map((item) => {
        const { quantity, price, unit_id, medicine_id } = item;
        const medicines = medicinesList["data"].filter((medicine) =>
          medicine.id == medicine_id ? medicine["medicine_formulas"] : null
        );
        const element = fetch_additional_params(
          medicines[0]["medicine_formulas"],
          quantity
        );

        if (quantity < 1) {
          item.quantityError = "Quantity must be more than 1!";
          item.hasError = true;
        }
        if (price < 1 || price < element.price) {
          item.priceError = `Price not less then ${element?.price}`;
          item.hasError = true;
        }
        if (unit_id == "") {
          item.unit_idError = "Invalid Unit id";
          item.hasError = true;
        }
        item.quantity = parseInt(item.quantity);
        return item;
      });
      const hasError = result.some((item) => item.hasError);
      return { result, hasError };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reqFor = "/medicine-sale";
    const { result, hasError } = validate(salesParams);
    if (hasError) return;
    setSalesParams(result);
    // Stop submission if any field has error
    const sale = [...salesParams];
    switch (action) {
      case "save":
        const stock = sale.map((sale) => {
          return getStock(sale.stock_id);
        });
        const resolveStockPromises = await Promise.all(stock);
        const med = resolveStockPromises.map((stock) => {
          return getMedicine(stock.medicine_formula_id);
        });
        const resolveMedPromises = await Promise.all(med);

        const total_stock = sale.map((sale) => {
          if (sale.unit_id == "pack") {
            const total = resolveMedPromises.map((med) => {
              const total =
                sale.quantity * med.strips_per_pack * med.units_per_strip;
              return total;
            });
            return total[0];
          } else {
            return sale.quantity;
          }
        });

        total_stock.map((total) => {
          resolveStockPromises.map(async (stock) => {
            if (total <= stock.total_items) {
              const data = await addOne(sale, session["id"]);
              showSalePopup(data.id);
              reFetch ? setReFetch(false) : setReFetch(true);
              setSalesParams(salesParams);
            } else {
              toast.error(`${Notifications.errorStock}`);
            }
          });
        });
        break;
      case "save-draft": {
        post(
          reqFor,
          {
            sale,
            method: "POST",
            user_id: session["id"],
            resource: "medicine_sales",
            status_id: "draft",
          },
          false
        ).then(() => (reFetch ? setReFetch(false) : setReFetch(true)));
        toast.success(`${Notifications.successMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setSalesParams(salesParams);
        break;
      }
      case "finalise-draft":
        post(
          reqFor,
          {
            sale_id: draftSaleId,
            sale,
            method: "UPDATE",
            user_id: session["id"],
            resource: "medicine_sales",
            status_id: "completed",
          },
          false
        ).then(() => (reFetch ? setReFetch(false) : setReFetch(true)));
        setSalesParams(salesParams);
        break;
      case "reset":
        const sales = [...salesParams];
        if (sales.length <= 1) {
          setSalesParams([defaultSalesParams]);
        } else {
          console.log("reset form");
        }
        break;
      default:
        setSalesParams([...defaultSalesParams]);
    }
    e.preventDefault();
  };

  return (
    <Layout>
      <div className="modal modal-md fade" id="sale-model">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card shadow mb-0">
                  <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Sale Detail
                    </h6>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="card-body p-3 prw text-center">
                    {(() => {
                      if (printableSale) {
                        return (
                          <>
                            <div className="p-0">
                              <h2>{slipParams.store_name}</h2>
                              <h6>{slipParams.store_branch_name}</h6>
                              Address: {slipParams.store_address} <br />
                              Phone: {slipParams.store_phone} <br />
                              Date: {date(printableSale.inserted_at)} <br />
                              Generated By: {printableSale.user.name} <br />
                              Invoice Number: {printableSale.id} <br /> <br />
                              <table className="table table-sale-receipt table-sm text-center">
                                <thead>
                                  <tr>
                                    <th>Item</th>
                                    <th>Quantity</th>
                                    <th>Type</th>
                                    <th>Price ({slipParams.currency})</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {printableSale.sale_medicines.map(
                                    (key, i) => (
                                      <tr key={i}>
                                        <td>{key.medicine.name}</td>
                                        <td>{key.quantity}</td>
                                        <td>{key.unit_id}</td>
                                        <td>{key.price}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                              <hr />
                              <div width={100} className="text-right">
                                Total Items:{" "}
                                <b>{printableSale.sale_medicines.length}</b>{" "}
                                <br />
                                <br />
                                Sub Total: <b>{printableSale.sub_total}</b>{" "}
                                <br />
                                {slipParams.discount}% Discount:{" "}
                                <b>{printableSale.discount}</b>
                                <br />
                                {slipParams.tax_type} @ {slipParams.tax_value}%:{" "}
                                <b>{printableSale.tax}</b>
                                <br />
                                Total: <b>{printableSale.total}</b> <br />
                              </div>
                              <hr />
                            </div>
                          </>
                        );
                      }
                    })()}
                  </div>
                  <div className="card-footer text-right">
                    <button onClick={printSale} className="btn btn-primary">
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isActive ? (
        <div className="accordionformblk mb-3">
          <SalesForm
            action={action}
            setAction={setAction}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            medicinesList={medicinesList["data"]}
            stocksList={stocksList["data"]}
            unitsList={unitsList["data"]}
            medicineFormulaList={medicineFormulaList}
            setSearch={setSearch}
            salesParams={salesParams}
            setSalesParams={setSalesParams}
            calculateTotalSale={calculateTotalSale}
            defaultSalesParams={defaultSalesParams}
            totalPrice={totalPrice}
            totalItems={totalItems}
            setTotalItems={setTotalItems}
            setTotalPrice={setTotalPrice}
            showSalePopup={showSalePopup}
            setDraftSaleId={setDraftSaleId}
            draftSaleId={draftSaleId}
            setReturnSaleId={setReturnSaleId}
            returnSaleId={returnSaleId}
            currency={currency}
            createMedicine={createMedicine}
          />
        </div>
      ) : (
        <span />
      )}
      <div className="card shadow mb-4">
        <div className="card-header">
          <h5 className="m-0 font-weight-bold text-primary">
            {isActive ? "Sales" : "Sales Logs"}
          </h5>
        </div>
        <div className="card-body p-1">
          <SalesTable
            salesList={salesList["data"]}
            setAction={setAction}
            isActive={isActive}
            currency={currency}
          />
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Pagination
              limit={10}
              offset={offset}
              total={salesList["total"]}
              onClick={(e, offset) => setOffset(offset)}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </Layout>
  );
};

export default Sales;
