import { post } from "../utils/http.util";

const resource = "system_settings";

// Get all system settings
export const getAllSettings = async () => {
  const data = await post(
    "/system_settings",
    { resource, method: "GET" },
    "POST"
  );
  const keys = {};
  data.data.forEach((x) => (keys[x.key] = x.value));
  return keys;
};

export const saveAllSettings = (values) => {
  return post("/system_setting", { values, resource, method: "UPDATE" }, "PUT");
};

export const getCurrency = async () => {
  const data = await getAllSettings();
  return data["currency"];
};
