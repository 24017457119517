import { post } from "../utils/http.util";

const resource = "medicine_sales";

export const getAllMedicines = (status_id = "active") => {
  return post("/medicines", { resource, method: "GET", status_id }, "POST");
};

export const getMedicine = (id) => {
  return post("/medicines", { id, resource, method: "GET" }, "POST");
};

export const searchMedicines = (search, status_id = "active") => {
  return post(
    "/medicines",
    { search, resource, method: "POST", status_id },
    "POST"
  );
};
