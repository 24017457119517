import { post } from "../utils/http.util";

const resource = "units";

// Get all units
export const getAll = (offset, status_id = "active") => {
  return post("/units", { resource, method: "GET", offset, status_id }, "POST");
};

// Get one unit
export const getOne = (id, status_id = "active") => {
  return post("/unit", { id, resource, method: "GET", status_id }, "POST");
};

// Add a new unit
export const addOne = (name) => {
  return post("/unit", { name, resource, method: "POST" }, "POST");
};

// Update a single unit
export const updateOne = (id, name, status_id = "active") => {
  return post(
    "/unit",
    { id, resource, name, method: "UPDATE", status_id },
    "PUT"
  );
};

// Delete one unit
export const deleteOne = (id) => {
  return post(
    "/unit",
    { id, resource, method: "DELETE", status_id: "inactive" },
    "DELETE"
  );
};

export const searchUnits = (search, offset, status_id = "active") => {
  return post(
    "/units",
    { offset, search, resource, method: "POST", status_id },
    "POST"
  );
};
