import { useState } from "react";

import Header from "./header/index";
import Footer from "./footer/index";
import Sidebar from "./sidebar";

const Layout = ({ children }) => {
  const [toggle, setToggle] = useState(false);
  const toggleFunction = () => setToggle(!toggle);

  return (
    <>
      <div id="wrapper">
        <Sidebar togglecls={toggle} />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header clickfun={toggleFunction} />
            <div className="container-fluid">{children}</div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
