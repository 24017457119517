import { Redirect, Route, Switch } from "react-router-dom";

import { getPermissions } from "../utils/permissions.util";
import {
  // Brands
  AddBrand,
  ActiveBrands,
  InactiveBrands,
  // Doses
  AddDose,
  ActiveDoses,
  InactiveDoses,
  // Units
  AddUnit,
  ActiveUnits,
  InactiveUnits,
  // Entity types
  AddType,
  ActiveTypes,
  InactiveTypes,
  // Salts
  AddSalt,
  EditSalt,
  ActiveSalts,
  InactiveSalts,
  // Medicines
  AddMedicine,
  EditMedicine,
  ActiveMedicines,
  InactiveMedicines,
  // Stocks
  Stocks,
  // Discounts
  ActiveDiscounts,
  InactiveDiscounts,
  // Sales
  Sales,
  SaleDrafts,
  // Roles
  Addrole,
  Editrole,
  ActiveRoles,
  InactiveRoles,
  // Users
  AddUser,
  AddEmployee,
  EditEmployee,
  ActiveUsers,
  InactiveUsers,
  UserRoles,
  // System Settings
  SystemSettings,
} from "../components";

let routes = [
  // Brands
  {
    path: "/active-brands",
    component: ActiveBrands,
    name: "Active Brands",
    per: 0,
    res: "brands",
  },
  {
    path: "/inactive-brands",
    component: InactiveBrands,
    name: "Inactive Brands",
    per: 0,
    res: "brands",
  },
  {
    path: "/add-brand",
    component: AddBrand,
    name: "Brands",
    per: 1,
    res: "brands",
  },
  // Doses
  {
    path: "/active-doses",
    component: ActiveDoses,
    name: "Active Doses",
    per: 0,
    res: "doses",
  },
  {
    path: "/inactive-doses",
    component: InactiveDoses,
    name: "Inactive Doses",
    per: 0,
    res: "doses",
  },
  {
    path: "/add-dose",
    component: AddDose,
    name: "Doses",
    per: 1,
    res: "doses",
  },
  // Units
  {
    path: "/active-units",
    component: ActiveUnits,
    name: "Active Units",
    per: 0,
    res: "units",
  },
  {
    path: "/inactive-units",
    component: InactiveUnits,
    name: "Inactive Units",
    per: 0,
    res: "units",
  },
  {
    path: "/add-unit",
    component: AddUnit,
    name: "Units",
    per: 1,
    res: "units",
  },
  // Types
  {
    path: "/active-types",
    component: ActiveTypes,
    name: "Active Types",
    per: 0,
    res: "entity_types",
  },
  {
    path: "/inactive-types",
    component: InactiveTypes,
    name: "Inactive Types",
    per: 0,
    res: "entity_types",
  },
  {
    path: "/add-type",
    component: AddType,
    name: "Types",
    per: 1,
    res: "types",
  },
  // Salts
  {
    path: "/add-salt",
    component: AddSalt,
    name: "Salts",
    per: 1,
    res: "salts",
  },
  {
    path: "/active-salts",
    component: ActiveSalts,
    name: "Active Salts",
    per: 0,
    res: "salts",
  },
  {
    path: "/inactive-salts",
    component: InactiveSalts,
    name: "Inactive Salts",
    per: 0,
    res: "salts",
  },
  {
    path: "/edit-salt",
    component: EditSalt,
    name: "Edit Salts",
    per: 2,
    res: "salts",
  },
  // Medicines
  {
    path: "/active-medicines",
    component: ActiveMedicines,
    name: "Active Medicines",
    per: 0,
    res: "medicines",
  },
  {
    path: "/inactive-medicines",
    component: InactiveMedicines,
    name: "Inactive Medicines",
    per: 0,
    res: "medicines",
  },
  {
    path: "/add-medicine",
    component: AddMedicine,
    name: "Medicines",
    per: 1,
    res: "medicines",
  },
  {
    path: "/edit-medicine",
    component: EditMedicine,
    name: "Edit Medicines",
    per: 2,
    res: "medicines",
  },
  // Stocks
  {
    path: "/active-stocks",
    component: Stocks,
    name: "Active Stocks",
    per: 0,
    res: "stocks",
  },
  {
    path: "/inactive-stocks",
    component: Stocks,
    name: "Inactive Stocks",
    per: 0,
    res: "stocks",
  },
  // Discounts
  // TODO: change permission to discounts res: "discounts" when permission is added at backend
  {
    path: "/active-discounts",
    component: ActiveDiscounts,
    name: "Active Discounts",
    per: 0,
    res: "sales",
  },
  {
    path: "/inactive-discounts",
    component: InactiveDiscounts,
    name: "Inactive Discounts",
    per: 0,
    res: "sales",
  },
  // Sales
  { path: "/sales", component: Sales, name: "Sales", per: 0, res: "sales" },
  {
    path: "/sale-logs",
    component: Sales,
    name: "Sale Logs",
    per: 0,
    res: "sales",
  },
  {
    path: "/sale-drafts",
    component: SaleDrafts,
    name: "Sale Drafts",
    per: 0,
    res: "sales",
  },
  // Roles
  {
    path: "/add-role",
    component: Addrole,
    name: "Roles & Permissions",
    per: 1,
    res: "roles",
  },
  {
    path: "/active-roles",
    component: ActiveRoles,
    name: "Active Roles",
    per: 0,
    res: "roles",
  },
  {
    path: "/inactive-roles",
    component: InactiveRoles,
    name: "Inactive Roles",
    per: 0,
    res: "roles",
  },
  {
    path: "/edit-user-roles",
    component: UserRoles,
    name: "Roles & Permissions",
    per: 2,
    res: "user_roles",
  },
  {
    path: "/edit-role",
    component: Editrole,
    name: "Roles",
    per: 2,
    res: "roles",
  },
  // Users
  {
    path: "/add-employee",
    component: AddEmployee,
    name: "Employees",
    per: 1,
    res: "employees",
  },
  {
    path: "/edit-employee",
    component: EditEmployee,
    name: "Edit Employees",
    per: 2,
    res: "employees",
  },
  {
    path: "/add-user",
    component: AddUser,
    name: "Users",
    per: 1,
    res: "users",
  },
  {
    path: "/active-users",
    component: ActiveUsers,
    name: "Active Users",
    per: 0,
    res: "users",
  },
  {
    path: "/inactive-users",
    component: InactiveUsers,
    name: "Inactive Users",
    per: 0,
    res: "users",
  },
  // System Settings
  {
    path: "/system-settings",
    component: SystemSettings,
    name: "System Settings",
    per: 0,
    res: "system_settings",
  },
];

const Routes = () => {
  const url = process.env.PUBLIC_URL;
  return (
    <Switch>
      {routes.map((item) =>
        item["per"] < getPermissions(item["res"]) ? (
          <Route
            exact
            path={`${url + item["path"]}`}
            component={item["component"]}
          />
        ) : (
          ""
        )
      )}
      <Redirect to="/404" />
    </Switch>
  );
};

export default Routes;
