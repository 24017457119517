import { getToken } from "./session.util";
import * as env from "../config/config";

const BASE_URL = env.config.BASE_URL;

// Set request params (body, header with or without auth)
let request = (body, method, token = true) => {
  const headers = {
    Origin: "*",
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (token) {
    headers["Authorization"] = `Bearer ${getToken()}`;
  }

  return {
    method: method,
    headers,
    body: JSON.stringify(body),
  };
};

// Post request method
export const post = async (uri, body, method = "POST") => {
  return fetch(BASE_URL + `${uri}`, request(body, method))
    .then((res) => res.json())
    .then((response) => response)
    .catch((error) => error);
};

// Post request method without authorization header
export const postNoAuth = async (uri, body, method = "POST") => {
  return fetch(BASE_URL + `${uri}`, request(body, method, false))
    .then((res) => res.json())
    .then((response) => response)
    .catch((error) => error);
};
