import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import "rsuite/dist/styles/rsuite-default.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";

import Notifications from "../../utils/notifications";
import BASE_URL from "../../utils/api";
import { getPermissions } from "../../utils/permissions.util";
import Layout from "../layouts/index";
import { date } from "../../utils/date.util";
import { getToken } from "../../utils/session.util";

// Configurations
const theme = createTheme();
toast.configure({ autoClose: 8000, draggable: false });

const InactiveRoles = () => {
  const [roles, setroles] = useState([]);
  const [loading, setloading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [alength, setalength] = useState();

  // Hooks
  useEffect(() => fetchData(offset), [offset]);

  const fetchData = async (offset) => {
    setloading(true);
    const res = await fetch(BASE_URL + "/roles", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "roles",
        method: "GET",
        offset: offset,
        status_id: "inactive",
      }),
    })
      .then((res) => res.json())
      .then(
        (resp) => {
          setroles(resp.data);
          setalength(resp.total);
          setloading(false);
        },
        (error) => {}
      );
  };

  function handleDelete(id, name) {
    fetch(BASE_URL + "/role", {
      method: "DELETE",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        id: `${id}`,
        resource: "roles",
        name: `${name}`,
        method: "DELETE",
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (Object.prototype.hasOwnProperty.call(result, "error")) {
          toast.error(`${result["error"]["message"]}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          fetchData(offset);
          toast.success(`${Notifications.deletedsuccess}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  }

  const handleClick = (offset) => {
    setOffset(offset);
    fetchData(offset);
  };

  // ======== Permissions Data =========
  let addbtns;
  if (1 < getPermissions("roles")) {
    addbtns = (
      <Link
        to={process.env.PUBLIC_URL + "/add-role"}
        className="btn btn-primary btn-icon-split"
      >
        <span className="icon text-white-50">
          <i className="fas fa-plus"></i>
        </span>
        <span className="text">Add</span>
      </Link>
    );
  } else {
    addbtns = <span></span>;
  }

  return (
    <Layout>
      <div className="card shadow mb-4">
        <div className="card-header">
          <h5 className="m-0 font-weight-bold text-primary">
            Inactive Roles and Permissions
          </h5>
        </div>
        <div className="card-body p-1">
          <Table
            data={roles}
            minHeight={300}
            loading={loading}
            autoHeight={true}
            wordWrap
          >
            <Column width={80} verticalAlign="middle">
              <HeaderCell>ID</HeaderCell>
              <Cell>
                {(rowData, rowIndex) => {
                  return <span>{rowIndex + 1}</span>;
                }}
              </Cell>
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell>Role Name</HeaderCell>
              <Cell dataKey="name" />
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell>Date Added</HeaderCell>
              <Cell>{(data) => date(data["inserted_at"], "medium")}</Cell>
            </Column>
            <Column width={90} fixed="right" verticalAlign="middle">
              <HeaderCell>Action</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <>
                      <span className="ml-3">
                        {3 < getPermissions("roles") ? (
                          <a
                            href
                            className="text-success"
                            onClick={() =>
                              handleDelete(rowData.id, rowData.name)
                            }
                          >
                            <i className="fas fa-sync"></i>{" "}
                          </a>
                        ) : (
                          <span></span>
                        )}
                      </span>
                    </>
                  );
                }}
              </Cell>
            </Column>
          </Table>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Pagination
              limit={10}
              offset={offset}
              total={alength}
              onClick={(e, offset) => handleClick(offset)}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </Layout>
  );
};

export default InactiveRoles;
