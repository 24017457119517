import { Cell, Column, HeaderCell, Table } from "rsuite-table";
import { useState } from "react";
import $ from "jquery";
import { useHistory } from "react-router-dom";

import { date } from "../../../utils/date.util";
import { getOne } from "../../../services/sales.service";
import { getAllSettings } from "../../../services/settings.service";

export const SalesTable = ({ salesList, loading, currency }) => {
  const history = useHistory();
  const [sale, setSale] = useState(null);
  const [settingParams, setSettingParams] = useState(null);

  const showSalePopup = async (id) => {
    const settings = await getAllSettings();
    const data = await getOne(id);
    const sale = data[0];

    // Values
    const currency = sale.currency || null;
    const discount = sale.total_sale_amount * (sale.discount / 100);
    const tax =
      sale.tax_value === "0"
        ? 0
        : (sale.total_sale_amount - discount) *
          (parseFloat(sale.tax_value) / 100);

    sale.sub_total = sale.total_sale_amount + ` ${currency}`;
    sale.discount_value = discount.toFixed(2) + ` ${currency}`;
    sale.tax = tax.toFixed(2) + ` ${currency}`;
    sale.total =
      (sale.total_sale_amount - discount + tax).toFixed(2) + ` ${currency}`;

    setSettingParams(settings);
    setSale(sale);
    $("#sale-model-table").modal("show");
  };

  const printSale = () => {
    console.log("Print functionality");
  };

  return (
    <div>
      <Table
        data={salesList}
        minHeight={300}
        autoHeight={true}
        loading={loading}
        headerHeight={37}
        wordWrap
      >
        <Column width={80} verticalAlign="middle">
          <HeaderCell>Sale ID</HeaderCell>
          <Cell dataKey="id" />
        </Column>
        <Column flexGrow={2} verticalAlign="middle">
          <HeaderCell>Total Amount</HeaderCell>
          <Cell>
            {(data) => {
              return (
                <>
                  {data.total_sale_amount} {data.currency}
                </>
              );
            }}
          </Cell>
        </Column>
        <Column flexGrow={2} verticalAlign="middle">
          <HeaderCell>Medicines</HeaderCell>
          <Cell>
            {(data) => {
              return (
                <div className="rolelist">
                  {(() => {
                    return data.sale_medicines.map((sale, i) => {
                      return (
                        <span className={`badge badge-primary a${i}`} key={i}>
                          {sale.medicine.name}
                        </span>
                      );
                    });
                  })()}
                </div>
              );
            }}
          </Cell>
        </Column>
        <Column flexGrow={2} verticalAlign="middle">
          <HeaderCell>Sold By</HeaderCell>
          <Cell>{(data) => data["user"]["name"]}</Cell>
        </Column>
        <Column flexGrow={2} verticalAlign="middle">
          <HeaderCell>Date Added</HeaderCell>
          <Cell>{(data) => date(data["inserted_at"], "medium")}</Cell>
        </Column>
        <Column fixed="right" flexGrow={1} verticalAlign="middle">
          <HeaderCell>Actions</HeaderCell>
          <Cell>
            {(data) => {
              return (
                <>
                  <span>
                    {
                      <a
                        href
                        data-toggle="tooltip"
                        className="cp text-dark"
                        title="View"
                        onClick={() => showSalePopup(data.id)}
                      >
                        <i className="fas fa-eye"></i>
                      </a>
                    }
                  </span>
                  {/* <span className="ml-2">
                    {
                      <a
                        onClick={() => {
                          history.push(`/sales?return=${data.id}`);
                          history.go(`/sales?return=${data.id}`);
                        }}
                        className="cp"
                        data-toggle="tooltip"
                        title="Return Sale"
                      >
                        <i className="fas fa-play text-success"></i>
                      </a>
                    }
                  </span> */}
                </>
              );
            }}
          </Cell>
        </Column>
      </Table>
      <div
        className="modal modal-md fade"
        id="sale-model-table"
        autoHeight={true}
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card shadow mb-0">
                  <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Sale Detail
                    </h6>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="card-body p-3 prw text-center">
                    {(() => {
                      if (sale) {
                        return (
                          <>
                            <div className="p-0">
                              <h2>{settingParams.store_name}</h2>
                              <h6>{settingParams.store_branch_name}</h6>
                              Address: {settingParams.store_address} <br />
                              Phone: {settingParams.store_phone} <br />
                              Date: {date(sale.inserted_at)} <br />
                              Generated By: {sale.user.name} <br />
                              Invoice Number: {sale.id} <br /> <br />
                              <table className="table table-sale-receipt table-sm text-center">
                                <thead>
                                  <tr>
                                    <th>Item</th>
                                    <th>Quantity</th>
                                    <th>Type</th>
                                    <th>Price ({sale.currency})</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sale.sale_medicines.map((key, i) => (
                                    <tr key={i}>
                                      <td>{key.medicine.name}</td>
                                      <td>{key.quantity}</td>
                                      <td>{key.unit_id}</td>
                                      <td>{key.price}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <hr />
                              <div width={100} className="text-right">
                                Total Items: <b>{sale.sale_medicines.length}</b>{" "}
                                <br />
                                <br />
                                Sub Total: <b>{sale.sub_total}</b> <br />
                                {sale.discount}% Discount:{" "}
                                <b>{sale.discount_value}</b>
                                <br />
                                {sale.tax_type} @ {sale.tax_value}%:{" "}
                                <b>{sale.tax}</b>
                                <br />
                                Total: <b>{sale.total}</b> <br />
                              </div>
                              <hr />
                            </div>
                          </>
                        );
                      }
                    })()}
                  </div>
                  <div className="card-footer text-right">
                    <button onClick={printSale} className="btn btn-primary">
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
