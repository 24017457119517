import { Cell, Column, HeaderCell, Table } from "rsuite-table";
import { Icon } from "rsuite";

import { getPermissions } from "../../../utils/permissions.util";
import { date } from "../../../utils/date.util";

export const StocksTable = ({
  stocksList,
  loading,
  fetchStock,
  setAction,
  setDataStatus,
}) => {
  return (
    <Table
      data={stocksList}
      minHeight={300}
      autoHeight={true}
      loading={loading}
      headerHeight={37}
      wordWrap
    >
      <Column width={50} verticalAlign="middle">
        <HeaderCell>ID</HeaderCell>
        <Cell dataKey="id" />
      </Column>
      <Column minWidth={200} flexGrow={2} verticalAlign="middle">
        <HeaderCell>Medicine</HeaderCell>
        <Cell>
          {(rowData) => {
            const keys = ["name", "quantity", "dose_id"];
            return (
              <span>
                {keys
                  .map((key) => {
                    if (key === "name") {
                      return rowData["medicine_formula"]["medicine"][key];
                    } else {
                      return rowData["medicine_formula"][key];
                    }
                  })
                  .join(" ")}
              </span>
            );
          }}
        </Cell>
      </Column>
      <Column flexGrow={2} verticalAlign="middle">
        <HeaderCell>Stock Quantity</HeaderCell>
        <Cell dataKey="total_stock_purchased" />
      </Column>
      <Column flexGrow={1} verticalAlign="middle">
        <HeaderCell>Expiry Date</HeaderCell>
        <Cell dataKey="expiry_date" />
      </Column>
      <Column flexGrow={2} verticalAlign="middle">
        <HeaderCell>Date Added</HeaderCell>
        <Cell>{(data) => date(data["inserted_at"], "medium")}</Cell>
      </Column>
      <Column minWidth={120} fixed="right" flexGrow={1} verticalAlign="middle">
        <HeaderCell>Actions</HeaderCell>
        <Cell>
          {(rowData) => {
            return (
              <>
                {2 < getPermissions("doses") ? (
                  <a
                    title="Edit"
                    data-toggle="tooltip"
                    className="cp"
                    onClick={() => {
                      fetchStock(rowData.id);
                      setAction("update");
                    }}
                  >
                    <Icon icon="edit2" />
                  </a>
                ) : (
                  <span />
                )}
                <span className="ml-3">
                  {3 < getPermissions("doses") ? (
                    <a
                      href
                      data-toggle="tooltip"
                      className="cp"
                      title="Inactivate"
                      onClick={() =>
                        setDataStatus({
                          confirmbx: true,
                          rowid: rowData.id,
                          name: rowData["medicine_formula"]["medicine"]["name"],
                        })
                      }
                    >
                      <Icon icon="trash" />
                    </a>
                  ) : (
                    <span />
                  )}
                </span>
              </>
            );
          }}
        </Cell>
      </Column>
    </Table>
  );
};
