import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

import Layout from "../layouts/index";
import Notifications from "../../utils/notifications";
import { getPermissions } from "../../utils/permissions.util";
import {
  getAllSettings,
  saveAllSettings,
} from "../../services/settings.service";

// Configurations
toast.configure({ autoClose: 8000, draggable: false });

const SystemSettings = () => {
  const [settings, setSettings] = useState(null);

  // Load all settings at component render
  useEffect(async () => setSettings(await getAllSettings()), []);

  // Update all settings
  const saveSettings = async (event) => {
    event.preventDefault();

    await saveAllSettings({
      currency: event.target.currency.value,
      store_name: event.target.store_name.value,
      store_branch_name: event.target.store_branch_name.value,
      store_phone: event.target.store_phone.value,
      store_address: event.target.store_address.value,
      tax_type: event.target.tax_type.value,
      tax_value: event.target.tax_value.value,
      discount: event.target.discount.value,
    });

    toast.success(`${Notifications.updatedsuccess}`);
  };

  return (() => {
    if (settings) {
      return (
        <>
          <Layout>
            <Form onSubmit={saveSettings}>
              <div id="system-settings" className="card shadow mb-4">
                <div className="card-header">
                  <h5 className="m-0 font-weight-bold text-primary">
                    System Settings
                  </h5>
                </div>
                <div className="card-body px-4 pb-0">
                  <div>
                    <h5>Store Settings</h5>
                    <div className="row col-12">
                      <div className="col-md-6 col-sm-6 pb-2 pr-2">
                        Currency
                        <p>
                          Mention the currency here like USD, CAD, INR, PKR etc.
                          or maybe a symbol like $, ¢, £ etc.
                        </p>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                          <input
                            name="currency"
                            defaultValue={settings.currency}
                            autoComplete="off"
                            type="text"
                            placeholder="Enter currency"
                            required
                            autoFocus
                            className={"form-control-user form-control"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row col-12">
                      <div className="col-md-6 col-sm-6 pb-2 pr-2">
                        Store Name
                        <p>
                          Mention store name which will be mentioned on sale
                          slips.
                        </p>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                          <input
                            name="store_name"
                            defaultValue={settings.store_name}
                            autoComplete="off"
                            type="text"
                            placeholder="Enter store name"
                            required
                            autoFocus
                            className={"form-control-user form-control"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row col-12">
                      <div className="col-md-6 col-sm-6 pb-2 pr-2">
                        Branch Name
                        <p>
                          Mention branch name which will be mentioned on sale
                          slips.
                        </p>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        {" "}
                        <div className="form-group">
                          <input
                            name="store_branch_name"
                            defaultValue={settings.store_branch_name}
                            autoComplete="off"
                            type="text"
                            placeholder="Enter branch name"
                            required
                            autoFocus
                            className={"form-control-user form-control"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row col-12">
                      <div className="col-md-6 col-sm-6 pb-2 pr-2">
                        Store Address
                        <p>
                          Mention store address which will be mentioned on sale
                          slips.
                        </p>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        {" "}
                        <div className="form-group">
                          <input
                            name="store_address"
                            defaultValue={settings.store_address}
                            autoComplete="off"
                            type="text"
                            placeholder="Enter address"
                            required
                            autoFocus
                            className={"form-control-user form-control"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row col-12">
                      <div className="col-md-6 col-sm-6 pb-2 pr-2">
                        Store Contact
                        <p>
                          Mention store contact which will be mentioned on sale
                          slips.
                        </p>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        {" "}
                        <div className="form-group">
                          <input
                            name="store_phone"
                            defaultValue={settings.store_phone}
                            autoComplete="off"
                            type="text"
                            placeholder="Enter contact"
                            required
                            autoFocus
                            className={"form-control-user form-control"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <h5>Tax Information</h5>
                    <div className="row col-12">
                      <div className="col-md-6 col-sm-6 pb-2 pr-2">
                        Tax Type
                        <p>
                          Mention the tax type like VAT, GST, FBR etc. It will
                          be shown on sale slips also.
                        </p>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        {" "}
                        <div className="form-group">
                          <input
                            name="tax_type"
                            defaultValue={settings.tax_type}
                            autoComplete="off"
                            type="text"
                            placeholder="Enter tax type"
                            required
                            autoFocus
                            className={"form-control-user form-control"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row col-12">
                      <div className="col-md-6 col-sm-6 pb-2 pr-2">
                        Tax Value
                        <p>
                          Mention the tax percentage here. For example, 10 means
                          10 percent tax is applied to the whole value.
                        </p>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        {" "}
                        <div className="form-group">
                          <input
                            name="tax_value"
                            defaultValue={settings.tax_value}
                            autoComplete="off"
                            type="number"
                            placeholder="Enter tax value"
                            required
                            min={0}
                            max={100}
                            step={0.01}
                            autoFocus
                            className={"form-control-user form-control"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <h5>Discounts</h5>
                    <div className="row col-12">
                      <div className="col-md-6 col-sm-6 pb-2 pr-2">
                        Discount value on sales (%)
                        <p>
                          Mention the discount percentage here. For example, 10
                          means 10 percent discount is applied to whole value.
                          Enter 0 for no discount
                        </p>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        {" "}
                        <div className="form-group">
                          <input
                            name="discount"
                            defaultValue={settings.discount}
                            autoComplete="off"
                            type="number"
                            placeholder="Enter discount value"
                            required
                            min={0}
                            max={100}
                            step={0.01}
                            autoFocus
                            className={"form-control-user form-control"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {1 < getPermissions("system_settings") ? (
                  <div className="card-footer text-right">
                    <button className="btn btn-primary">Update</button>
                  </div>
                ) : (
                  <sapn></sapn>
                )}
              </div>
            </Form>
          </Layout>
        </>
      );
    } else {
      return (
        <>
          <p>Loading</p>
        </>
      );
    }
  })();
};

export default SystemSettings;
