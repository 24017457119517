import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import "rsuite/dist/styles/rsuite-default.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import { searchDoses } from "../../services/doses.service";

import Notifications from "../../utils/notifications";
import { getPermissions } from "../../utils/permissions.util";
import Layout from "../layouts/index";
import { date } from "../../utils/date.util";
import { getAll, updateOne } from "../../services/doses.service";

// Configurations
const theme = createTheme();
toast.configure({ autoClose: 8000, draggable: false });

const InactiveDoses = () => {
  const [doses, setDoses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [alength, setalength] = useState();
  const [datastatus, setDatastatus] = useState({
    confirmBox: false,
    id: "",
    name: "",
  });

  // Hooks
  useEffect(() => fetchData(offset), [offset]);

  const fetchData = async (offset) => {
    setLoading(true);
    const data = await getAll(offset, "inactive");
    setDoses(data.data);
    setalength(data.total);
    setLoading(false);
  };

  const handleActivation = async () => {
    if (datastatus.confirmBox === true) {
      setDatastatus({ confirmBox: false, id: "", name: "" });
      setLoading(true);
      const data = await updateOne(datastatus.id, datastatus.name);
      setLoading(false);
      if (Object.prototype.hasOwnProperty.call(data, "error")) {
        toast.error(`${data["error"]["message"]}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success(`${Notifications.successMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDatastatus({ confirmBox: false, id: "", name: "" });
        fetchData(offset);
      }
    }
  };

  const search = async (value) => {
    if (value.length > 2) {
      setLoading(true);
      setSearchValue(value);
      setOffset(0);
      const data = await searchDoses(value, offset, "inactive");
      setDoses(data.data);
      setalength(data.total);
      setLoading(false);
    } else {
      setOffset(0);
      setSearchValue("");
      fetchData();
    }
  };

  const activate = () => setDatastatus({ confirmBox: false, id: "", name: "" });

  const handleClick = (offset) => {
    setOffset(offset);
    if (searchValue !== "") {
      fetchData();
    } else {
      search(searchValue);
    }
  };

  return (
    <Layout>
      <div className="card shadow mb-4">
        <div className="card-header">
          <h5 className="m-0 font-weight-bold text-primary">
            Inactive Doses List
          </h5>
        </div>
        <div className="card-body p-1">
          <input
            autoComplete="off"
            type="text"
            onChange={(e) => search(e.target.value)}
            placeholder="Search..."
            className="form-control-user form-control"
          />
          <Table
            wordWrap
            data={doses}
            minHeight={300}
            autoHeight={true}
            loading={loading}
            headerHeight={37}
          >
            <Column verticalAlign="middle">
              <HeaderCell>ID</HeaderCell>
              <Cell>
                {(rowData, rowIndex) => {
                  return <span>{rowIndex + 1}</span>;
                }}
              </Cell>
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell>
                Dose <small>(Display name)</small>
              </HeaderCell>
              <Cell dataKey="name" />
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell>
                Dose <small>(Short name)</small>
              </HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell>Date Added</HeaderCell>
              <Cell>{(data) => date(data["inserted_at"], "medium")}</Cell>
            </Column>
            <Column fixed="right" flexGrow={1} verticalAlign="middle">
              <HeaderCell>Actions</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <>
                      <span>
                        {3 < getPermissions("doses") ? (
                          <a
                            href
                            className="cp"
                            data-toggle="tooltip"
                            title="Activate"
                            onClick={() =>
                              setDatastatus({
                                confirmBox: true,
                                id: rowData.id,
                                name: rowData.name,
                              })
                            }
                          >
                            <i className="fas fa-play text-success"></i>{" "}
                          </a>
                        ) : (
                          <span></span>
                        )}
                      </span>
                    </>
                  );
                }}
              </Cell>
            </Column>
          </Table>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Pagination
              limit={10}
              offset={offset}
              total={alength}
              onClick={(e, offset) => handleClick(offset)}
            />
          </MuiThemeProvider>
        </div>
      </div>
      <SweetAlert
        show={datastatus.confirmBox}
        showCancel
        confirmBtnText="Activate"
        confirmBtnBsStyle="success"
        title="Are you sure!"
        onConfirm={handleActivation}
        onCancel={activate}
        focusCancelBtn
        closeAnim={{ name: "hideSweetAlert", duration: 500 }}
      >
        You want to activate {datastatus.name}?
      </SweetAlert>
    </Layout>
  );
};

export default InactiveDoses;
