import Typography from "@material-ui/core/Typography";

const NotFound = () => {
  return (
    <div>
      <div>
        <Typography>404</Typography>
        <Typography>Page not found!</Typography>
        <Typography variant="caption">
          Sorry, but the page you were trying to view does not exist.
        </Typography>
      </div>
    </div>
  );
};

export default NotFound;
