import { Form } from "react-bootstrap";

import {
  buildOptions,
  SearchableSelect,
  TextField,
} from "../../common/forms/form-fields";
import { getPermissions } from "../../../utils/permissions.util";

export const StocksForm = ({
  action,
  setAction,
  handleSubmit,
  handleChange,
  medicinesList,
  medicineFormulaList,
  stocksParams,
  setSearch,
  isActive,
  currency,
}) => {
  const handleSearch = (e) => setSearch(e);

  const selectFields = [
    {
      id: "medicine_id",
      label: "Select Medicine",
      search: handleSearch,
      options: buildOptions(medicinesList, "id", ["name"], "medicine_id"),
    },
    {
      id: "medicine_formula_id",
      label: "Select Medicine Formula",
      search: null,
      options: buildOptions(
        medicineFormulaList,
        "id",
        ["quantity", "dose_id"],
        "medicine_formula_id"
      ),
    },
  ];

  const fields = [
    {
      id: "total_stock_purchased",
      label: "Stock Quantity",
      type: "number",
      placeHolder: "Enter Stock Amount",
      value: stocksParams["total_stock_purchased"],
    },
    {
      id: "expiry_date",
      label: "Expiry Date",
      type: "date",
      placeHolder: "Expiry Date",
      value: stocksParams["expiry_date"],
    },
    {
      id: "stock_shelf",
      label: "Stock Rack",
      type: "text",
      placeHolder: "Enter Stock Rack",
      value: stocksParams["stock_shelf"],
    },
    {
      id: "purchased_from",
      label: "Purchased From",
      type: "text",
      placeHolder: "Enter Stock Purchased From",
      value: stocksParams["purchased_from"],
    },
    {
      id: "delivered_by",
      label: "Delivered By",
      type: "text",
      placeHolder: "Enter Delivered By",
      value: stocksParams["delivered_by"],
    },
    {
      id: "purchase_date",
      label: "Purchased Date",
      type: "date",
      placeHolder: "Enter Purchased Date",
      value: stocksParams["purchase_date"],
    },
    {
      id: "can_change_price",
      label: "Can Change Price",
      type: "checkbox",
      placeHolder: null,
      value: stocksParams["can_change_price"],
    },
    {
      id: "selling_price_per_pack",
      label: "Selling Price/Pack",
      type: "number",
      placeHolder: "Enter Selling Price Per Pack",
      value: stocksParams["selling_price_per_pack"],
    },
    {
      id: "price_per_pack",
      label: "Buying Price/Pack",
      type: "number",
      placeHolder: "Enter Buying Price Per Pack",
      value: stocksParams["price_per_pack"],
    },
  ];

  return (() => {
    if (isActive) {
      return (
        <Form className="user" onSubmit={handleSubmit}>
          {1 < getPermissions("stocks") ? (
            <div id="accordion1">
              <div className="card">
                <div className="card-header">
                  <a
                    className="collapsed card-link d-block w-100 text-dark font-weight-600"
                    aria-expanded="true"
                    data-toggle="collapse"
                    href="#collapseTwo3"
                  >
                    <h5 className="m-0 font-weight-bold text-primary">
                      {action === "update" ? "Edit" : "Add"} Stock
                    </h5>
                  </a>
                </div>
                <div
                  id="collapseTwo3"
                  className="collapse show"
                  data-parent="#accordion1"
                >
                  <div className="card-body px-2 pt-2 pb-0">
                    <div id="accordion">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="row">
                            {selectFields.map((select) => (
                              <SearchableSelect
                                label={select["label"]}
                                handleSearch={select["search"]}
                                handleChange={handleChange}
                                options={select["options"]}
                              />
                            ))}
                            {fields.map((field) => {
                              if (field["id"] === "can_change_price") {
                                return (
                                  <>
                                    <TextField
                                      id={field["id"]}
                                      label={field["label"]}
                                      fieldType={field["type"]}
                                      placeHolder={field["placeHolder"]}
                                      value={field["value"]}
                                      handleChange={handleChange}
                                      style={{
                                        height: "20px",
                                        marginTop: "12px",
                                      }}
                                    />
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <TextField
                                      id={field["id"]}
                                      label={field["label"]}
                                      fieldType={field["type"]}
                                      placeHolder={field["placeHolder"]}
                                      value={field["value"]}
                                      handleChange={handleChange}
                                      style={{}}
                                    />
                                  </>
                                );
                              }
                            })}
                            <div className="form-group col-lg-6 col-md-6 col-sm-6 col-6">
                              <i>
                                All prices represented here are in {currency}.
                              </i>
                            </div>
                            <div className="form-group col-lg-6 col-md-6 col-sm-6 col-6 text-right">
                              {(() => {
                                if (action === "update") {
                                  return (
                                    <>
                                      <button
                                        type="submit"
                                        onClick={() => setAction("update")}
                                        className="btn btn-success btn-sm mx-2"
                                      >
                                        {" "}
                                        Update
                                      </button>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <button
                                        type="submit"
                                        onClick={() => setAction("add")}
                                        className="btn btn-success btn-sm mx-2"
                                      >
                                        {" "}
                                        Save
                                      </button>
                                    </>
                                  );
                                }
                              })()}
                              <button
                                type="submit"
                                onClick={() => setAction("")}
                                className="btn btn-secondary btn-sm"
                              >
                                {" "}
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <span></span>
          )}
        </Form>
      );
    } else {
      return <span></span>;
    }
  })();
};
