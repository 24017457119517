import SweetAlert from "react-bootstrap-sweetalert";

export const sweetAlert = (datastatus, identifier, Cancelfun, func1, func2) => {
  let func;
  if (identifier.event === "handleDeleteMedicineFormula") {
    func = func1;
  } else if (identifier.event === "handleDelete") {
    func = func2;
  }
  let message1 = `Inactivate`;
  let message2 = `You want to ${identifier.message} it?`;
  return (
    <SweetAlert
      show={datastatus.confirmbx}
      showCancel
      confirmBtnText={message1}
      confirmBtnBsStyle="error"
      title="Are you sure!"
      onConfirm={func}
      onCancel={Cancelfun}
      focusCancelBtn
      closeAnim={{ name: "hideSweetAlert", duration: 500 }}
    >
      {message2}
    </SweetAlert>
  );
};
