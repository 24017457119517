import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import "rsuite/dist/styles/rsuite-default.css";
import { Icon } from "rsuite";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import { searchDoses } from "../../services/doses.service";

import Notifications from "../../utils/notifications";
import { getPermissions } from "../../utils/permissions.util";
import Layout from "../layouts/index";
import { date } from "../../utils/date.util";
import {
  addOne,
  deleteOne,
  getAll,
  getOne,
  updateOne,
} from "../../services/doses.service";

// Configurations
const theme = createTheme();
toast.configure({ autoClose: 8000, draggable: false });

const ActiveDoses = () => {
  const [doses, setDoses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [doseId, setDoseId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [offset, setOffset] = useState(0);
  const [alength, setalength] = useState();
  const [ids, setIds] = useState("");
  const [datastatus, setDatastatus] = useState({
    confirmBox: false,
    id: "",
    name: "",
  });
  const [short_name, setShortName] = useState("");

  // Hooks
  useEffect(() => fetchData(offset), [offset]);

  const fetchData = async (offset) => {
    setLoading(true);
    const data = await getAll(offset);
    setDoses(data.data);
    setalength(data.total);
    setLoading(false);
  };

  const handleUpdate = async (e) => {
    setLoading(true);
    const data = await updateOne(id, name, short_name);
    setDoseId(data.dose_id);
    if (Object.prototype.hasOwnProperty.call(data, "error")) {
      toast.error(`${data["error"]["message"]}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success(`${Notifications.updatedsuccess}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      fetchData(offset);
    }
  };

  const getDose = async (id) => {
    setId(id);
    setLoading(true);
    const data = await getOne(id);
    setId(data.id);
    setName(data.name);
    setDoseId(data.short_name);
    setShortName(data.short_name);
    setLoading(false);
  };

  const search = async (value) => {
    if (value.length > 2) {
      setLoading(true);
      setSearchValue(value);
      setOffset(0);
      const data = await searchDoses(value, offset);
      setDoses(data.data);
      setalength(data.total);
      setLoading(false);
    } else {
      setOffset(0);
      setSearchValue("");
      fetchData();
    }
  };

  const handleDelete = async () => {
    if (datastatus.confirmBox === true) {
      setDatastatus({ confirmBox: false });
      setLoading(true);
      const data = await deleteOne(datastatus.id);
      setLoading(false);
      if (Object.prototype.hasOwnProperty.call(data, "error")) {
        toast.error(`${data["error"]["message"]}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success(`${Notifications.deletedsuccess}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDatastatus({ confirmBox: false, id: "", name: "" });
        fetchData(offset);
      }
    }
  };

  const cancelActivation = () =>
    setDatastatus({ confirmBox: false, id: "", name: "" });

  const handleClick = (offset) => {
    setOffset(offset);
    if (searchValue !== "") {
      fetchData();
    } else {
      search(searchValue);
    }
  };

  const addDosefunc = async () => {
    setLoading(true);
    const data = await addOne(name, doseId);
    setLoading(false);
    toast.success(`${Notifications.addedsuccess}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    fetchData(offset);
  };

  const addnewdosefunc = (e) => {
    e.preventDefault();
    addDosefunc();
    resetForm();
  };

  // Update Brand/Stock Type function
  const updatenewdosefunc = (e) => {
    e.preventDefault();
    handleUpdate();
    resetForm();
  };

  // Reset Form function
  const resetForm = (e) => {
    setName("");
    setDoseId("");
    setIds("");
    setId("");
    setShortName("");
  };

  return (
    <Layout>
      {1 < getPermissions("doses") ? (
        <div className="accordionformblk mb-3">
          <Form
            className="user"
            onSubmit={
              ids === "update"
                ? updatenewdosefunc
                : ids === "update_reset"
                ? updatenewdosefunc
                : addnewdosefunc
            }
          >
            <div id="accordion1">
              <div className="card">
                <div className="card-header">
                  <a
                    className="collapsed card-link d-block w-100 text-dark font-weight-600"
                    aria-expanded="true"
                    data-toggle="collapse"
                    href="#collapseTwo3"
                  >
                    <h5 className="m-0 font-weight-bold text-primary">
                      {id !== "" ? "Edit" : "Add"} Dose
                    </h5>
                  </a>
                </div>

                <div
                  id="collapseTwo3"
                  className="collapse show"
                  data-parent="#accordion1"
                >
                  <div className="card-body px-2 pt-2 pb-0">
                    <div id="accordion">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="row">
                            <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                              <input
                                name="name"
                                autoComplete="off"
                                value={name}
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                id="brand"
                                placeholder="Enter name"
                                required
                                autoFocus
                                className={"form-control-user form-control"}
                              />
                              <label htmlFor="name">
                                Dose <small>(Display name)</small>
                              </label>
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                              <input
                                name="doseId"
                                autoComplete="off"
                                value={doseId}
                                type="text"
                                onChange={(e) => {
                                  setDoseId(e.target.value);
                                  setShortName(e.target.value);
                                }}
                                id="doseId"
                                placeholder="Enter short name"
                                required
                                autoFocus
                                className={"form-control-user form-control"}
                              />
                              <label htmlFor="dose_id">
                                Dose <small>(Short name)</small>
                              </label>
                            </div>
                            <div className="form-group col-lg-6 col-md-12 col-sm-12 col-12">
                              {(() => {
                                if (id !== "") {
                                  return (
                                    <>
                                      <button
                                        type="submit"
                                        onClick={() => setIds("update")}
                                        className="btn btn-success btn-sm mx-2"
                                      >
                                        {" "}
                                        Update
                                      </button>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <button
                                        type="submit"
                                        onClick={() => setIds("add")}
                                        className="btn btn-success btn-sm mx-2"
                                      >
                                        {" "}
                                        Save
                                      </button>
                                    </>
                                  );
                                }
                              })()}
                              <button
                                type="button"
                                onClick={resetForm}
                                className="btn btn-secondary btn-sm"
                              >
                                {" "}
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      ) : (
        <span></span>
      )}

      <div className="card shadow mb-4">
        <div className="card-header">
          <h5 className="m-0 font-weight-bold text-primary">
            Active Doses List
          </h5>
        </div>
        <div className="card-body p-1">
          <input
            autoComplete="off"
            type="text"
            onChange={(e) => search(e.target.value)}
            placeholder="Search..."
            className="form-control-user form-control"
          />
          <Table
            data={doses}
            minHeight={300}
            autoHeight={true}
            loading={loading}
            headerHeight={37}
            wordWrap
          >
            <Column width={50} verticalAlign="middle">
              <HeaderCell>ID</HeaderCell>
              <Cell>
                {(rowData, rowIndex) => {
                  return <span>{rowIndex + 1}</span>;
                }}
              </Cell>
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell>
                Dose <small>(Display name)</small>
              </HeaderCell>
              <Cell dataKey="name" />
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell>
                Dose <small>(Short name)</small>
              </HeaderCell>
              <Cell dataKey="short_name" />
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell>Date Added</HeaderCell>
              <Cell>{(data) => date(data["inserted_at"], "medium")}</Cell>
            </Column>
            <Column fixed="right" flexGrow={1} verticalAlign="middle">
              <HeaderCell>Actions</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <>
                      {(() => {
                        if (2 < getPermissions("doses")) {
                          return (
                            <a
                              title="Edit"
                              data-toggle="tooltip"
                              className="cp"
                              onClick={() => getDose(rowData.id)}
                            >
                              {" "}
                              <Icon icon="edit2" />
                            </a>
                          );
                        }
                      })()}
                      <span className="ml-3">
                        {3 < getPermissions("doses") ? (
                          <a
                            href
                            data-toggle="tooltip"
                            className="cp"
                            title="Inactivate"
                            onClick={() =>
                              setDatastatus({
                                confirmBox: true,
                                id: rowData.id,
                                name: rowData.name,
                              })
                            }
                          >
                            <Icon icon="trash" />{" "}
                          </a>
                        ) : (
                          <span></span>
                        )}
                      </span>
                    </>
                  );
                }}
              </Cell>
            </Column>
          </Table>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Pagination
              limit={10}
              offset={offset}
              total={alength}
              onClick={(e, offset) => handleClick(offset)}
            />
          </MuiThemeProvider>
        </div>
      </div>

      <SweetAlert
        show={datastatus.confirmBox}
        showCancel
        confirmBtnText="Inactivate"
        confirmBtnBsStyle="error"
        title="Are you sure!"
        onConfirm={handleDelete}
        onCancel={cancelActivation}
        focusCancelBtn
        closeAnim={{ name: "hideSweetAlert", duration: 500 }}
      >
        You want to inactivate {datastatus.name}?
      </SweetAlert>
    </Layout>
  );
};

export default ActiveDoses;
