import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { Cell, Column, HeaderCell, Table } from "rsuite-table";
import "rsuite/dist/styles/rsuite-default.css";
import { Icon } from "rsuite";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import $ from "jquery";
import { useLocation } from "react-router-dom";
import { searchMedicines } from "../../services/medicines.service";

import Notifications from "../../utils/notifications";
import BASE_URL from "../../utils/api";
import { getPermissions } from "../../utils/permissions.util";
import Layout from "../layouts/index";
import { sweetAlert } from "../common/sweetalert/sweet-alert";
import { MedicineDetails } from "./medicine-details.modal";
import { getToken } from "../../utils/session.util";
import { getCurrency } from "../../services/settings.service";

// Configurations
const theme = createTheme();
toast.configure({ autoClose: 8000, draggable: false });

const ActiveMedicines = () => {
  const location = useLocation();
  const [medicine_list, setMedicine_list] = useState([]);
  const [loading, setloading] = useState(false);
  const [prloading, setprloading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [alength, setalength] = useState();
  const [entity_type_id, setEntity_type_id] = useState("");
  const [entity_type_ids, setEntity_type_ids] = useState("");
  const [entity_type_list, setEntity_type_list] = useState([]);
  const [brand_id, setBrand_id] = useState("");
  const [brand_id_list, setBrand_id_list] = useState([]);
  const [identifier, setIdentifier] = useState({
    row_id: "",
    event: "",
    message: "",
  });
  const [mdname, setMdname] = useState("");
  const [mdshelf, setMdshelf] = useState("");
  const [id, setId] = useState("");
  const [datastatus, setDatastatus] = useState({
    confirmbx: false,
    rowid: "",
    name: "",
  });
  const [currency, setCurrency] = useState("");
  const [mddiseases, setDiseases] = useState("");
  const [mdside_effects, setSideEffects] = useState("");

  // New Medicine Add Form
  const [salt_id_list, setSalt_id_list] = useState([]);
  const [dose_id_list, setDose_id_list] = useState([]);
  const [unit_id_list, setUnit_id_list] = useState([]);
  const [inputFormulas, setinputFormulas] = useState([]);
  const [inputFields, setInputFields] = useState([]);
  const [savereset, setSavereset] = useState("");
  const [inputFormulass, setinputFormulass] = useState([]);
  const [inputFieldss, setInputFieldss] = useState([]);
  const [similarMedicines, setSimilarMedicines] = useState({
    fuzzyMatchList: [],
    inactiveList: [],
  });
  const validationTablets = Yup.object({
    medicine_shelf: Yup.string()
      .required("Medicine Shelf is required")
      .nullable(),
    medicine_name: Yup.string()
      .required("Medicine name is required")
      .nullable(),
    brand_name: Yup.string().required("Brand name is required").nullable(),
    type_id: Yup.string().required("Medicine type is required").nullable(),
    medicine_formulas: Yup.array().of(
      Yup.object().shape({
        stock: Yup.object().shape({
          total_stock_purchased: Yup.string()
            .required("Stock quantity is required")
            .nullable(),
          expiry_date: Yup.string()
            .required("Expiry date is required")
            .nullable(),
          stock_shelf: Yup.string()
            .required("Stock Rack is required")
            .nullable(),
          selling_price_per_pack: Yup.string()
            .required("Selling price per pack is required")
            .nullable(),
          price_per_pack: Yup.string()
            .required("Buying price is required")
            .nullable(),
        }),
        dose_id: Yup.string().required("Dose is required").nullable(),
        quantity: Yup.string().required("Dose quantity is required").nullable(),
      })
    ),
    salts: Yup.array().of(
      Yup.object().shape({
        salt_id: Yup.string().required("Salt name is required").nullable(),
        dose_id: Yup.string().required("Dose name is required").nullable(),
        quantity: Yup.string().required("Quantity is required").nullable(),
      })
    ),
  });

  useEffect(async () => {
    // Getting draft
    const create = new URLSearchParams(location.search).get("create");
    const name = new URLSearchParams(location.search).get("name");
    if (create && name) {
      initialvals.medicine_name = name;
    }
  }, []);

  // Initial Values for Edit form
  const initialValuas = {
    medicine_shelf: mdshelf,
    medicine_name: mdname,
    diseases: mddiseases,
    side_effects: mdside_effects,
    brand_name: brand_id,
    type_id: entity_type_id,
    medicine_formulas: inputFormulas,
    salts: inputFields,
  };

  const initialvals = {
    medicine_shelf: "",
    medicine_name: "",
    brand_name: "",
    type_id: "",
    medicine_formulas: [
      {
        units_per_strip: "",
        strips_per_pack: "",
        stock: {
          total_stock_purchased: "",
          purchased_from: "",
          purchase_date: "",
          expiry_date: "",
          selling_price_per_pack: "",
          delivered_by: "",
          stock_shelf: "",
          price_per_pack: "",
        },
        dose_id: "",
        quantity: "",
        id: "",
      },
    ],
    salts: [],
  };

  useEffect(async () => {
    const currency = await getCurrency();
    setCurrency(currency);
  }, []);

  const fetchData = async (offset) => {
    await fetch(BASE_URL + "/medicines", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "medicines",
        offset: 0,
        method: "GET",
        status_id: "active",
      }),
    })
      .then((res) => res.json())
      .then(
        (resp) => {
          let medlist = resp.data.filter((key) => {
            if (key.status_id === "active") {
              return key;
            }
          });
          setMedicine_list(medlist);
          setalength(resp.total);
          setloading(false);
        },
        (error) => {}
      );
  };

  useEffect(() => {
    fetchData(offset);
    fetchEntityTypeList();
    fetchBrandsList();
    fetchDoseList();
    fetchUnitsList();
    fetchSaltsList();
  }, [offset]);

  // Medicine Types List Function
  const fetchEntityTypeList = async () => {
    await fetch(BASE_URL + "/entity-types", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "entity_types",
        method: "GET",
        status: "active",
      }),
    })
      .then((res) => res.json())
      .then(
        (response) => {
          setEntity_type_list(response.data);
        },
        (error) => {}
      );
  };

  // Brands List
  const fetchBrandsList = async () => {
    await fetch(BASE_URL + "/brands", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "brands",
        method: "GET",
        status_id: "active",
      }),
    })
      .then((res) => res.json())
      .then(
        (response) => {
          setBrand_id_list(response.data);
        },
        (error) => {}
      );
  };

  const handleClick = (offset) => {
    setOffset(offset);
    fetchData(offset);
  };

  const handleSweetAlert = (rowId, flag, identifier) => {
    if (identifier === "handleDeleteMedicineFormula") {
      setDatastatus({ confirmbx: flag, rowId: rowId });
      setIdentifier({
        row_id: rowId,
        event: "handleDeleteMedicineFormula",
        message: "Delete",
      });
    } else if (identifier === "handleDelete") {
      setDatastatus({ confirmbx: flag, rowId: rowId });
      setIdentifier({
        row_id: rowId,
        event: "handleDelete",
        message: "Inactive",
      });
    }
  };

  function handleDelete() {
    if (datastatus.confirmbx === true) {
      setDatastatus({ confirmbx: false, rowid: "" });
      setloading(true);
      fetch(BASE_URL + "/medicine", {
        method: "DELETE",
        headers: {
          Origin: "*",
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          id: `${identifier.row_id}`,
          resource: "medicines",
          method: "DELETE",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (Object.prototype.hasOwnProperty.call(result, "error")) {
            toast.error(`${result["error"]["message"]}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            fetchData(offset);
            setDatastatus({ rowid: "" });
            toast.success(`${Notifications.deletedsuccess}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    }
  }

  function handleDeleteMedicineFormula() {
    if (datastatus.confirmbx === true) {
      setDatastatus({ confirmbx: false, rowid: "" });
      setloading(true);
      fetch(BASE_URL + "/medicine", {
        method: "DELETE",
        headers: {
          Origin: "*",
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          medicine_formula_id: `${identifier.row_id}`,
          resource: "medicines",
          method: "DELETE",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (Object.prototype.hasOwnProperty.call(result, "error")) {
            toast.error(`${result["error"]["message"]}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            fetchData(offset);
            setDatastatus({ rowid: "" });
            toast.success(`${Notifications.deletedsuccess}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    }
  }

  const cancelActivation = () => {
    setDatastatus({ confirmbx: false, rowid: "", name: "" });
  };

  // Units List
  const fetchUnitsList = async () => {
    await fetch(BASE_URL + "/units", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "units",
        method: "GET",
      }),
    })
      .then((res) => res.json())
      .then(
        (response) => {
          setUnit_id_list(response.data);
        },
        (error) => {}
      );
  };

  const getSimilarMedicines = async (search) => {
    if (search != "") {
      await fetch(BASE_URL + "/medicines", {
        method: "POST",
        headers: {
          Origin: "*",
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          resource: "medicines",
          method: "GET",
          search: search,
        }),
      })
        .then((res) => res.json())
        .then(
          (response) => {
            setSimilarMedicines({
              fuzzyMatchList: response.fuzzymatch,
              inactiveList: response.inactive_list,
            });
          },
          (error) => {}
        );
    } else {
      setSimilarMedicines({ fuzzyMatchList: [], inactiveList: [] });
    }
  };

  useEffect(() => {}, [setSimilarMedicines]);

  // Salts List
  const fetchSaltsList = async () => {
    await fetch(BASE_URL + "/salts", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "salts",
        method: "GET",
        status_id: "active",
      }),
    })
      .then((res) => res.json())
      .then(
        (response) => {
          setSalt_id_list(response.data);
        },
        (error) => {}
      );
  };

  // Dose List
  const fetchDoseList = async () => {
    await fetch(BASE_URL + "/doses", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "doses",
        method: "GET",
        status_id: "active",
      }),
    })
      .then((res) => res.json())
      .then(
        (response) => {
          setDose_id_list(response.data);
        },
        (error) => {}
      );
  };

  // Activate an inactive medicine
  const activateMedcine = (id) => {
    fetch(BASE_URL + "/admin-by", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        medicine_id: `${id}`,
        resource: "admin_by",
        method: "POST",
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (Object.prototype.hasOwnProperty.call(result, "error")) {
          toast.error(`${result["error"]["message"]}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          fetchData(offset);
          toast.success(`${Notifications.activesuccess}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  // Get Medicine with ID
  const getmedicinedata = (id) => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    fetch(BASE_URL + "/medicine", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        id: `${id}`,
        status: "active",
        resource: "medicines",
        method: "GET",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setId(result.id);
          setBrand_id(result.brand_id);
          setMdname(result.name);
          setDiseases(result.diseases);
          setSideEffects(result.side_effects);
          setMdshelf(result.medicine_shelf);
          setEntity_type_id(result.entity_type_id);
          const values = [];
          result.medicine_formulas.map((key, i) => {
            values.push({
              units_per_strip: key.units_per_strip,
              strips_per_pack: key.strips_per_pack,
              price_per_pack: key.entity_pricings.price_per_pack,
              dose_id: key.dose_id,
              quantity: key.quantity,
              id: key.id,
            });
          });
          setinputFormulas(values);
          const value = [];
          result.salts.map((key, i) => {
            value.push({
              salt_id: key.salt_id,
              dose_id: key.dose_id,
              quantity: key.quantity,
              id: key.id,
            });
          });
          setInputFields(value);
        },
        (error) => {}
      );
  };

  const search = async (value) => {
    if (value.length > 2) {
      setloading(true);
      const data = await searchMedicines(value);
      setMedicine_list(data.data);
      setalength(data.total);
      setloading(false);
    }
  };

  const getmedicinedatas = (id) => {
    $("#getmedicineslistpopup").modal("show");
    setprloading(true);
    fetch(BASE_URL + "/medicine", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        id: `${id}`,
        status: "active",
        resource: "medicines",
        method: "GET",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setId(result.id);
          setBrand_id(result.brand_id);
          setMdname(result.name);
          setDiseases(result.diseases);
          setSideEffects(result.side_effects);
          setMdshelf(result.medicine_shelf);
          setEntity_type_ids(result.entity_type_id);
          setinputFormulass(result.medicine_formulas);
          setInputFieldss(result.salts);
          setprloading(false);
        },
        (error) => {
          setprloading(false);
        }
      );
  };

  return (
    <Layout>
      {(() => {
        if (id !== "") {
          return (
            <>
              <div className="accordionformblk mb-3">
                <Formik
                  initialValues={initialValuas}
                  enableReinitialize={true}
                  onSubmit={(values, { resetForm }) => {
                    setloading(true);
                    fetch(BASE_URL + "/medicine", {
                      method: "PUT",
                      headers: {
                        Origin: "*",
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${getToken()}`,
                      },
                      body: JSON.stringify({
                        id: `${id}`,
                        name: values.medicine_name,
                        diseases: values.diseases,
                        side_effects: values.side_effects,
                        medicine_shelf: values.medicine_shelf,
                        entity_type_id: values.type_id,
                        brand_id: values.brand_name,
                        salts: values.salts,
                        medicine_formulas: values.medicine_formulas,
                        resource: "medicines",
                        method: "UPDATE",
                      }),
                    })
                      .then((res) => res.json())
                      .then((result) => {
                        if (
                          Object.prototype.hasOwnProperty.call(result, "error")
                        ) {
                          toast.error(`${result["error"]["message"]}`, {
                            position: toast.POSITION.TOP_RIGHT,
                          });
                          setloading(false);
                        } else {
                          setloading(false);
                          fetchData(offset);
                          window.location.reload(false);
                          $("#editformmed")[0].reset();
                          $("#resetForm").click();
                          toast.success(`${Notifications.updatedsuccess}`, {
                            position: toast.POSITION.TOP_RIGHT,
                          });
                        }
                      });
                  }}
                  render={({ values, handleChange, handleBlur, resetForm }) => (
                    <Form className="user" id={`editformmed`}>
                      <div className="card">
                        <div className="card-header">
                          <a
                            className="collapsed card-link d-block w-100 text-dark font-weight-600"
                            aria-expanded="true"
                            data-toggle="collapse"
                            href="#collapseTwo3"
                          >
                            <h5 className="m-0 font-weight-bold text-primary">
                              Edit Medicine
                            </h5>
                          </a>
                        </div>
                        <div
                          id="collapseTwo3"
                          className="collapse show"
                          data-target="#accordion1"
                        >
                          <div className="card-body px-2 pt-2 pb-0">
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12 pb-2">
                                <div className="row">
                                  <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                    <Field
                                      name="medicine_name"
                                      placeholder="Enter medicine name"
                                      type="text"
                                      className={
                                        "form-control-user form-control"
                                      }
                                      onBlur={handleBlur}
                                    />
                                    <label htmlFor="medicine_name">
                                      Medicine Name<sup>*</sup>
                                    </label>
                                    <ErrorMessage
                                      name="medicine_name"
                                      component="div"
                                      className="invalidbx"
                                    />
                                  </div>
                                  <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                    <Field
                                      name="diseases"
                                      placeholder="Enter diseases cured by this medicine"
                                      type="text"
                                      className={
                                        "form-control-user form-control"
                                      }
                                    />
                                    <label htmlFor="diseases">
                                      Diseases<sup>*</sup>
                                    </label>
                                    <ErrorMessage
                                      name="diseases"
                                      component="div"
                                      className="invalidbx"
                                    />
                                  </div>
                                  <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                    <Field
                                      name="side_effects"
                                      placeholder="Enter side effects cured by this medicine"
                                      type="text"
                                      className={
                                        "form-control-user form-control"
                                      }
                                    />
                                    <label htmlFor="side_effects">
                                      Side effects<sup>*</sup>
                                    </label>
                                    <ErrorMessage
                                      name="side_effects"
                                      component="div"
                                      className="invalidbx"
                                    />
                                  </div>
                                  <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                    <Field
                                      component="select"
                                      name="type_id"
                                      id="type_id"
                                      onChange={(e) => {
                                        handleChange(e);
                                        setEntity_type_id(
                                          e.currentTarget.value
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      className={
                                        "form-control-user form-control"
                                      }
                                    >
                                      <option value="" label="Select Type" />
                                      {entity_type_list.map((stock, i) => (
                                        <option
                                          key={i}
                                          value={stock.id}
                                          label={stock.name}
                                        />
                                      ))}
                                    </Field>
                                    <label htmlFor="type_id">
                                      Medicine Type<sup>*</sup>
                                    </label>
                                    <ErrorMessage
                                      name="type_id"
                                      component="div"
                                      className="invalidbx"
                                    />
                                  </div>
                                  <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                    <Field
                                      component="select"
                                      name="brand_name"
                                      id="brand_name"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className={
                                        "form-control-user form-control"
                                      }
                                    >
                                      <option value="" label="Select Brand" />
                                      {brand_id_list.map((stock, i) => (
                                        <option
                                          key={i}
                                          value={stock.id}
                                          label={stock.name}
                                        />
                                      ))}
                                    </Field>
                                    <label htmlFor="brand_name">
                                      Brands<sup>*</sup>
                                    </label>
                                    <ErrorMessage
                                      name="brand_name"
                                      component="div"
                                      className="invalidbx"
                                    />
                                  </div>
                                  <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                    <Field
                                      name="medicine_shelf"
                                      placeholder="Medicine Rack"
                                      type="text"
                                      className={
                                        "form-control-user form-control"
                                      }
                                      onBlur={handleBlur}
                                    />
                                    <label htmlFor="medicine_shelf">
                                      Medicine Rack<sup>*</sup>
                                    </label>
                                    <ErrorMessage
                                      name="medicine_shelf"
                                      component="div"
                                      className="invalidbx"
                                    />
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="card-header">
                                    <a
                                      className="collapsed card-link d-block w-100 text-dark"
                                      aria-expanded="true"
                                      data-toggle="collapse"
                                      href="#collapseTwo1"
                                    >
                                      Doses Information
                                    </a>
                                  </div>
                                  <div
                                    id="collapseTwo1"
                                    className="collapse show"
                                    data-target="#accordion"
                                  >
                                    <div className="card-body  p-0" id="child1">
                                      <div className="px-3 pt-1 pb-3">
                                        <FieldArray
                                          name="medicine_formulas"
                                          render={(arrayHelpers) => {
                                            const medicine_formulas =
                                              values.medicine_formulas;
                                            return (
                                              <div>
                                                {medicine_formulas &&
                                                medicine_formulas.length > 0
                                                  ? medicine_formulas.map(
                                                      (
                                                        medicine_formula,
                                                        index
                                                      ) => (
                                                        <div
                                                          key={
                                                            medicine_formula.id
                                                          }
                                                        >
                                                          <div className="row formula_sec">
                                                            {(() => {
                                                              return (
                                                                <>
                                                                  <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                    <Field
                                                                      name={`medicine_formulas.${index}.strips_per_pack`}
                                                                      autoComplete="off"
                                                                      type="number"
                                                                      placeholder="Enter strips per pack"
                                                                      className={`form-control-user form-control`}
                                                                    />
                                                                    <label htmlFor="strips_per_pack">
                                                                      Strips Per
                                                                      Pack for
                                                                      Tablets
                                                                      <sup>
                                                                        *
                                                                      </sup>
                                                                    </label>
                                                                    <ErrorMessage
                                                                      name={`medicine_formulas.${index}.strips_per_pack`}
                                                                      component="div"
                                                                      className="invalidbx"
                                                                    />
                                                                  </div>
                                                                  <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                    <Field
                                                                      name={`medicine_formulas.${index}.units_per_strip`}
                                                                      autoComplete="off"
                                                                      type="number"
                                                                      placeholder="Enter units per strip"
                                                                      className={`form-control-user form-control`}
                                                                    />
                                                                    <label htmlFor="units_per_strip">
                                                                      Units Per
                                                                      Strip for
                                                                      Tablets
                                                                      <sup>
                                                                        *
                                                                      </sup>
                                                                    </label>
                                                                    <ErrorMessage
                                                                      name={`medicine_formulas.${index}.units_per_strip`}
                                                                      component="div"
                                                                      className="invalidbx"
                                                                    />
                                                                  </div>
                                                                </>
                                                              );
                                                            })()}
                                                            <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                              <Field
                                                                name={`medicine_formulas.${index}.stock.price_per_pack`}
                                                                autoComplete="off"
                                                                type="number"
                                                                placeholder="Enter buying price per pack"
                                                                className={`form-control-user form-control`}
                                                              />
                                                              <label htmlFor="price_per_pack">
                                                                Buying Price Per
                                                                Pack<sup>*</sup>
                                                              </label>
                                                              <ErrorMessage
                                                                name={`medicine_formulas.${index}.stock.price_per_pack`}
                                                                component="div"
                                                                className="invalidbx"
                                                              />
                                                            </div>
                                                            {(() => {
                                                              if (
                                                                medicine_formula.id !==
                                                                ""
                                                              ) {
                                                                return "";
                                                              } else {
                                                                return (
                                                                  <>
                                                                    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                      <Field
                                                                        name={`medicine_formulas.${index}.stock.selling_price_per_pack`}
                                                                        autoComplete="off"
                                                                        type="number"
                                                                        placeholder="Enter selling price per pack"
                                                                        className={`form-control-user form-control`}
                                                                      />
                                                                      <label htmlFor="selling_price_per_pack">
                                                                        Selling
                                                                        Price
                                                                        Per Pack
                                                                        <sup>
                                                                          *
                                                                        </sup>
                                                                      </label>
                                                                      <ErrorMessage
                                                                        name={`medicine_formulas.${index}.stock.selling_price_per_pack`}
                                                                        component="div"
                                                                        className="invalidbx"
                                                                      />
                                                                    </div>
                                                                  </>
                                                                );
                                                              }
                                                            })()}
                                                            <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                              <Field
                                                                component="select"
                                                                name={`medicine_formulas.${index}.dose_id`}
                                                                id="dose_id"
                                                                onChange={
                                                                  handleChange
                                                                }
                                                                onBlur={
                                                                  handleBlur
                                                                }
                                                                className={
                                                                  "form-control-user form-control"
                                                                }
                                                              >
                                                                <option
                                                                  value=""
                                                                  label="Select Dose"
                                                                />
                                                                {dose_id_list.map(
                                                                  (
                                                                    stock,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      key={
                                                                        index
                                                                      }
                                                                      value={
                                                                        stock.id
                                                                      }
                                                                      label={
                                                                        stock.name
                                                                      }
                                                                    />
                                                                  )
                                                                )}
                                                              </Field>
                                                              <label htmlFor="dose_id">
                                                                Dose name
                                                                <sup>*</sup>
                                                              </label>
                                                              <ErrorMessage
                                                                name={`medicine_formulas.${index}.dose_id`}
                                                                component="div"
                                                                className="invalidbx"
                                                              />
                                                            </div>
                                                            <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                              <Field
                                                                name={`medicine_formulas.${index}.quantity`}
                                                                autoComplete="off"
                                                                type="number"
                                                                placeholder="Enter quantity"
                                                                className={`form-control-user form-control`}
                                                              />
                                                              <label htmlFor="quantity">
                                                                Dose Quantity
                                                                <sup>*</sup>
                                                              </label>
                                                              <ErrorMessage
                                                                name={`medicine_formulas.${index}.quantity`}
                                                                component="div"
                                                                className="invalidbx"
                                                              />
                                                            </div>
                                                            {(() => {
                                                              if (
                                                                medicine_formula.id !==
                                                                ""
                                                              ) {
                                                                return "";
                                                              } else {
                                                                return (
                                                                  <>
                                                                    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                      <Field
                                                                        name={`medicine_formulas.${index}.stock.total_stock_purchased`}
                                                                        autoComplete="off"
                                                                        type="number"
                                                                        placeholder="Enter stock quantity"
                                                                        className={`form-control-user form-control`}
                                                                      />
                                                                      <label htmlFor="total_stock_purchased">
                                                                        Stock
                                                                        Quantity
                                                                        <sup>
                                                                          *
                                                                        </sup>
                                                                      </label>
                                                                      <ErrorMessage
                                                                        name={`medicine_formulas.${index}.stock.total_stock_purchased`}
                                                                        component="div"
                                                                        className="invalidbx"
                                                                      />
                                                                    </div>
                                                                    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                      <Field
                                                                        name={`medicine_formulas.${index}.stock.expiry_date`}
                                                                        autoComplete="off"
                                                                        type="date"
                                                                        placeholder="Enter expiry date"
                                                                        className={`form-control-user form-control`}
                                                                      />
                                                                      <label htmlFor="expiry_date">
                                                                        Expiry
                                                                        Date
                                                                        <sup>
                                                                          *
                                                                        </sup>
                                                                      </label>
                                                                      <ErrorMessage
                                                                        name={`medicine_formulas.${index}.stock.expiry_date`}
                                                                        component="div"
                                                                        className="invalidbx"
                                                                      />
                                                                    </div>
                                                                    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                      <Field
                                                                        name={`medicine_formulas.${index}.stock.purchased_from`}
                                                                        autoComplete="off"
                                                                        type="text"
                                                                        placeholder="Enter purchased from"
                                                                        className={
                                                                          "form-control-user form-control"
                                                                        }
                                                                      />
                                                                      <label htmlFor="purchased_from">
                                                                        Purchased
                                                                        From
                                                                      </label>
                                                                    </div>
                                                                    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                      <Field
                                                                        name={`medicine_formulas.${index}.stock.delivered_by`}
                                                                        autoComplete="off"
                                                                        type="text"
                                                                        className={
                                                                          "form-control-user form-control"
                                                                        }
                                                                        placeholder="Enter delivered by"
                                                                      />
                                                                      <label htmlFor="delivered_by">
                                                                        Delivered
                                                                        By
                                                                      </label>
                                                                    </div>
                                                                    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                      <Field
                                                                        name={`medicine_formulas.${index}.stock.purchase_date`}
                                                                        autoComplete="off"
                                                                        type="date"
                                                                        placeholder="Enter purchased date"
                                                                        className={
                                                                          "form-control-user form-control"
                                                                        }
                                                                      />
                                                                      <label htmlFor="purchase_date">
                                                                        Purchased
                                                                        Date
                                                                      </label>
                                                                    </div>
                                                                    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                      <Field
                                                                        name={`medicine_formulas.${index}.stock.stock_shelf`}
                                                                        autoComplete="off"
                                                                        type="text"
                                                                        placeholder="Stock Rack"
                                                                        className={
                                                                          "form-control-user form-control"
                                                                        }
                                                                      />
                                                                      <label htmlFor="stock_shelf">
                                                                        Stock
                                                                        Rack
                                                                      </label>
                                                                    </div>
                                                                  </>
                                                                );
                                                              }
                                                            })()}
                                                            {(() => {
                                                              if (
                                                                medicine_formula.id !==
                                                                ""
                                                              ) {
                                                                return (
                                                                  <span
                                                                    className="close_btn"
                                                                    onClick={() => {
                                                                      handleSweetAlert(
                                                                        medicine_formula.id,
                                                                        true,
                                                                        "handleDeleteMedicineFormula"
                                                                      );
                                                                      arrayHelpers.remove(
                                                                        index
                                                                      );
                                                                    }}
                                                                  >
                                                                    <i className="fas fa-times-circle text-danger"></i>
                                                                  </span>
                                                                );
                                                              } else {
                                                                return (
                                                                  <span
                                                                    className="close_btn"
                                                                    onClick={() => {
                                                                      arrayHelpers.remove(
                                                                        index
                                                                      );
                                                                    }}
                                                                  >
                                                                    <i className="fas fa-times-circle text-danger"></i>
                                                                  </span>
                                                                );
                                                              }
                                                            })()}
                                                          </div>
                                                        </div>
                                                      )
                                                    )
                                                  : null}
                                                <div className="row">
                                                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end text-right">
                                                    <button
                                                      className="btn btn-secondary bxd btn-sm"
                                                      type="button"
                                                      onClick={() =>
                                                        arrayHelpers.push({
                                                          units_per_strip: "",
                                                          strips_per_pack: "",
                                                          stock: {
                                                            total_stock_purchased:
                                                              "",
                                                            purchased_from: "",
                                                            purchase_date: "",
                                                            expiry_date: "",
                                                            selling_price_per_pack:
                                                              "",
                                                            delivered_by: "",
                                                            stock_shelf: "",
                                                            price_per_pack: "",
                                                          },
                                                          dose_id: "",
                                                          quantity: "",
                                                          id: "",
                                                        })
                                                      }
                                                    >
                                                      <i className="fas fa-plus"></i>{" "}
                                                      Add
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card mt-2">
                                  <div className="card-header">
                                    <a
                                      className="collapsed card-link d-block w-100 text-dark font-weight-600"
                                      aria-expanded="true"
                                      data-toggle="collapse"
                                      href="#collapseOneBs1"
                                    >
                                      Salts Information
                                    </a>
                                  </div>
                                  <div
                                    className="card-body p-0 collapse show"
                                    data-target={`#accordion`}
                                    id={`collapseOneBs1`}
                                  >
                                    <div className="px-3 pt-1 pb-3">
                                      <FieldArray
                                        name="salts"
                                        render={(arrayHelpers) => {
                                          const salts = values.salts;
                                          return (
                                            <div>
                                              {salts && salts.length > 0
                                                ? salts.map((salt, i) => (
                                                    <div
                                                      key={i}
                                                      className="row formula_sec"
                                                    >
                                                      <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                                        <Field
                                                          component="select"
                                                          name={`salts.${i}.salt_id`}
                                                          id="salt_id"
                                                          onChange={
                                                            handleChange
                                                          }
                                                          onBlur={handleBlur}
                                                          className={
                                                            "form-control-user form-control"
                                                          }
                                                        >
                                                          <option
                                                            value=""
                                                            label="Select Salt"
                                                          />
                                                          {salt_id_list.map(
                                                            (stock, index) => (
                                                              <option
                                                                key={index}
                                                                value={stock.id}
                                                                label={
                                                                  stock.name
                                                                }
                                                              />
                                                            )
                                                          )}
                                                        </Field>
                                                        <label htmlFor="saltid">
                                                          Salt name<sup>*</sup>
                                                        </label>
                                                        <ErrorMessage
                                                          name={`salts.${i}.salt_id`}
                                                          component="div"
                                                          className="invalidbx"
                                                        />
                                                      </div>
                                                      <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                                        <Field
                                                          component="select"
                                                          name={`salts.${i}.dose_id`}
                                                          id="dose_id"
                                                          onChange={
                                                            handleChange
                                                          }
                                                          onBlur={handleBlur}
                                                          className={
                                                            "form-control-user form-control"
                                                          }
                                                        >
                                                          <option
                                                            value=""
                                                            label="Select Dose"
                                                          />
                                                          {dose_id_list.map(
                                                            (stock, index) => (
                                                              <option
                                                                key={index}
                                                                value={stock.id}
                                                                label={
                                                                  stock.name
                                                                }
                                                              />
                                                            )
                                                          )}
                                                        </Field>
                                                        <label htmlFor="dose_id">
                                                          Dose name<sup>*</sup>
                                                        </label>
                                                        <ErrorMessage
                                                          name={`salts.${i}.dose_id`}
                                                          component="div"
                                                          className="invalidbx"
                                                        />
                                                      </div>
                                                      <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                                        <Field
                                                          type="number"
                                                          placeholder="Enter quantity"
                                                          className="form-control-user form-control"
                                                          id={`salts.${i}.quantity`}
                                                          name={`salts.${i}.quantity`}
                                                        />
                                                        <label htmlFor="quantity">
                                                          Dose Quantity
                                                          <sup>*</sup>
                                                        </label>
                                                        <ErrorMessage
                                                          name={`salts.${i}.quantity`}
                                                          component="div"
                                                          className="invalidbx"
                                                        />
                                                      </div>
                                                      <span
                                                        className="close_btn"
                                                        onClick={() =>
                                                          arrayHelpers.remove(i)
                                                        }
                                                      >
                                                        <i className="fas fa-times-circle text-danger"></i>
                                                      </span>
                                                    </div>
                                                  ))
                                                : null}
                                              <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end text-right">
                                                  <button
                                                    className="btn btn-secondary bxd btn-sm"
                                                    type="button"
                                                    onClick={() =>
                                                      arrayHelpers.push({
                                                        salt_id: "",
                                                        dose_id: "",
                                                        quantity: "",
                                                        id: "",
                                                      })
                                                    }
                                                  >
                                                    <i className="fas fa-plus"></i>{" "}
                                                    Add
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group mt-2 text-right">
                                  <button
                                    type="submit"
                                    className="btn btn-success btn-sm mr-2"
                                  >
                                    {" "}
                                    Update
                                  </button>
                                  <button
                                    type="reset"
                                    id={`resetForm`}
                                    className="btn btn-secondary btn-sm"
                                  >
                                    {" "}
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </>
          );
        } else {
          return (
            <>
              {1 < getPermissions("medicines") ? (
                <div className="accordionformblk mb-3">
                  <Formik
                    initialValues={initialvals}
                    validationSchema={validationTablets}
                    onSubmit={(values, { resetForm }) => {
                      setloading(true);
                      fetch(BASE_URL + "/medicine", {
                        method: "POST",
                        headers: {
                          Origin: "*",
                          "Content-Type": "application/json",
                          Accept: "application/json",
                          Authorization: `Bearer ${getToken()}`,
                        },
                        body: JSON.stringify({
                          name: values.medicine_name,
                          diseases: values.diseases,
                          side_effects: values.side_effects,
                          medicine_shelf: values.medicine_shelf,
                          entity_type_id: values.type_id,
                          brand_id: values.brand_name,
                          salts: values.salts,
                          medicine_formulas: values.medicine_formulas,
                          resource: "medicines",
                          method: "POST",
                        }),
                      })
                        .then((res) => res.json())
                        .then((result) => {
                          if (savereset === "addreset") {
                            resetForm();
                            setSavereset("");
                          }
                          if (
                            Object.prototype.hasOwnProperty.call(
                              result,
                              "error"
                            )
                          ) {
                            toast.error(`${result["error"]["message"]}`, {
                              position: toast.POSITION.TOP_RIGHT,
                            });
                          } else {
                            setloading(false);
                            fetchData(offset);
                            toast.success(`${Notifications.addedsuccess}`, {
                              position: toast.POSITION.TOP_RIGHT,
                            });
                          }
                        });
                    }}
                    render={({
                      values,
                      handleChange,
                      handleBlur,
                      resetForm,
                    }) => (
                      <Form className="user">
                        <div className="card">
                          <div className="card-header">
                            <a
                              className="collapsed card-link d-block w-100 text-dark font-weight-600"
                              aria-expanded="true"
                              data-toggle="collapse"
                              href="#collapseTwo4"
                            >
                              <h5 className="m-0 font-weight-bold text-primary">
                                Add Medicine
                              </h5>
                            </a>
                          </div>
                          <div
                            id="collapseTwo4"
                            className="collapse show"
                            data-target="#accordion1"
                          >
                            <div className="card-body px-2 pt-2 pb-0">
                              <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 pb-2">
                                  <div className="row">
                                    <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                      <Field
                                        name="medicine_name"
                                        placeholder="Enter medicine name"
                                        type="text"
                                        className={
                                          "form-control-user form-control"
                                        }
                                        onKeyUp={(e) => {
                                          getSimilarMedicines(e.target.value);
                                        }}
                                      />
                                      <label htmlFor="medicine_name">
                                        Medicine Name<sup>*</sup>
                                      </label>
                                      <ErrorMessage
                                        name="medicine_name"
                                        component="div"
                                        className="invalidbx"
                                      />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                      <Field
                                        name="diseases"
                                        placeholder="Enter diseases cured by this medicine"
                                        type="text"
                                        className={
                                          "form-control-user form-control"
                                        }
                                      />
                                      <label htmlFor="diseases">
                                        Diseases<sup>*</sup>
                                      </label>
                                      <ErrorMessage
                                        name="diseases"
                                        component="div"
                                        className="invalidbx"
                                      />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                      <Field
                                        name="side_effects"
                                        placeholder="Enter side effects cured by this medicine"
                                        type="text"
                                        className={
                                          "form-control-user form-control"
                                        }
                                      />
                                      <label htmlFor="side_effects">
                                        Side effects<sup>*</sup>
                                      </label>
                                      <ErrorMessage
                                        name="side_effects"
                                        component="div"
                                        className="invalidbx"
                                      />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                      <select
                                        name="type_id"
                                        id="type_id"
                                        value={values.type_id}
                                        onChange={(e) => {
                                          handleChange(e);
                                          setEntity_type_id(
                                            e.currentTarget.value
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        className={
                                          "form-control-user form-control"
                                        }
                                      >
                                        <option value="" label="Select Type" />
                                        {entity_type_list.map((stock, i) => (
                                          <option
                                            key={i}
                                            value={stock.id}
                                            label={stock.name}
                                          />
                                        ))}
                                      </select>
                                      <label htmlFor="type_id">
                                        Medicine Type<sup>*</sup>
                                      </label>
                                      <ErrorMessage
                                        name="type_id"
                                        component="div"
                                        className="invalidbx"
                                      />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                      <select
                                        name="brand_name"
                                        id="brand_name"
                                        value={values.brand_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={
                                          "form-control-user form-control"
                                        }
                                      >
                                        <option value="" label="Select Brand" />
                                        {brand_id_list.map((stock, i) => (
                                          <option
                                            key={i}
                                            value={stock.id}
                                            label={stock.name}
                                          />
                                        ))}
                                      </select>
                                      <label htmlFor="brand_name">
                                        Brands<sup>*</sup>
                                      </label>
                                      <ErrorMessage
                                        name="brand_name"
                                        component="div"
                                        className="invalidbx"
                                      />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                      <Field
                                        name="medicine_shelf"
                                        placeholder="Medicine Rack"
                                        type="text"
                                        className={
                                          "form-control-user form-control"
                                        }
                                      />
                                      <label htmlFor="medicine_name">
                                        Medicine Rack<sup>*</sup>
                                      </label>
                                      <ErrorMessage
                                        name="medicine_shelf"
                                        component="div"
                                        className="invalidbx"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12 col-sm-12 pb-2">
                                    <div>
                                      <b>
                                        {similarMedicines.fuzzyMatchList
                                          .length > 0
                                          ? "Similar medicines added"
                                          : ""}
                                      </b>
                                      {similarMedicines.fuzzyMatchList.length >
                                        0 &&
                                        similarMedicines.fuzzyMatchList.map(
                                          (x) => {
                                            return (
                                              <>
                                                <p>
                                                  {x.name} -{" "}
                                                  {x.entity_type_id
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    x.entity_type_id.slice(1)}
                                                  <OverlayTrigger
                                                    overlay={
                                                      <Tooltip>Edit</Tooltip>
                                                    }
                                                  >
                                                    <a
                                                      href
                                                      className="cp"
                                                      onClick={() =>
                                                        getmedicinedata(x.id)
                                                      }
                                                    >
                                                      {" "}
                                                      <Icon icon="edit2" />
                                                    </a>
                                                  </OverlayTrigger>
                                                </p>
                                              </>
                                            );
                                          }
                                        )}
                                    </div>
                                    <div>
                                      <b>
                                        {similarMedicines.inactiveList.length >
                                        0
                                          ? "Similar inactive medicines"
                                          : ""}
                                      </b>
                                      {similarMedicines.inactiveList.length >
                                        0 &&
                                        similarMedicines.inactiveList.map(
                                          (x) => {
                                            return (
                                              <>
                                                <p>
                                                  {x.name} -{" "}
                                                  {x.entity_type_id
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    x.entity_type_id.slice(1)}
                                                  <OverlayTrigger
                                                    overlay={
                                                      <Tooltip>
                                                        Activate
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <a
                                                      href
                                                      className="cp"
                                                      onClick={() =>
                                                        activateMedcine(x.id)
                                                      }
                                                    >
                                                      {" "}
                                                      <i className="fas fa-redo"></i>
                                                    </a>
                                                  </OverlayTrigger>
                                                </p>
                                              </>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="card-header">
                                      <a
                                        className="collapsed card-link d-block w-100 text-dark"
                                        aria-expanded="true"
                                        data-toggle="collapse"
                                        href="#collapseTwo1"
                                      >
                                        Doses Information
                                      </a>
                                    </div>
                                    <div
                                      id="collapseTwo1"
                                      className="collapse show"
                                      data-target="#accordion"
                                    >
                                      <div
                                        className="card-body  p-0"
                                        id="child1"
                                      >
                                        <div className="px-3 pt-1 pb-3">
                                          <i>
                                            All prices represented here are in{" "}
                                            {currency}.
                                          </i>
                                          <FieldArray
                                            name="medicine_formulas"
                                            render={(arrayHelpers) => {
                                              const medicine_formulas =
                                                values.medicine_formulas;
                                              return (
                                                <div>
                                                  {medicine_formulas &&
                                                  medicine_formulas.length > 0
                                                    ? medicine_formulas.map(
                                                        (
                                                          medicine_formula,
                                                          index
                                                        ) => (
                                                          <div key={index}>
                                                            <div className="row formula_sec">
                                                              {(() => {
                                                                return (
                                                                  <>
                                                                    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                      <Field
                                                                        name={`medicine_formulas.${index}.strips_per_pack`}
                                                                        autoComplete="off"
                                                                        type="number"
                                                                        placeholder="Enter strips per pack"
                                                                        className={`form-control-user form-control`}
                                                                      />
                                                                      <label htmlFor="strips_per_pack">
                                                                        Strips
                                                                        Per Pack
                                                                        <sup>
                                                                          *
                                                                        </sup>
                                                                      </label>
                                                                      <ErrorMessage
                                                                        name={`medicine_formulas.${index}.strips_per_pack`}
                                                                        component="div"
                                                                        className="invalidbx"
                                                                      />
                                                                    </div>
                                                                    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                      <Field
                                                                        name={`medicine_formulas.${index}.units_per_strip`}
                                                                        autoComplete="off"
                                                                        type="number"
                                                                        placeholder="Enter units per strip"
                                                                        className={`form-control-user form-control`}
                                                                      />
                                                                      <label htmlFor="units_per_strip">
                                                                        Units
                                                                        Per
                                                                        Strip
                                                                        <sup>
                                                                          *
                                                                        </sup>
                                                                      </label>
                                                                      <ErrorMessage
                                                                        name={`medicine_formulas.${index}.units_per_strip`}
                                                                        component="div"
                                                                        className="invalidbx"
                                                                      />
                                                                    </div>
                                                                  </>
                                                                );
                                                              })()}
                                                              <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                <Field
                                                                  name={`medicine_formulas.${index}.stock.price_per_pack`}
                                                                  autoComplete="off"
                                                                  type="number"
                                                                  placeholder="Enter buying price per pack"
                                                                  className={`form-control-user form-control`}
                                                                />
                                                                <label htmlFor="price_per_pack">
                                                                  Buying Price
                                                                  Per Pack
                                                                  <sup>*</sup>
                                                                </label>
                                                                <ErrorMessage
                                                                  name={`medicine_formulas.${index}.stock.price_per_pack`}
                                                                  component="div"
                                                                  className="invalidbx"
                                                                />
                                                              </div>
                                                              <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                <Field
                                                                  name={`medicine_formulas.${index}.stock.selling_price_per_pack`}
                                                                  autoComplete="off"
                                                                  type="number"
                                                                  placeholder="Enter selling price per pack"
                                                                  className={`form-control-user form-control`}
                                                                />
                                                                <label htmlFor="selling_price_per_pack">
                                                                  Selling Price
                                                                  Per Pack
                                                                  <sup>*</sup>
                                                                </label>
                                                                <ErrorMessage
                                                                  name={`medicine_formulas.${index}.stock.selling_price_per_pack`}
                                                                  component="div"
                                                                  className="invalidbx"
                                                                />
                                                              </div>
                                                              <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                <select
                                                                  name={`medicine_formulas.${index}.dose_id`}
                                                                  id="dose_id"
                                                                  onChange={
                                                                    handleChange
                                                                  }
                                                                  onBlur={
                                                                    handleBlur
                                                                  }
                                                                  className={
                                                                    "form-control-user form-control"
                                                                  }
                                                                >
                                                                  <option
                                                                    value=""
                                                                    label="Select Dose"
                                                                  />
                                                                  {dose_id_list.map(
                                                                    (
                                                                      stock,
                                                                      index
                                                                    ) => (
                                                                      <option
                                                                        key={
                                                                          index
                                                                        }
                                                                        value={
                                                                          stock.id
                                                                        }
                                                                        label={
                                                                          stock.name
                                                                        }
                                                                      />
                                                                    )
                                                                  )}
                                                                </select>
                                                                <label htmlFor="dose_id">
                                                                  Dose name
                                                                  <sup>*</sup>
                                                                </label>
                                                                <ErrorMessage
                                                                  name={`medicine_formulas.${index}.dose_id`}
                                                                  component="div"
                                                                  className="invalidbx"
                                                                />
                                                              </div>
                                                              <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                <Field
                                                                  name={`medicine_formulas.${index}.quantity`}
                                                                  autoComplete="off"
                                                                  type="number"
                                                                  placeholder="Enter quantity"
                                                                  className={`form-control-user form-control`}
                                                                />
                                                                <label htmlFor="quantity">
                                                                  Dose Quantity
                                                                  <sup>*</sup>
                                                                </label>
                                                                <ErrorMessage
                                                                  name={`medicine_formulas.${index}.quantity`}
                                                                  component="div"
                                                                  className="invalidbx"
                                                                />
                                                              </div>
                                                              <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                <Field
                                                                  name={`medicine_formulas.${index}.stock.total_stock_purchased`}
                                                                  autoComplete="off"
                                                                  type="number"
                                                                  placeholder="Enter stock quantity"
                                                                  className={`form-control-user form-control`}
                                                                />
                                                                <label htmlFor="total_stock_purchased">
                                                                  Stock Quantity
                                                                  <sup>*</sup>
                                                                </label>
                                                                <ErrorMessage
                                                                  name={`medicine_formulas.${index}.stock.total_stock_purchased`}
                                                                  component="div"
                                                                  className="invalidbx"
                                                                />
                                                              </div>
                                                              <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                <Field
                                                                  name={`medicine_formulas.${index}.stock.expiry_date`}
                                                                  autoComplete="off"
                                                                  type="date"
                                                                  placeholder="Enter expiry date"
                                                                  className={`form-control-user form-control`}
                                                                />
                                                                <label htmlFor="expiry_date">
                                                                  Expiry Date
                                                                  <sup>*</sup>
                                                                </label>

                                                                <ErrorMessage
                                                                  name={`medicine_formulas.${index}.stock.expiry_date`}
                                                                  component="div"
                                                                  className="invalidbx"
                                                                />
                                                              </div>
                                                              <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                <Field
                                                                  name={`medicine_formulas.${index}.stock.stock_shelf`}
                                                                  autoComplete="off"
                                                                  type="text"
                                                                  placeholder="Stock Rack"
                                                                  className={
                                                                    "form-control-user form-control"
                                                                  }
                                                                />
                                                                <label htmlFor="stock_shelf">
                                                                  Stock Rack
                                                                  <sup>*</sup>
                                                                </label>
                                                                <ErrorMessage
                                                                  name={`medicine_formulas.${index}.stock.stock_shelf`}
                                                                  component="div"
                                                                  className="invalidbx"
                                                                />
                                                              </div>
                                                              <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                <Field
                                                                  name={`medicine_formulas.${index}.stock.purchased_from`}
                                                                  autoComplete="off"
                                                                  type="text"
                                                                  placeholder="Enter purchased from"
                                                                  className={
                                                                    "form-control-user form-control"
                                                                  }
                                                                />
                                                                <label htmlFor="purchased_from">
                                                                  Purchased From
                                                                </label>
                                                              </div>
                                                              <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                <Field
                                                                  name={`medicine_formulas.${index}.stock.delivered_by`}
                                                                  autoComplete="off"
                                                                  type="text"
                                                                  className={
                                                                    "form-control-user form-control"
                                                                  }
                                                                  placeholder="Enter delivered by"
                                                                />
                                                                <label htmlFor="delivered_by">
                                                                  Delivered By
                                                                </label>
                                                              </div>
                                                              <div className="form-group col-lg-3 col-md-6 col-sm-12 col-12 has-float-label">
                                                                <Field
                                                                  name={`medicine_formulas.${index}.stock.purchase_date`}
                                                                  autoComplete="off"
                                                                  type="date"
                                                                  placeholder="Enter purchased date"
                                                                  className={
                                                                    "form-control-user form-control"
                                                                  }
                                                                />
                                                                <label htmlFor="purchase_date">
                                                                  Purchased Date
                                                                </label>
                                                              </div>
                                                              {(() => {
                                                                if (index > 0) {
                                                                  return (
                                                                    <span
                                                                      className="close_btn"
                                                                      onClick={() =>
                                                                        arrayHelpers.remove(
                                                                          index
                                                                        )
                                                                      }
                                                                    >
                                                                      <i className="fas fa-times-circle text-danger"></i>
                                                                    </span>
                                                                  );
                                                                } else {
                                                                  return (
                                                                    <div></div>
                                                                  );
                                                                }
                                                              })()}
                                                            </div>
                                                          </div>
                                                        )
                                                      )
                                                    : null}
                                                  <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end text-right">
                                                      <button
                                                        className="btn btn-secondary bxd btn-sm"
                                                        type="button"
                                                        onClick={() =>
                                                          arrayHelpers.push({
                                                            units_per_strip: "",
                                                            strips_per_pack: "",
                                                            stock: {
                                                              total_stock_purchased:
                                                                "",
                                                              purchased_from:
                                                                "",
                                                              purchase_date: "",
                                                              expiry_date: "",
                                                              selling_price_per_pack:
                                                                "",
                                                              delivered_by: "",
                                                              stock_shelf: "",
                                                              price_per_pack:
                                                                "",
                                                            },
                                                            dose_id: "",
                                                            quantity: "",
                                                            id: "",
                                                          })
                                                        }
                                                      >
                                                        <i className="fas fa-plus"></i>{" "}
                                                        Add
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <a
                                        className="collapsed card-link d-block w-100 text-dark font-weight-600"
                                        aria-expanded="true"
                                        data-toggle="collapse"
                                        href="#collapseOneBs1"
                                      >
                                        Salts Information
                                      </a>
                                    </div>
                                    <div
                                      className="card-body p-0 collapse show"
                                      data-target={`#accordion`}
                                      id={`collapseOneBs1`}
                                    >
                                      <div className="px-3 pt-1 pb-3">
                                        <FieldArray
                                          name="salts"
                                          render={(arrayHelpers) => {
                                            const salts = values.salts;
                                            return (
                                              <div>
                                                {salts && salts.length > 0
                                                  ? salts.map((salt, i) => (
                                                      <div
                                                        key={i}
                                                        className="row formula_sec"
                                                      >
                                                        <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                                          <select
                                                            name={`salts.${i}.salt_id`}
                                                            id="salt_id"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            className={
                                                              "form-control-user form-control"
                                                            }
                                                          >
                                                            <option
                                                              value=""
                                                              label="Select Salt"
                                                            />
                                                            {salt_id_list.map(
                                                              (
                                                                stock,
                                                                index
                                                              ) => (
                                                                <option
                                                                  key={index}
                                                                  value={
                                                                    stock.id
                                                                  }
                                                                  label={
                                                                    stock.name
                                                                  }
                                                                />
                                                              )
                                                            )}
                                                          </select>
                                                          <label htmlFor="saltid">
                                                            Salt name
                                                            <sup>*</sup>
                                                          </label>
                                                          <ErrorMessage
                                                            name={`salts.${i}.salt_id`}
                                                            component="div"
                                                            className="invalidbx"
                                                          />
                                                        </div>
                                                        <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                                          <select
                                                            name={`salts.${i}.dose_id`}
                                                            id="dose_id"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            className={
                                                              "form-control-user form-control"
                                                            }
                                                          >
                                                            <option
                                                              value=""
                                                              label="Select Dose"
                                                            />
                                                            {dose_id_list.map(
                                                              (
                                                                stock,
                                                                index
                                                              ) => (
                                                                <option
                                                                  key={index}
                                                                  value={
                                                                    stock.id
                                                                  }
                                                                  label={
                                                                    stock.name
                                                                  }
                                                                />
                                                              )
                                                            )}
                                                          </select>
                                                          <label htmlFor="dose_id">
                                                            Dose name
                                                            <sup>*</sup>
                                                          </label>
                                                          <ErrorMessage
                                                            name={`salts.${i}.dose_id`}
                                                            component="div"
                                                            className="invalidbx"
                                                          />
                                                        </div>
                                                        <div className="form-group col-lg-4 col-md-6 col-sm-12 col-12 has-float-label">
                                                          <Field
                                                            type="number"
                                                            placeholder="Enter quantity"
                                                            className="form-control-user form-control"
                                                            id={`salts.${i}.quantity`}
                                                            name={`salts.${i}.quantity`}
                                                          />
                                                          <label htmlFor="quantity">
                                                            Dose Quantity
                                                            <sup>*</sup>
                                                          </label>
                                                          <ErrorMessage
                                                            name={`salts.${i}.quantity`}
                                                            component="div"
                                                            className="invalidbx"
                                                          />
                                                        </div>
                                                        <span
                                                          className="close_btn"
                                                          onClick={() =>
                                                            arrayHelpers.remove(
                                                              i
                                                            )
                                                          }
                                                        >
                                                          <i className="fas fa-times-circle text-danger"></i>
                                                        </span>
                                                      </div>
                                                    ))
                                                  : null}
                                                <div className="row">
                                                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end text-right">
                                                    <button
                                                      className="btn btn-secondary bxd btn-sm"
                                                      type="button"
                                                      onClick={() =>
                                                        arrayHelpers.push({
                                                          salt_id: "",
                                                          dose_id: "",
                                                          quantity: "",
                                                          id: "",
                                                        })
                                                      }
                                                    >
                                                      <i className="fas fa-plus"></i>{" "}
                                                      Add
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group mt-2 text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-success btn-sm mx-2"
                                      onClick={() => {
                                        setSavereset("addreset");
                                      }}
                                    >
                                      {" "}
                                      Save
                                    </button>
                                    <button
                                      type="reset"
                                      onClick={resetForm}
                                      className="btn btn-secondary btn-sm"
                                    >
                                      {" "}
                                      Reset
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  />
                </div>
              ) : (
                <span></span>
              )}
            </>
          );
        }
      })()}
      <div className="card shadow mb-4">
        <div className="card-header">
          <h5 className="m-0 font-weight-bold text-primary">
            Active Medicines
          </h5>
        </div>
        <div className="card-body p-1">
          <input
            autoComplete="off"
            type="text"
            onChange={(e) => search(e.target.value)}
            placeholder="Search..."
            className="form-control-user form-control"
          />
          <Table wordWrap data={medicine_list} loading={loading} height={500}>
            <Column width={40} verticalAlign="middle">
              <HeaderCell>ID</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column minWidth={100} flexGrow={1} verticalAlign="middle">
              <HeaderCell>Name</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <OverlayTrigger overlay={<Tooltip>{rowData.name}</Tooltip>}>
                      <span style={{ textTransform: "capitalize" }}>
                        {rowData.name}
                      </span>
                    </OverlayTrigger>
                  );
                }}
              </Cell>
            </Column>
            <Column minWidth={80} verticalAlign="middle">
              <HeaderCell>Type</HeaderCell>
              <Cell>
                {(rowData, rowIndex) => {
                  let enty = rowData.entity_type_id;
                  return (
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ textTransform: "capitalize" }}>
                          {enty.replace(/_/g, " ")}
                        </Tooltip>
                      }
                    >
                      <span style={{ textTransform: "capitalize" }}>
                        {enty.replace(/_/g, " ")}
                      </span>
                    </OverlayTrigger>
                  );
                }}
              </Cell>
            </Column>
            <Column minWidth={120} flexGrow={1} verticalAlign="middle">
              <HeaderCell>Doses</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <div className="rolelist">
                      {(() => {
                        if (3 < getPermissions("medicines")) {
                          return rowData.medicine_formulas.map((key, i) => {
                            return (
                              <span
                                className={`badge badge-primary a${i}`}
                                key={i}
                              >
                                {key.dose_id}
                                {key.quantity}
                              </span>
                            );
                          });
                        }
                      })()}
                    </div>
                  );
                }}
              </Cell>
            </Column>
            <Column minWidth={100} flexGrow={1} verticalAlign="middle">
              <HeaderCell>Brand</HeaderCell>
              <Cell>
                {(rowData, rowIndex) => {
                  let enty = rowData.brand_id;
                  return (
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ textTransform: "capitalize" }}>
                          {enty.replace(/_/g, " ")}
                        </Tooltip>
                      }
                    >
                      <span style={{ textTransform: "capitalize" }}>
                        {enty.replace(/_/g, " ")}
                      </span>
                    </OverlayTrigger>
                  );
                }}
              </Cell>
            </Column>
            <Column minWidth={80} flexGrow={1} verticalAlign="middle">
              <HeaderCell>Salts</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <div className="rolelist">
                      {(() => {
                        if (0 < getPermissions("medicines")) {
                          return rowData.salts.map((key, i) => {
                            return (
                              <span
                                style={{ textTransform: "capitalize" }}
                                className={`badge badge-primary a${i}`}
                                key={i}
                              >
                                {key.salt_id.replace(/_/g, " ")}
                              </span>
                            );
                          });
                        }
                      })()}
                    </div>
                  );
                }}
              </Cell>
            </Column>
            <Column width={120} fixed="right" verticalAlign="middle">
              <HeaderCell>Actions</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <div className="mdbtnsdet">
                      {(() => {
                        if (2 < getPermissions("medicines")) {
                          return (
                            <div>
                              <OverlayTrigger overlay={<Tooltip>View</Tooltip>}>
                                <a
                                  href
                                  className="cp mx-2 mr-3"
                                  onClick={() => getmedicinedatas(rowData.id)}
                                >
                                  <i className="fas fa-eye"></i>
                                </a>
                              </OverlayTrigger>
                              <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                                <a
                                  href
                                  className="cp"
                                  onClick={() => getmedicinedata(rowData.id)}
                                >
                                  {" "}
                                  <Icon icon="edit2" />
                                </a>
                              </OverlayTrigger>
                            </div>
                          );
                        }
                      })()}
                      <span className="ml-3">
                        {3 < getPermissions("medicines") ? (
                          <a
                            title="Inactivate"
                            data-toggle="tooltip"
                            href
                            alt={rowData.id}
                            onClick={() => {
                              handleSweetAlert(
                                rowData.id,
                                true,
                                "handleDelete"
                              );
                            }}
                          >
                            <Icon icon="trash" />{" "}
                          </a>
                        ) : (
                          <span></span>
                        )}
                      </span>
                    </div>
                  );
                }}
              </Cell>
            </Column>
          </Table>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Pagination
              limit={10}
              offset={offset}
              total={alength}
              onClick={(e, offset) => handleClick(offset)}
            />
          </MuiThemeProvider>
        </div>
      </div>

      {/* ==========  View Medicine  ======== */}
      {MedicineDetails(
        prloading,
        Spinner,
        inputFieldss,
        mdname,
        mddiseases,
        mdside_effects,
        mdshelf,
        entity_type_ids,
        brand_id,
        inputFormulass
      )}
      {sweetAlert(
        datastatus,
        identifier,
        cancelActivation,
        handleDeleteMedicineFormula,
        handleDelete
      )}
    </Layout>
  );
};

export default ActiveMedicines;
