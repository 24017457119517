import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import BASE_URL from "../../utils/api";
import Layout from "../layouts/index";
import { getToken } from "../../utils/session.util";

// Configurations
toast.configure({ autoClose: 8000, draggable: false });

const PermissionList = () => {
  const [rolelist, setrolelist] = useState([]);
  const [resourcelist, setresourcelist] = useState([]);
  const [loading, setloading] = useState(false);

  const fetchData = async () => {
    setloading(true);
    await fetch(BASE_URL + "/roles", {
      method: "POST",
      headers: {
        Origin: "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        resource: "roles",
        method: "GET",
        with: "permissions",
      }),
    })
      .then((res) => res.json())
      .then(
        (resp) => {
          console.log(resp.data);
          let data = resp.data;
          let role = resp.data
            .map((item) => item.role)
            .filter((value, index, self) => self.indexOf(value) === index);
          let resources = resp.data
            .map((item) => item.resource)
            .filter((value, index, self) => self.indexOf(value) === index);
          setrolelist(role);
          setresourcelist(resources);
          setloading(false);
        },
        (error) => {}
      );
  };

  useEffect(() => fetchData(), []);

  return (
    <Layout>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h4 className="m-0 font-weight-bold text-primary">
            Roles and Permissions
          </h4>
        </div>
        <div className="card-body">
          <div className="permslist">
            <div className="table-responsive">
              <table className="table table-bordered table-sm">
                <thead>
                  <tr>
                    <th>
                      <strong></strong>
                    </th>
                    {rolelist.map((role, i) => (
                      <th key={i}>
                        <strong>{role}</strong>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>John</td>
                    <td>Doe</td>
                    <td>Doe</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PermissionList;
